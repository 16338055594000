<template>
  <div class="generator-wrapper">
    <div class="pre-list" v-if="!isGenerating">
      <div class="generator-item" @click="handleGenerate('continue')">
        <img src="@/assets/images/penPlus.svg" />
        <span>Continue to write</span>
      </div>

      <div class="generator-item" @click="handleGenerate('shorten')">
        <img src="@/assets/images/penMinus.svg" />
        <span>Shorten</span>
      </div>

      <div class="generator-item" @click="handleGenerate('rewrite')">
        <img src="@/assets/images/refreshDark.svg" />
        <span>Rewrite</span>
      </div>

      <div class="generator-item" @click="handleGenerate('fun')">
        <img src="@/assets/images/happyDark.svg" />
        <span>More fun</span>
      </div>

      <div class="generator-item" @click="handleGenerate('formal')">
        <img src="@/assets/images/presentationDark.svg"  />
        <span>More formal</span>
      </div>

      <div class="generator-item" @click="handleGenerate('grammar')">
        <img src="@/assets/images/typeDark.svg" />
        <span>Fix grammar</span>
      </div>

      <div class="generator-item sbetween" :class="isCustomPrompt && 'toggled'" @click="toggleCustomPrompt">
        <span>Custom prompt</span>
        <img src="@/assets/images/chevronRightDark.svg" />
      </div>
    </div>

    <div class="pre-list loading" v-else>
      <VueSpinnerDots :size="16"/>
    </div>

    <div class="custom-generator" v-if="isCustomPrompt">
      <div class="ai-generator-header">
        <input
            class="ai-input"
            v-model="prompt"
            placeholder="Enter prompt..."
        >

        <img
            v-if="!isGenerating"
            src="@/assets/images/sendFill.svg"
            @click="() => handleGenerate('custom')"
        />

        <VueSpinnerDots v-else :size="16"/>
      </div>

      <div class="ai-generator-footer">
        <img src="@/assets/images/warningDark.svg" />
        <span>AI responses can be inaccurate or misleading.</span>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, computed, watch } from 'vue';
import {VueSpinnerDots} from "vue3-spinners";

const props = defineProps({
  targetInput: {
    type: HTMLElement,
    required: true
  },
  aiSocket: {
    type: Object,
    required: true
  }
});

const prompt = ref('');
const currentChatId = ref(null);
const isCustomPrompt = ref(false);

const isGenerating = computed(() => {
  return currentChatId.value ? props.aiSocket.state.isGenerating[currentChatId.value] : false;
});

const handleGenerate = async (type = 'custom') => {
  if (isGenerating.value) return;

  let tempPrompt = prompt.value;

  if (type !== 'custom') {
    isCustomPrompt.value = false;
  }

  if (type === 'custom') {
    if (!prompt.value || prompt.value === '') return;

    tempPrompt =
        "You are an text tool, the user wrote the following text to textarea: "
        + props.targetInput.value
        + "The user wrote the following prompt: "
        + prompt.value
        + "Revise the text if there is any to make it clear, concise, and professional based on users text language"
        + "Do not include comments, explanations, or introductory phrases. Only provide the revised text."
        + "If there  is no text provided just run the prompt and provide the revised text.";
  } else if (type === 'continue') {
    if (!props.targetInput.value || props.targetInput.value === '') return;

    tempPrompt =
        "You are an text tool, the user wrote the following text to textarea: "
        + props.targetInput.value
        + "Continue writing the text. Make it clear, concise, and professional based on users text language"
        + "Do not include comments, explanations, or introductory phrases. Only provide the revised text."
  } else if (type === 'shorten') {
    if (!props.targetInput.value || props.targetInput.value === '') return;

    tempPrompt =
        "You are an text tool, the user wrote the following text to textarea: "
        + props.targetInput.value
        + "Shorten the text. Make it clear, concise, and professional based on users text language"
        + "Do not include comments, explanations, or introductory phrases. Only provide the revised text."
  }  else if (type === 'rewrite') {
    if (!props.targetInput.value || props.targetInput.value === '') return;

    tempPrompt =
        "You are an text tool, the user wrote the following text to textarea: "
        + props.targetInput.value
        + "Rewrite the text. Make it clear, concise, and professional based on users text language"
        + "Do not include comments, explanations, or introductory phrases. Only provide the revised text."
  } else if (type === 'formal') {
    if (!props.targetInput.value || props.targetInput.value === '') return;

    tempPrompt =
        "You are an text tool, the user wrote the following text to textarea: "
        + props.targetInput.value
        + "Revise the text if there is any to make it clear, concise, and formal based on users text language"
        + "Do not include comments, explanations, or introductory phrases. Only provide the revised text."
  } else if (type === 'grammar') {
    if (!props.targetInput.value || props.targetInput.value === '') return;

    tempPrompt =
        "You are an text tool, the user wrote the following text to textarea: "
        + props.targetInput.value
        + "Revise the text if there is any to make it clear, concise, and formal based on users text language, fix the grammar errors."
        + "Do not include comments, explanations, or introductory phrases. Only provide the revised text."
  } else if (type === 'fun') {
    if (!props.targetInput.value || props.targetInput.value === '') return;

    tempPrompt =
        "You are an text tool, the user wrote the following text to textarea: "
        + props.targetInput.value
        + "Revise the text to make it more fun."
        + "Do not include comments, explanations, or introductory phrases. Only provide the revised text."
  }


  try {
    currentChatId.value = await props.aiSocket.generateText(tempPrompt);
    prompt.value = '';
  } catch (error) {
    console.error('Error generating text:', error);
  }
};

const toggleCustomPrompt = () => {
  isCustomPrompt.value = !isCustomPrompt.value;
};

// Watch for changes in generatedText and update the input value accordingly
watch(() => props.aiSocket.state.generatedText[currentChatId.value], (newText, oldText) => {
  if (!newText || newText === oldText) return;
  props.targetInput.value = newText;
});
</script>

<style scoped>
.generator-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 100%;
  gap: 15px;
}

.pre-list {
  overflow-y: auto;
  background: white;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  max-height: 300px;
  padding-top: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding-bottom: 10px;
}

.pre-list.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
}

.generator-item {
  padding: 8px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.generator-item:hover, .generator-item.toggled {
  background: #F5F5F5;
  cursor: pointer;
}

.generator-item span {
  display: block;
  font-size: 12px;
}
.generator-item img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.generator-item.sbetween {
  justify-content: space-between;
}

.ai-generator-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  border: 1px solid #EAECF0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  min-width: 350px;
}

.ai-generator-footer {
  display: flex;
  flex-direction: row;
  background: #F5F5F5;
  border-left: 1px solid #EAECF0;
  border-right: 1px solid #EAECF0;
  border-bottom: 1px solid #EAECF0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 5px 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.ai-generator-footer span {
  color: #757575;
  font-size: 10px;
}

.ai-generator-footer img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.ai-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
}

button {
  width: 100%;
  padding: 8px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background: #ccc;
}
</style>
