import axios from './providers/axios';

const reviewService = {
    getReviews(page, type = 'expert') {
        return axios.get(`/v1/admin/reviews/store?page=${page}&limit=20&type=${type}`);
    },
    updateReviewStatus(reviewId, status) {
        const statusTemp = status ? 1 : 0;
        return axios.put(`/v1/admin/reviews/publish?reviewsId=${reviewId}&status=${statusTemp}`);
    }
}

export default reviewService;
