import axios from './providers/axios';
import moment from "moment/moment";

const expertService = {
    getExperts(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/experts/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/experts/store?page=${page}`);
        }
    },
    addExpert(data) {
        return axios.post('/v1/admin/experts/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateExpert(data) {
        return axios.post('/v1/admin/experts/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteExpert(id) {
        return axios.delete(`/v1/admin/experts/delete/${id}`);
    },
    getExpertDetails(id) {
        return axios.get(`/v1/admin/experts/getFromId/${id}`);
    },
    saveAboutExpert(expertId, about) {
        return axios.put('/v1/admin/experts/about/update', {
            expertId: expertId,
            about: about
        });
    },
    addSchool(data) {
        return axios.post('/v1/admin/experts/education/add', data);
    },
    updateSchool(data) {
        return axios.put('/v1/admin/experts/education/update', data);
    },
    addCertificate(data) {
        return axios.post('/v1/admin/experts/certificate/add', data);
    },
    updateCertificate(data) {
        return axios.put('/v1/admin/experts/certificate/update', data);
    },
    updateSlot(data) {
        return axios.post('/v1/admin/experts/slot/add', data);
    },
    addSpecificSlot(data) {
        return axios.post('/v1/admin/experts/slot/addSpecific', data);
    },
    getExpertReviews(page, expertId) {
        return axios.get(`/v1/admin/experts/reviews/store?page=${page}&limit=20&expertId=${expertId}`);
    },
    getExpertSlots(expertId) {
        return axios.get(`/v1/admin/experts/slot/store/?expertId=${expertId}`);
    },
    getExpertSpecificSlots(expertId) {
        return axios.get(`/v1/admin/experts/slot/storeSpecific/?expertId=${expertId}`);
    },
    deleteSlot(id) {
        return axios.delete(`/v1/admin/experts/slot/delete/${id}`);
    },
    getReservationAvailability(expertId, date) {
        return axios.get(`/v1/admin/experts/reservation/availableHours?expertId=${expertId}&date=${date}`);
    },
    addReservation(data) {
        return axios.post('/v1/admin/experts/reservation/add', data);
    },
    updateReservation(data) {
        return axios.put('/v1/admin/experts/reservation/update', data);
    },
    updateReservationStatus(expertReservationId, status, reason) {
        return axios.put('/v1/admin/experts/reservation/updateStatus', {
            expertReservationId: expertReservationId,
            reason: reason,
            status: status
        });
    },
    getExpertCalendarData(expertId) {
        const today = moment().format('YYYY-MM-DD');
        return axios.get(`/v1/admin/experts/reservation/getMonthlyAllReservations?expertId=${expertId}&date=${today}`);
    },
    getExpertReservations(expertId, page, status) {
        if (status === 'all') {
            return axios.get(`/v1/admin/experts/reservation/store?expertId=${expertId}&page=${page}&limit=20`);
        } else {
            return axios.get(`/v1/admin/experts/reservation/store?status=${status}&expertId=${expertId}&page=${page}&limit=20`);
        }
    },
    updateExpertTags(expertId, tagIds,) {
        return axios.put('/v1/admin/experts/tags/update', {
            expertId: expertId,
            tagIds: tagIds
        });
    }
}

export default expertService;
