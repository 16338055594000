// File: src/utils/aiSocket.js
import { io } from 'socket.io-client';
import { reactive } from 'vue';

class AISocketClient {
    constructor() {
        // Make the state reactive for Vue
        this.state = reactive({
            socket: null,
            generatedText: {},
            isGenerating: {},
            error: null,
            currentChatId: null
        });
    }

    connect() {
        if (!this.state.socket) {
            this.state.socket = io('https://ai.ibilityapp.com', {
                withCredentials: true,
                transports: ['websocket']
            });

            this.state.socket.on('connect', () => {
                console.log('Connected to socket server');
            });

            this.state.socket.on('disconnect', () => {
                console.log('Disconnected from socket server');
            });

            this.state.socket.on('text_chunk', (chunk) => {
                if (chunk.chatId && !chunk.done) {
                    if (!this.state.generatedText[chunk.chatId]) {
                        this.state.generatedText[chunk.chatId] = '';
                    }
                    this.state.generatedText[chunk.chatId] += chunk.text;
                } else if (chunk.done) {
                    this.state.isGenerating[chunk.chatId] = false;
                }
            });

            this.state.socket.on('error', (err) => {
                console.error('Socket error:', err);
                this.state.error = err;
            });
        }
    }

    async generateText(prompt, options = {}) {
        if (!this.state.socket) {
            this.state.error = 'Socket not connected';
            throw new Error('Socket not connected');
        }

        const chatId = options.chatId || Date.now().toString();
        this.state.currentChatId = chatId;
        this.state.isGenerating[chatId] = true;
        this.state.generatedText[chatId] = '';
        this.state.error = null;

        console.log("Sending prompt:", prompt);
        this.state.socket.emit('generate_text', {
            chatId,
            prompt,
            maxTokens: options.maxTokens || 512,
            temperature: options.temperature || 0.5
        });

        return chatId;
    }

    disconnect() {
        if (this.state.socket) {
            this.state.socket.disconnect();
            this.state.socket = null;
        }
    }
}

export default new AISocketClient();
