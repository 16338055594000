<template>
  <router-link :to="to">
    <div class="flow-card no-text-select">
      <div class="status-tag" :class="isPublish ? 'Published' : 'Draft'">
        <div class="status-dot" :class="isPublish ? 'Published' : 'Draft'"></div>
        <span>{{ isPublish ? $t('published') : $t('draft') }}</span>
      </div>

      <span class="flow-date">{{date}}</span>
      <span class="flow-title">{{ name }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    isPublish: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
  },
  methods: {

  },
};
</script>

<style scoped>

.flow-card {
  width: 100%;
  height: 100%;
  border: 1px solid #EAECF0;
  background-color: #FCFCFD;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.status-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040c2d;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag.Draft {
  background-color: #fad9c6;
}

.status-tag.Published {
  background-color: #ddfbde;
}

.status-dot.Draft {
  background-color: #f09460;
}

.status-dot.Published {
  background-color: #3da33e;
}

.flow-date {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #667085;
  margin-top: 30px;
}

.flow-title {
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  color: #040C2D;
  margin-bottom: 10px;
}

.info-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: auto;
}

.info-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #040C2D;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #F2F4F7;
}

</style>
