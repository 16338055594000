<template>
  <Layout padding="0">
    <ExpertHeader
        :is-loading="isLoading"
        :title="data.name + ' ' + data.surname"
        :photo="data.avatarUrl"
        :created-at="data.createdAt"
        :on-edit="showEditModal"
        :on-delete="showDeleteModal"
    />
    <div class="main-content">
      <div class="tab-panel-row">
        <div class="tab-panel">
          <button class="tab-button" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
            <span>{{$t('general')}}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
            <span>{{$t('online_sessions')}}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 2}" @click="() => changeTab(2)">
            <span>{{$t('calendar')}}</span>
          </button>
        </div>

        <div class="tab-actions">
          <div class="action-row" v-if="tabIndex === 1">
            <button class="primary-button" @click="sessionModal = true">{{ $t('create_new') }}</button>
          </div>
          <div class="action-row" v-if="tabIndex === 2">
            <button class="primary-button" @click="availabilityModal = true">{{$t('edit_availability')}}</button>
          </div>

        </div>
      </div>


      <div v-if="tabIndex === 0" class="tab-container">
        <h3>{{$t('expert_summary')}}</h3>
        <div class="company-details">
          <div class="company-details-col">

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/avatar.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">{{$t('full_name')}}</span>
                <span class="detail-text" v-if="!isLoading">{{data.name}} {{data.surname}}</span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/avatar.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('title_expert')}}
                </span>
                <span class="detail-text" v-if="!isLoading">{{data.title}}</span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/buildings.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('graduated_from')}}
                </span>
                <span class="detail-text" v-if="!isLoading && data.defaultEducation">{{  data.defaultEducations.schoole.name }}</span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/tag.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('experience')}}
                </span>
                <span class="detail-text" v-if="!isLoading">{{data.experiance}} {{$t('years_experience')}}</span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

          </div>

          <div class="company-details-col">
            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/message.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('e_mail')}}
                </span>
                <span class="detail-text" v-if="!isLoading">{{ data.email }}</span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/phone.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                   {{$t('phone')}}
                </span>
                <span class="detail-text" v-if="!isLoading">{{ data.phone }}</span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/cake.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('birthday')}}
                </span>
                <span class="detail-text" v-if="!isLoading">{{ formatDate(data.birthday) }}</span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

          </div>
        </div>

        <div class="title-icon">
          <h3>{{$t('about_expert')}}</h3>
          <img src="@/assets/images/editPen.svg" class="edit-icon" @click="editAboutModal = true" />
        </div>

        <p v-if="!isLoading">
          {{data.about}}
        </p>

        <free-style-shimmer
            :is-loading="isLoading"
            height="7px"
            width="200px"
            border-radius="50px"
            color="#EAECF0"
            style="margin-bottom: 10px"
        />

        <free-style-shimmer
            :is-loading="isLoading"
            height="7px"
            width="160px"
            border-radius="50px"
            color="#EAECF0"
        />


        <div class="title-icon">
          <h3>{{$t('education')}}</h3>
          <img src="@/assets/images/editPen.svg" class="edit-icon" @click="editEducationModal = true"/>
        </div>

        <div class="info-list">

          <div class="info-card" v-if="!isLoading" v-for="item in data.educations">
            <div class="info-left">
              <img src="https://i.hizliresim.com/48wveyk.png" />
              <div class="info">
                <span class="title">{{ item.schoole.name }}, {{ item.schoole.country }}</span>
                <span class="description">{{ item.degree.name }}</span>
              </div>
            </div>
            <span class="date">{{item.finishAt}}</span>
          </div>

          <div class="info-card" v-else>
            <div class="info-left">
              <free-style-shimmer
                  :is-loading="true"
                  height="40px"
                  width="40px"
                  border-radius="50%"
                  color="#EAECF0"
              />
              <div class="info">
                <free-style-shimmer
                    :is-loading="true"
                    height="7px"
                    width="160px"
                    border-radius="50px"
                    color="#EAECF0"
                />
                <free-style-shimmer
                    :is-loading="true"
                    height="7px"
                    width="240px"
                    border-radius="50px"
                    color="#EAECF0"
                    style="margin-top: 5px"
                />
              </div>
            </div>
            <span class="date">
                   <free-style-shimmer
                       :is-loading="true"
                       height="7px"
                       width="40px"
                       border-radius="50px"
                       color="#EAECF0"
                       style="margin-top: 5px"
                   />
              </span>
          </div>
        </div>


        <div class="title-icon">
          <h3>{{$t('certifications')}}</h3>
          <img src="@/assets/images/editPen.svg" class="edit-icon" @click="showCertificationModal"/>
        </div>

        <div class="info-list">

          <div class="info-card" v-if="!isLoading" v-for="item in data.certificates">
            <div class="info-left">
              <img src="https://i.hizliresim.com/gzzvvnq.png" />
              <div class="info">
                <span class="title">{{item.name}}</span>
                <span class="description">{{ item.institution }}</span>
              </div>
            </div>
            <span class="date">{{item.finishAt}}</span>
          </div>

          <div class="info-card" v-else>
            <div class="info-left">
              <free-style-shimmer
                  :is-loading="true"
                  height="40px"
                  width="40px"
                  border-radius="50%"
                  color="#EAECF0"
              />
              <div class="info">
                <free-style-shimmer
                    :is-loading="true"
                    height="7px"
                    width="160px"
                    border-radius="50px"
                    color="#EAECF0"
                />
                <free-style-shimmer
                    :is-loading="true"
                    height="7px"
                    width="240px"
                    border-radius="50px"
                    color="#EAECF0"
                    style="margin-top: 5px"
                />
              </div>
            </div>
            <span class="date">
                   <free-style-shimmer
                       :is-loading="true"
                       height="7px"
                       width="40px"
                       border-radius="50px"
                       color="#EAECF0"
                       style="margin-top: 5px"
                   />
              </span>
          </div>

        </div>

        <div class="title-icon">
          <h3>{{$t('specialties')}}</h3>
          <img src="@/assets/images/editPen.svg" class="edit-icon" @click="showTagModal"/>
        </div>

        <div class="specialities-tags">
          <span class="category-tag" v-for="tag in data.tags">{{tag.name}}</span>
        </div>
      </div>

      <div v-if="tabIndex === 1" class="tab-container">
        <h3>{{$t('online_sessions')}}</h3>

        <div class="session-tab-panel no-text-select">
          <div class="session-tab-pane" :class="{'active': activeSessionTab === 0}" @click="() => handleChangeSessionTab(0)">
            {{$t('pending')}}
          </div>

          <div class="session-tab-pane" :class="{'active': activeSessionTab === 1}" @click="() => handleChangeSessionTab(1)">
            {{$t('upcoming')}}
          </div>

          <div class="session-tab-pane" :class="{'active': activeSessionTab === 2}" @click="() => handleChangeSessionTab(2)">
            {{$t('scheduled')}}
          </div>

          <div class="session-tab-pane" :class="{'active': activeSessionTab === 3}" @click="() => handleChangeSessionTab(3)">
            {{$t('completed')}}
          </div>

          <div class="session-tab-pane" :class="{'active': activeSessionTab === 4}" @click="() => handleChangeSessionTab(4)">
            {{$t('cancelled')}}
          </div>
        </div>


        <div class="session-list">
          <SessionCard
              v-if="!reservationsLoading"
              v-for="session in expertReservations"
              :session-data="session"
              :handle-accept="(reservationId) => changeReservationStatus(reservationId, 1)"
              :handle-reject="(reservationId) => handleRejectReservation(reservationId)"
              :edit-session="handleEditSession"
          />

          <VueSpinnerIos v-else-if="reservationsLoading" />

          <div v-if="!reservationsLoading && expertReservations.length === 0" class="no-reservations-wrapper">
            <span>
              {{$t('no_reservations')}}
            </span>
          </div>
        </div>

      </div>


      <div v-if="tabIndex === 2" class="tab-container">
        <FullCalendar :options="calendarOptions" />
      </div>
    </div>

    <ConfirmDeleteModal
        :title="this.$t('alert_expert_messages')"
        :message="this.$t('all_experts_deleted')"
        :is-visible="deleteModalVisible"
        :on-action="handleDelete"
        :on-close="handleDeleteModalClose"
    />

    <ExpertModal :visible="editModalVisible" :on-hide="hideEditModal" :edit-mode="true" :edit-data="data" :expert-id="data.expertId" :refresh-callback="getDetails" />
    <EditAboutModal :visible="editAboutModal" :on-hide="hideEditAboutModal" :about-text="data.about" :expert-id="data.expertId" :refresh-callback="getDetails" />
    <EditEducationModal :visible="editEducationModal" :on-hide="hideEducationModal" :education-data="data.educations" :expert-id="data.expertId" :refresh-callback="getDetails" />
    <EditCertificationModal :visible="certificationModal" :on-hide="hideCertificationModal" :certification-data="data.certificates" :expert-id="data.expertId" :refresh-callback="getDetails" />
    <ExpertTagModal :visible="expertTagVisible" :expert-id="data.expertId" :on-hide="hideTagModal" :tag-data="data.tags" :refresh-callback="getDetails"/>

    <SessionModal
        :visible="sessionModal"
        :on-hide="hideSessionModal"
        :expert-id="data.expertId"
    />
    <AvailabilityModal :visible="availabilityModal" :on-hide="hideAvailabilityModal" :expert-id="data.expertId" />
    <SessionMeetUpdateModal
        :visible="updateSessionModal"
        :on-hide="hideSessionUpdateModal"
        :edit-session-data="editSessionData"
    />

    <SessionRejectReasonModal
        :visible="rejectModalVisible"
        :on-hide="() => rejectModalVisible = false"
        :on-reject="(reason) => changeReservationStatus(rejectReservationId, 4, reason)"
        />
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import UserTable from "@/components/tables/User/UserTable.vue";
import TherapyCard from "@/components/TherapyCard.vue";
import TransactionTable from "@/components/tables/TransactionTable.vue";
import AddUserToCompanyModal from "@/components/modals/InviteUserModal.vue";
import SessionCard from "@/components/SessionCard.vue";
import TotalRating from "@/components/TotalRating.vue";
import ReviewCard from "@/components/ReviewCard.vue";
import expertService from "@/service/expertService";
import ExpertHeader from "@/components/ExpertHeader.vue";
import EditAboutModal from "@/components/modals/EditAboutModal.vue";
import EditEducationModal from "@/components/modals/EditEducationModal.vue";
import SessionModal from "@/components/modals/SessionModal.vue";
import AvailabilityModal from "@/components/modals/AvailabilityModal.vue";
import ExpertModal from "@/components/modals/ExpertModal.vue";
import ConfirmDeleteModal from "@/components/modals/ConfirmDeleteModal.vue";
import {
  VueSpinnerIos
} from 'vue3-spinners'
import EditCertificationModal from "@/components/modals/EditCertificationModal.vue";
import ExpertTagModal from "@/components/modals/ExpertTagModal.vue";
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import {handleErrorResponse} from "@/utils/utils";
import SessionMeetUpdateModal from "@/components/modals/SessionMeetUpdateModal.vue";
import SessionRejectReasonModal from "@/components/modals/SessionRejectReasonModal.vue";

export default {
  components: {
    SessionRejectReasonModal,
    SessionMeetUpdateModal,
    FullCalendar,
    ExpertTagModal,
    EditCertificationModal,
    ConfirmDeleteModal,
    ExpertModal,
    AvailabilityModal,
    SessionModal,
    EditEducationModal,
    EditAboutModal,
    ExpertHeader,
    ReviewCard,
    TotalRating,
    SessionCard,
    AddUserToCompanyModal,
    TransactionTable,
    TherapyCard,
    UserTable,
    ExpandableSearch,
    VueSpinnerIos,
    Layout
  },
  data() {
    return {
      isLoading: true,
      searchText: '',
      tabIndex: 0,
      editModalVisible: false,
      editAboutModal: false,
      editEducationModal: false,
      sessionModal: false,
      updateSessionModal: false,
      addUserModal: false,
      availabilityModal: false,
      deleteModalVisible: false,
      certificationModal: false,
      expertTagVisible: false,
      activeSessionTab: 0,
      data: {},
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        displayEventEnd:true,
        headerToolbar: {
          left: 'title',
          right: ''
        },
        events: [],
        locale: this.getLocaleConfig()
      },
      expertReservations: [],
      reservationsLoading: false,
      editSessionData: null,
      rejectReservationId: null,
      rejectModalVisible: false,
    }
  },

  mounted() {
    this.getDetails();
    this.fetchCalendar();
    this.fetchReservations(0);
  },

  watch: {
    '$i18n.locale': function() {
      this.calendarOptions = {
        ...this.calendarOptions,
        locale: this.getLocaleConfig()
      };
    }
  },

  methods: {
    changeTab(index) {
      this.tabIndex = index;
    },
    showEditModal() {
      this.editModalVisible = true;
    },
    hideEditModal() {
      this.editModalVisible = false;
    },
    hideSessionModal() {
      this.sessionModal = false;
    },
    hideAvailabilityModal() {
      this.availabilityModal = false;
    },
    showCertificationModal() {
      this.certificationModal = true;
    },
    hideCertificationModal() {
      this.certificationModal = false;
    },
    hideTagModal() {
      this.expertTagVisible = false;
    },
    showTagModal() {
      this.expertTagVisible = true;
    },
    showDeleteModal() {
      this.deleteModalVisible = true;;
      event.stopPropagation();
    },
    handleDeleteModalClose() {
      this.deleteModalVisible = false;
    },
    getDetails() {
      const routeParams = this.$route.params;

      this.isLoading = true;
      expertService.getExpertDetails(routeParams.id).then((response) => {
        this.isLoading = false;
        this.data = response.data.data;
      });
    },
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};

        const currentLocale = this.$i18n.locale;

        return date.toLocaleDateString(currentLocale, options);
      } catch (e) {
        return '-';
      }
    },
    hideEditAboutModal() {
      this.editAboutModal = false;
    },
    hideEducationModal() {
      this.editEducationModal = false;
    },
    handleDelete() {
      this.deleteModalVisible = false;

      expertService.deleteExpert(this.data.expertId).then(() => {
        this.$router.push('/experts');
      }).catch(() => {
        this.$snackbar.add({
          text: this.$t('error_occurred_deleting_expert'),
          type: 'error',
        });
      })
    },
    processCalendarBackendData(response) {
      const events = []

      if (response.responseType === 'success' && response.data) {
        Object.entries(response.data).forEach(([date, appointments]) => {
          appointments.forEach(appointment => {
            // Randevunun başlangıç tarih ve saati
            let startDateTime;
            let startTime = "";
            let endTime = "";

            // Eğer expertSlotTime varsa, onun startTime değerini kullanabiliriz
            if (appointment.expertSlotTime && appointment.expertSlotTime.startTime) {
              // Tarih ve saat bilgisini birleştir (YYYY-MM-DD + saat bilgisi)
              const datePart = date; // Zaten "2025-03-04" formatında
              startTime = appointment.expertSlotTime.startTime.substring(0, 5); // "09:00" formatına dönüştür (saat:dakika)
              const timePart = appointment.expertSlotTime.startTime; // "09:00:00" formatında
              startDateTime = `${datePart}T${timePart}`; // "2025-03-04T09:00:00" formatına dönüşür
            } else {
              // Slot zamanı yoksa, appointment.date değerini kullan
              startDateTime = this.$moment(appointment.date).format('YYYY-MM-DD HH:mm:ss');
              startTime = this.$moment(appointment.date).format('HH:mm');
            }

            // Randevunun bitiş tarih ve saati (varsa)
            let endDateTime;
            if (appointment.expertSlotTime && appointment.expertSlotTime.endTime) {
              const datePart = date;
              endTime = appointment.expertSlotTime.endTime.substring(0, 5); // "10:00" formatına dönüştür
              const timePart = appointment.expertSlotTime.endTime;
              endDateTime = `${datePart}T${timePart}`;
            }

            // Randevu durumuna göre renk belirleme
            let backgroundColor, borderColor;
            switch(appointment.status) {
              case "0": // Bekleyen randevu
                backgroundColor = "#FFC107"; // Sarı
                borderColor = "#FFC107";
                break;
              case "1": // Onaylanmış randevu
                backgroundColor = "#4CAF50"; // Yeşil
                borderColor = "#4CAF50";
                break;
              default:
                backgroundColor = "#4CAF50";
                borderColor = "#4CAF50";
            }

            events.push({
              id: appointment.id,
              title: appointment.note || 'Appointment',
              start: startDateTime,
              end: endDateTime,
              extendedProps: {
                status: appointment.status,
                note: appointment.note,
                meetUrl: appointment.meetUrl,
                reasonIds: appointment.reasonIds,
                timeRange: `${startTime}-${endTime}`
              },
              backgroundColor: backgroundColor,
              borderColor: borderColor
            })
          })
        })
      }

      this.events = events
      this.calendarOptions.events = this.events
    },
    fetchCalendar() {
      const routeParams = this.$route.params;
      expertService.getExpertCalendarData(routeParams.id).then((response) => {
        this.processCalendarBackendData(response.data)
       });
    },
    fetchReservations(status) {
      if (this.reservationsLoading) {
        return;
      }

      const routeParams = this.$route.params;
      let tempStatus = 'all';

      if (status === 0) {
        tempStatus = '0';
      } else if (status === 1) {
        tempStatus = '1';
      } else if (status === 2) {
        tempStatus = '2';
      } else if (status === 3) {
        tempStatus = '3';
      } else if (status === 4) {
        tempStatus = '4';
      }
      this.reservationsLoading = true;

      expertService.getExpertReservations(routeParams.id, 1, tempStatus).then((response) => {
        this.expertReservations = response.data.data;
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.reservationsLoading = false;
      });
    },
    handleChangeSessionTab(index) {
      if (this.reservationsLoading) {
        return;
      }

      this.activeSessionTab = index;
      this.fetchReservations(index);
    },
    handleRejectReservation(reservationId) {
      this.rejectReservationId = reservationId;
      this.rejectModalVisible = true;
    },
    changeReservationStatus(reservationId, status, reason) {
      expertService.updateReservationStatus(reservationId, status, reason).then(() => {
        this.fetchReservations();
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      });
    },
    handleEditSession(sessionData) {
      this.editSessionData = sessionData;
      this.updateSessionModal = true;
    },
    hideSessionUpdateModal() {
      this.editSessionData = null;
      this.updateSessionModal = false;
      this.fetchReservations(
        this.activeSessionTab
      );
    },


    getLocaleConfig() {
      // i18n'den mevcut dili al (tr veya en)
      const currentLocale = this.$i18n.locale;

      if (currentLocale === 'tr') {
        return {
          code: 'tr',
          week: { dow: 1, doy: 7 },
          buttonText: {
            today: 'Bugün',
            month: 'Ay',
            week: 'Hafta',
            day: 'Gün',
            list: 'Liste'
          },
          weekText: 'Hf',
          allDayText: 'Tüm gün',
          moreLinkText: 'daha fazla',
          noEventsText: 'Gösterilecek etkinlik yok',
          dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
          dayNamesShort: ['Paz', 'Pts', 'Sal', 'Çar', 'Per', 'Cum', 'Cts'],
          monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
          monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara']
        };
      } else {
        return {
          code: 'en',
          week: { dow: 0, doy: 6 },
          buttonText: {
            today: 'Today',
            month: 'Month',
            week: 'Week',
            day: 'Day',
            list: 'List'
          },
          weekText: 'W',
          allDayText: 'All Day',
          moreLinkText: 'more',
          noEventsText: 'No events to display',
          dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        };
      }
    }
  },

}
</script>

<style scoped>
.tab-panel {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}
.main-content {
  padding: 25px;
}
.tab-panel-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #F2F4F7;
  border-bottom: 1px solid #F2F4F7;
  padding: 10px 0;
  margin-bottom: 20px;
}
.tab-button {
  padding: 6px 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.tab-button span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #344054;
}
.tab-button.active, .tab-button:hover {
  background-color: #F2F4F7;
}
h3 {
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.company-details-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.company-detail-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.company-detail-icon {
  background-color: #F5F5F5;
  border-radius: 16px;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-detail-icon img {
  cursor: default;
  width: 20px;
  height: 20px;
}
.detail-title {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #667085;
  font-size: 12px;
}
.detail-text {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #101828;
  font-size: 14px;
}
.company-detail-body {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.company-details {
  display: flex;
  flex-direction: row;
  gap: 20px;
}


h4 {
  margin: 0;
  padding: 0;
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
}
p {
  color: #354055;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.title-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-top: 20px
}

.info-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.info-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.info-card .info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.info-card img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.info-card .title {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
}

.info-card .description {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #667085;
}

.info-card .date {
  font-family: euclid_regular, sans-serif;
  font-size: 16px;
  color: #667085;
}

.info-list {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.specialities-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.session-tab-panel {
  width: 100%;
  border-bottom: 2px solid #F2F4F7;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.session-tab-pane {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.session-tab-pane.active {
  border-bottom: 2px solid #3DA33E;
  color: #101828;
}

.session-tab-pane.active-orange {
  border-bottom: 2px solid #F09460;
  color: #101828;
}

.session-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.reviews-grid {
  width: 100%;
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}


.total-rating-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

.review-grid-item {
  width: 90%;
}

.no-reservations-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #667085;
}
</style>
