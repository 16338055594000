<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <div class="left">
            <span class="modal-header-txt">{{$t('add_widget')}}</span>
          </div>

          <div class="right">
            <div @click="this.onHide" class="close-btn">
              <img class="cross" src="../../assets/images/cross.svg" />
            </div>
          </div>
        </div>

        <div class="info-header">
          <h3>{{$t('chart_name')}}</h3>
          <h4>{{$t('select_chart_name')}}</h4>
        </div>

        <div class="chart-name-wrapper mt-2">
          <input class="form-input" :placeholder="$t('chart_name')" v-model="widgetData.name" />
        </div>


        <div class="info-header">
          <h3>{{$t('chart_type')}}</h3>
          <h4>{{$t('select_chart_type')}}</h4>
        </div>

        <div class="chart-list mt-3">
          <div
              class="chart-type"
              :class="widgetData.chartType === 'linearChart' && 'selected'"
              @click="toggleChart('linearChart')"
          >
            <img class="chart-image" src="@/assets/images/linearChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>

          <div
              class="chart-type"
              :class="widgetData.chartType === 'barChart' && 'selected'"
              @click="toggleChart('barChart')"
          >
            <img class="chart-image" src="@/assets/images/barChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>

          <div
              class="chart-type"
              :class="widgetData.chartType === 'pieChart' && 'selected'"
              @click="toggleChart('pieChart')"
          >
            <img class="chart-image" src="@/assets/images/pieChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>

          <div
              class="chart-type"
              :class="widgetData.chartType === 'halfPieChart' && 'selected'"
              @click="toggleChart('halfPieChart')"
          >
            <img class="chart-image" src="@/assets/images/halfPieChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>

          <div
              class="chart-type"
              :class="widgetData.chartType === 'funnelChart' && 'selected'"
              @click="toggleChart('funnelChart')"
          >
            <img class="chart-image" src="@/assets/images/funnelChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>

          <div
              class="chart-type"
              :class="widgetData.chartType === 'tableChart' && 'selected'"
              @click="toggleChart('tableChart')"
          >
            <img class="chart-image" src="@/assets/images/tableChart.png" />
            <img class="check" src="@/assets/images/checkBlue.svg" />
          </div>
        </div>

        <div class="info-header">
          <h3>{{$t('time_frame')}}</h3>
          <h4>{{$t('select_time_frame_for')}}</h4>
        </div>

        <div class="time-frame-list">
          <div class="time-frame" :class="widgetData.timeFrame === '1day' && 'selected'" @click="toggleTimeFrame('1day')">
            <span>1 {{$t('day_title')}}</span>
          </div>

          <div class="time-frame" :class="widgetData.timeFrame === '1week' && 'selected'" @click="toggleTimeFrame('1week')">
            <span>1 {{$t('week_title')}}</span>
          </div>

          <div class="time-frame" :class="widgetData.timeFrame === '1month' && 'selected'" @click="toggleTimeFrame('1month')">
            <span>1 {{$t('month_title')}}</span>
          </div>

          <div class="time-frame" :class="widgetData.timeFrame === '3month' && 'selected'" @click="toggleTimeFrame('3month')">
            <span>3 {{$t('month_title')}}</span>
          </div>

          <div class="time-frame" :class="widgetData.timeFrame === '6month' && 'selected'" @click="toggleTimeFrame('6month')">
            <span>6 {{$t('month_title')}}</span>
          </div>

          <div class="time-frame" :class="widgetData.timeFrame === '1year' && 'selected'" @click="toggleTimeFrame('1year')">
            <span>1 {{$t('year_title')}}</span>
          </div>
        </div>

        <div class="info-header">
          <h3>{{$t('data_filters')}}</h3>
          <h4>{{$t('data_filter_settings')}}</h4>
        </div>

        <div class="data-step-wrapper">
          <div class="data-group" v-for="filter in widgetData.filters">
            <div class="left" />

            <div class="right">
              <div class="filter">
                <div class="input-row">
                  <input class="data-input color" type="color" v-model="filter.color" />
                  <input class="data-input" :placeholder="$t('group_title')" v-model="filter.name" />
                </div>

                <div class="filter-row">
                  <FilterDropdown
                      :placeholder="$t('select_data')"
                      :actions="mainFilterOptions"
                      :on-select="select => {
                        filter.targetType = select.value;
                      }"
                  />

                  <span class="data-text">{{$t('where_title')}}</span>

                  <FilterDropdown
                      :placeholder="$t('select_source')"
                      :actions="sourceList"
                      :for-check="filter.targetType"
                      :on-select="select => {
                        filter.source.type = select.mainValue.value;
                        filter.source.id = select.value;

                        if (filter.source.type === 'onboarding') {
                          getOnboarding(filter.source.id);
                        }
                      }"
                  />

                  <FilterDropdown
                      v-if="filter?.source?.type === 'module'"
                      :placeholder="this.$t('select_source')"
                      :actions="sourceModuleList"
                      :on-select="select => filter.filters.source.value = select.value"
                  />

                  <FilterDropdown
                      v-if="filter?.source?.type === 'subModule'"
                      :placeholder="this.$t('select_source')"
                      :actions="sourceSubModuleList"
                      :on-select="select => filter.filters.source.value = select.value"
                  />

                  <button
                      class="add-filter-button"
                      @click="() => addProgramFilter(filter)" v-if="filter?.source?.type === 'program' && filter?.source?.type === 'module'"
                  >
                    <img src="@/assets/images/add.svg" />
                  </button>

                  <span class="data-text">{{$t('is_title')}}</span>

                  <FilterDropdown
                      :right="true"
                      :for-check="filter?.source?.type"
                      :placeholder="$t('select')"
                      :actions="[
                        ...typeFilters,
                        ...onboardingTypeFilters
                      ]"
                      :on-select="(value) => {
                        filter.source.value = value.value;
                      }"
                  />
                </div>
              </div>
            </div>

            <div class="delete-wrapper" @click="removeFilterGroup(filter)">
              <img src="@/assets/images/cross.svg" />
            </div>
          </div>


          <div class="add-filter-group">
            <button class="add-filter-group-button" @click="addFilterGroup">
              <img src="@/assets/images/plusBlue.svg" />
              <span>{{$t('add_group')}}</span>
            </button>
          </div>
        </div>

        <div class="footer-controls">
          <button class="btn-style" @click="handleSave">{{$t('save_button')}}</button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import StepProgress from "@/components/StepProgress.vue";
import FilterDropdown from "@/components/dashboard-components/FilterDropdown.vue";
import widgetService from "@/service/widgetService";
import {handleErrorResponse} from "@/utils/utils";
import onboardingService from "@/service/onboardingService";

export default {
  components: {FilterDropdown, StepProgress},
  data() {
    return {
      selectedChartType: null,
      selectedTimeFrame: null,
      mainFilterOptions: [
        {
          name: 'Users',
          value: 'users',
        },
        {
          name: 'Time Spent',
          value: 'timeSpent',
        },
        // {
        //   name: 'Files',
        //   value: 'files',
        // },
      ],
      sourceList: [
        {
          for: ['users', 'timeSpent', 'files'],
          name: 'Program',
          value: 'program',
          subList: []
        },
        {
          for: ['users', 'timeSpent', 'files'],
          name: 'Test',
          value: 'test',
          subList: []
        },
        {
          for: ['users', 'timeSpent'],
          name: 'Onboarding',
          value: 'onboarding',
          subList: []
        }
      ],
      typeFilters: [
        {
          for: ['program', 'test'],
          name: 'Completed',
          value: 'completed'
        },
        {
          for: ['program', 'test'],
          name: 'Pending',
          value: 'pending'
        },
        {
          for: ['program', 'test'],
          name: 'Assigned',
          value: 'assigned'
        },
        // {
        //   for: ['program', 'test'],
        //   name: 'Not Assigned',
        //   value: 'notAssigned'
        // },
        // {
        //   for: ['program', 'test'],
        //   name: 'Draft',
        //   value: 'draft'
        // },
      ],
      onboardingTypeFilters: [],
      sourceModuleList: [
        {
          name: 'Module 1',
          value: 'module_1'
        },
        {
          name: 'Module 2',
          value: 'module_2'
        },
        {
          name: 'Module 3',
          value: 'module_3'
        }
      ],
      sourceSubModuleList: [
        {
          name: 'Sub Module 1',
          value: 'sub_module_1'
        },
        {
          name: 'Sub Module 2',
          value: 'sub_module_2'
        },
        {
          name: 'Sub Module 3',
          value: 'sub_module_3'
        }
      ],
      filterGroups: [{
        name: '',
        color: '',
        filters: {},
      }],
      widgetData: {
        name: '',
        chartType: '',
        timeFrame: '',
        filters: [
          {
            name: '',
            color: '#000000',
            targetType: '',
            source: {
              type: '',
              value: '',
            }
          }
        ]
      },
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    programList: {
      type: Array,
      default: []
    },
    testList: {
      type: Array,
      default: []
    },
    onboardingList: {
      type: Array,
      default: []
    },
    onAdd: {
      type: Function,
      default: () => {}
    }
  },
  watch: {
    programList: {
      handler: function (val) {
        this.sourceList[0].subList = val.map(program => {
          return {
            name: program.name,
            value: program.therapyId
          }
        });
      },
      deep: true
    },
    testList: {
      handler: function (val) {
        this.sourceList[1].subList = val.map(test => {
          return {
            name: test.name,
            value: test.testId
          }
        });
      },
      deep: true
    },
    onboardingList: {
      handler: function (val) {
        this.sourceList[2].subList = val.map(onboarding => {
          return {
            name: onboarding.name,
            value: onboarding.onboardingId
          }
        });
      },
      deep: true
    }
  },
  methods: {
    toggleChart(type) {
      this.widgetData.chartType = type;
    },
    toggleTimeFrame(type) {
      this.widgetData.timeFrame = type;
    },
    handleSave() {
      widgetService.addWidget(this.widgetData)
          .then(() => {
            this.$snackbar.add({
              text: 'Widget added successfully',
              type: 'success'
            });
            this.onHide();
            this.onAdd();
          })
          .catch(err => {
            handleErrorResponse(err, this.$snackbar);
          });
    },
    addProgramFilter(filter) {
      if (!filter.filters.source.module) {
        filter.filters.source.module = 'null';
      } else if (!filter.filters.source.subModule) {
        filter.filters.source.subModule = 'null';
      }
    },
    removeFilterGroup(filter) {
      if (this.widgetData.filters.length === 1) {
        this.$snackbar.add({
          text: 'You cannot delete the last filter group',
          type: 'warning'
        });
        return;
      }

      this.widgetData.filters = this.widgetData.filters.filter(f => f !== filter);
    },
    addFilterGroup() {
      this.widgetData.filters.push({
        name: '',
        color: '#000000',
        targetType: '',
        source: {
          type: '',
          value: '',
        }
      });
    },
    getOnboarding(onboardingId) {
      this.onboardingTypeFilters = [];
      onboardingService.getOnboardingDetail(onboardingId).then(response => {
        const tempOnboardingData = response.data.data;

        if (!tempOnboardingData || tempOnboardingData.length === 0) {
          return;
        }

        try {
          tempOnboardingData?.questions?.map(onboardItem => {
            const tempSubList = [];

            if (!onboardItem?.content?.options || onboardItem?.content?.options?.length === 0) {
              return;
            }

            onboardItem?.content?.options.map(onboardItemOption => {
              tempSubList.push({
                name: onboardItemOption.name,
                value: onboardItemOption.id,
              })
            })


            this.onboardingTypeFilters.push({
              for: ['onboarding'],
              name: onboardItem.name,
              value: onboardItem.questionId,
              subList: tempSubList
            })

          });
        } catch (e) {
          console.error(e);
        }
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      })
    }
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  padding: 30px;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 12px;
  max-height: calc(100vh - 48px);

}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 50%;
}

.cross{
  padding: 8px;
}

.modal-header{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0;
  border: none;
}

.modal-header .left{
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.modal-header .right{
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.modal-header-description{
  font-family: euclid_regular, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
}

.step-header-wrapper {
  margin-top: 24px;
}

.step-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.chart-type {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
  border: 1px solid #f1f1f1;
  background-color: #f8f8f8;
  border-radius: 9px;
  transition: all 0.2s ease;
}


.chart-type .chart-image {
  width: 160px;
}

.chart-type .check {
  opacity: 0;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
  transition: all 0.2s ease;
}

.chart-type.selected, .chart-type:hover {
  background-color: #E3E7FA;
  border: 1px solid #4A68F8;
}

.chart-type.selected .check {
  opacity: 1;
}

.slide-horizontal-enter-active,
.slide-horizontal-leave-active {
  transition: all 0.3s ease;
}

.slide-horizontal-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.slide-horizontal-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.step-wrapper {
  position: relative;
  width: 100%;
}

.data-group {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #f1f1f1;
  background-color: #f8f8f8;
  padding: 8px;
  border-radius: 9px;
}

.data-group .left {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.data-group .filter-row {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  gap: 4px;
  padding: 8px;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  border-radius: 10px;
  flex-wrap: wrap;
}

.input-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.data-input {
  border: 1px solid #f1f1f1;
  border-radius: 8px;
  padding: 8px;
  font-size: 10px;
}

.data-input.color {
  background-color: #fff;
  height: 36px;
  width: 36px;
  aspect-ratio: 1;
}

.data-group .right {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.filter {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.data-step-wrapper {
  width: 100%;
  height: 100%;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.add-filter-button {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #f1f1f1;
  background-color: #fff;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  transition: all 0.2s ease;
}

.add-filter-button img {
  width: 16px;
  height: 16px;
}

.add-filter-button:hover {
  background-color: #f1f1f1;
}

.data-text {
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-size: 10px;
  font-style: normal;
  line-height: 10px;
  height: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  margin-left: 3px;
  margin-right: 3px;
  flex: 1;
}

.add-filter-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.add-filter-group-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  transition: all 0.2s ease;
  gap: 3px;
  padding: 5px 10px;
}

.add-filter-group-button span {
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
}

.add-filter-group-button img {
  width: 18px;
  height: 18px;
}

.add-filter-group-button:hover {
  background-color: #f1f1f1;
}

.data-group:hover .delete-wrapper {
  opacity: 1;
}

.delete-wrapper {
  opacity: 0;
  height: 24px;
  width: 24px;
  position: absolute;
  display: flex;
  top: -12px;
  right: -10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #f1f1f1;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}

.delete-wrapper img {
  width: 8px;
  height: 8px;
}

.delete-wrapper:hover {
  background-color: #f1f1f1;
}

.info-header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 24px;
}

.info-header h3 {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 14px;
  font-style: normal;
  line-height: 14px;
}

.info-header h4 {
  font-family: euclid_regular, sans-serif;
  color: #667085;
  font-size: 12px;
  font-style: normal;
  line-height: 12px;
}

.time-frame-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 24px;
}

.time-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid #f1f1f1;
  background-color: #f8f8f8;
  border-radius: 9px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.time-frame span {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  transition: all 0.2s ease;
}

.time-frame:hover {
  background-color: #E3E7FA;
  border: 1px solid #4A68F8;
}

.time-frame.selected {
  background-color: #E3E7FA;
  border: 1px solid #4A68F8;
}

.time-frame.selected span {
  color: #4A68F8;
}

.footer-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
}

.btn-style{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  height: 48px;
  min-width: 120px;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  font-size: 14px;
  outline: none;
  border: none;
}

@media (max-width: 1170px) {
  .chart-list {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
