<template>
  <div class="filter-view">
    <div class="flex-row">
      <UserFilters />
    </div>
    <div v-if="companyId!==null" class="invite-holder">
      <div class="invite-code" data-tooltip="Kullanıcıları bu kod ile davet edebilirsiniz" >
        {{inviteCode}}
        <img src="../../../assets/images/copyOutline.svg" @click="copyCode" class="invite-code-icon">
      </div>
      <img @click="changeCode" class="refresh-icon" src="../../../assets/images/refresh.svg">
    </div>
    <div class="justify-end-div" v-if="rightAction">
      <div class="justify-end-search" style="padding-right: 30px">
        <ExpandableSearch :searchText="searchText" :on-change="handleSearch" />
      </div>
    </div>
  </div>

  <div class="table-container">
    <table class="content-table">
      <thead class="header-table">
      <tr class="trhead">
        <th><input type="checkbox" :checked="selectAll" @change="toggleAll"></th>
        <th>{{ $t('name_payment') }}</th>
        <th>{{ $t('status_payment_title') }} <img src="../../../assets/images/status.svg"></th>
        <th style="text-align: start">{{ $t('added_date') }} <img src="../../../assets/images/status.svg"></th>
        <th style="text-align: center;">
          {{ $t('total_therapies_tab') }} <img src="../../../assets/images/status.svg">
        </th>
        <th>{{ $t('email_address') }}</th>
        <th>{{$t('phone_number_person') }}</th>
        <th>{{$t('user_department_name')}}</th>
      </tr>
      </thead>
      <tbody class="tbody-container">
      <tr class="tr-class" v-if="!loading" v-for="user in users" :key="user.userId">
        <td class="td-style"><input type="checkbox" @change="(e) => toggleUser(e, user.userId)" :checked="selectedUserIds.includes(user.userId)"></td>

        <td class="td-style">
          <router-link :to="'/users/detail/' + user.userId" class="name-row">
            <template v-if="user.avatarUrl">
              <img class="company-icon" :src="user.avatarUrl" />
            </template>
            <template v-else>
              <em class="no-data-text">{{$t('no_data')}}</em>
            </template>
            <template v-if="user.name || user.surname">
              <span class="test-class">{{ truncateText(user.name + ' ' + user.surname, 15) }}</span>
            </template>
            <template v-else>
              <em class="no-data-text">N/A</em>
            </template>
          </router-link>
        </td>

        <td class="td-style">
            <span class="test-class">
              <img v-if="user.status=== true" src="../../../assets/images/active.svg">
              <img v-else src="../../../assets/images/failed.svg">
              <span v-if="user.status">
                {{ $t('active') }}
              </span>
              <span v-else>
                {{ $t('inactive') }}
              </span>
            </span>
        </td>

        <td class="td-style">
            <span class="test-class">
              <img src="../../../assets/images/datepicker.svg">
              {{ truncateText(user.addedDate, 10) }}
            </span>
        </td>

        <td style="text-align: center" class="td-style">
            <span class="test-class">
              <img src="../../../assets/images/therapies.svg">
              {{ truncateText($t('program_count', {programCount: user.therapiesCount}), 10) }}
            </span>
        </td>

        <td class="td-style">
          <span class="test-class">{{ truncateText(user.email, 20) }}</span>
        </td>

        <td class="td-style">
          <template v-if="user.phoneNumber">
            <span class="test-class">{{ truncateText(user.phoneNumber, 15) }}</span>
          </template>
          <template v-else>
            <em class="no-data-text">N/A</em>
          </template>
        </td>

        <td class="td-style">
          <template v-if="user.department?.name">
            <span class="test-class">{{ truncateText(user.department.name, 15) }}</span>
          </template>
          <template v-else>
            <em class="no-data-text">N/A</em>
          </template>
        </td>
      </tr>

      <tr v-else>
        <td colspan="8" class="table-loading">
          <VueSpinner size="16" height="100%" color="#8c8c8c" />
          <span class="text">Loading</span>
        </td>
      </tr>

      <tr v-if="!loading && users.length === 0 ">
        <td colspan="8" class="table-loading">
          <span class="text">No data found</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="bottom-actions" v-if="selectedUserIds.length > 0">
    <span class="selected-label">{{$t('selected')}} {{selectedUserIds.length}} {{$t('user')}}</span>
    <ActionDropdown :actions="departmentActions" :on-action="handleDepartmentAction" :placeholder="$t('departments')" />
  </div>
</template>

<script>
import AddUserModal from '@/components/modals/InviteUserModal.vue';
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import {VueSpinner} from "vue3-spinners";
import UserFilters from "@/components/tables/User/UserFilters.vue";
import companyService from "@/service/companyService";
import commonService from "@/service/commonService";
import userService from "@/service/userService";
import {handleErrorResponse} from "@/utils/utils";
import ActionDropdown from "@/components/ActionDropdown.vue";

export default {
  components: {
    UserFilters,
    AddUserModal,
    ExpandableSearch,
    VueSpinner,
    ActionDropdown
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    addNewUser: {
      type: Function,
      default: () => {}
    },
    users: {
      type: Array,
      required: true
    },
    rightAction: {
      type: Boolean,
      default: true
    },
    companyId:{
      type:Number,
      default:null
    },
    code: {
      type:Number,
      default:null
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      inviteCode: null,
      showModal: false,
      selectAll: false,
      showFilter: false,
      searchText: '',
      selectedUserIds: [],
      departmentList: [],
      departmentActions: [],
      isProcessing: false
    }
  },
  created() {
    this.inviteCode = this.code;
    this.loadDepartments();
  },
  methods: {
    loadDepartments() {
      commonService.getDepartments().then(response => {
        this.departmentList = response.data.data.departments.filter(department =>
            department.name && department.name.trim() !== ''
        );

        this.departmentActions = this.departmentList.map(dept => ({
          name: dept.name,
          action: dept.id.toString()
        }));

        console.log("Kullanıcı grubu işlemleri yüklendi:", this.departmentActions);
      }).catch(err => {
        handleErrorResponse(err, this.$snackbar);
      });
    },
    copyCode() {
      if(this.inviteCode) {
        navigator.clipboard
            .writeText(this.inviteCode)
            .then(() => {
              this.$snackbar.add({
                text: this.$t("create_invite_code_success"),
                type: "success"
              });
            })
      }
      if(this.inviteCode === null){
        this.$snackbar.add({
          text: this.$t("create_invite_code_error"),
          type:"error"
        });
      }
    },
    changeCode(){
      companyService.changeInviteCode(this.companyId).then(response => {
        this.inviteCode = response.data.data.inviteCode;
      });
    },
    toggleAll() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.selectedUserIds = this.users.map(user => user.userId);
      } else {
        this.selectedUserIds = [];
      }
    },
    toggleUser(e, userId) {
      if (e.target.checked) {
        this.selectedUserIds.push(userId);
      } else {
        this.selectedUserIds = this.selectedUserIds.filter(id => id !== userId);
      }
    },
    handleSearch(event) {
      this.searchText = event.target.value;
    },
    truncateText(text, maxLength) {
      if (!text) return "";
      return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    },
    handleDepartmentAction(action) {
      if (!action || !this.selectedUserIds.length || this.isProcessing) {
        return;
      }

      this.isProcessing = true;

      const departmentId = action;

      this.assignDepartmentToUsers(departmentId);
    },
    assignDepartmentToUsers(departmentId) {
      const department = this.departmentList.find(dept => dept.id.toString() === departmentId);

      console.log("API çağrısı yapılıyor:", {
        userIds: this.selectedUserIds,
        departmentId: departmentId
      });

      userService.assignDepartmentToUsers(this.selectedUserIds, departmentId)
          .then((response) => {
            console.log("API yanıtı:", response);

            this.$snackbar.add({
              text: `${this.selectedUserIds.length} kullanıcı ${department ? department.name : ''} kullanıcı grubuna atandı`,
              type: 'success'
            });

            this.selectedUserIds = [];
            this.selectAll = false;
            this.refreshData();
          })
          .catch(err => {
            handleErrorResponse(err, this.$snackbar);
          })
          .finally(() => {
            this.isProcessing = false;
          });
    }
  }
}
</script>

<style>

.bottom-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
  background-color: #fff;
  border-top: 1px solid #e5e7eb;
  margin-top: 8px;
}

.selected-label {
  margin-right: 16px;
  font-size: 14px;
  color: #6b7280;
}

.invite-code-icon{
  align-self: center;
  height: 15px;
  justify-content: center;
}
.refresh-icon{
  height: 20px;
}
.invite-code{
  border: 1px solid #EAECF0;
  background-color: #FCFCFD;
  border-radius: 15px;
  padding: 10px;
  color: #000000;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.invite-code::after {
  content: attr(data-tooltip);
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10;
  white-space: normal;
  width: 150px;
  font-size: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #040C2D;
}
.invite-code:hover::after {
  opacity: 1;
  visibility: visible;
}
.invite-holder{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.invite-code-icon{
  align-self: center;
  height: 15px;
  justify-content: center;
}
.refresh-icon{
 height: 20px;
}
.invite-code{
  border: 1px solid #EAECF0;
  background-color: #FCFCFD;
  border-radius: 15px;
  padding: 10px;
  color: #000000;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
}
.invite-code::after {
  content: attr(data-tooltip); /* Açıklama metni data-tooltip özelliğinden alınır */
  position: absolute;
  top: -35px; /* Kutunun span'ın üstünde görünmesi için */
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 10;
  white-space: normal;
  width: 150px;
  font-size: 10px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Daha modern bir görünüm için gölge ekle */
  background-color: #040C2D;

}
.invite-code:hover::after {
  opacity: 1;
  visibility: visible;
}
.invite-holder{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
.table-container {
  width: 100%;
  height: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
}
.trhead{
  background-color: transparent;
}
.tr-class{
  border-bottom-width: 1px;
  border-bottom-color: #F2F4F7;
}

tr td {
  padding-bottom: 12px;
  padding-top: 12px;
  align-items: center;
}
.table-container {
  width: 100%;
}
tr th{
  align-items: center;
  font-size: 12px;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.header-table,
.content-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.header-table {
  background-color: #F9FAFB;
  border-radius: 8px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #F2F4F7;
}

.tbody-container {
  overflow-y: auto;
}
.tr-class td tr {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.test-class{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
}
.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}

.row-item.filter {
  background-color: transparent;
}

.row-item-txt{
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.filter-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  min-height: 52px;
}
.justify-end-div{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.justify-end-search{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
}

.company-icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.show-filters{
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}

.row-item-icon {
  height: 16px;
  width: 16px;
}

.name-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.name-row span {
  font-family: euclid_medium, sans-serif;
  color: #000;
}

.name-row:hover.name-row span{
  color: #4A68F8;
}
.td-style {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.name-row {
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}

.company-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
}

.test-class {
  font-weight: bold;
  color: #333;
}

.no-data-text {
  font-style: italic;
  color: #B6C1DB;
  font-size: 12px;
  font-weight: bold;
}
</style>
