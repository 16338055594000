<template>
  <div v-if="step?.flowType !== 'start' && step?.flowType !== 'notification'" class="main-container">
    <div class="submodule-container">
      <div class="submodule-info">
        <div class="line-holder">
        <span class="image-background">
          <img v-if="step.data?.content?.type"  :src="getIcon(step.data?.content?.type)" alt="icon" class="submodule-icon" />
          <img v-else :src="getIcon(step?.flowType)" alt="icon" class="submodule-icon" />
        </span>
          <span v-if="step?.flowType!=='end' && (!lastData) &&(index!==stepCount-1)" class="connection">
            <span  v-if="nextStep?.type==='notification'" class="notification-container">
                <div class="quarter-circle"></div>
                <span class="notification-text">{{ nextStep?.data.name}}</span>
            </span>
          </span>
          <span v-if="step?.flowType!=='end' && (index===stepCount-1)" class="gradient-connection">
            <span  v-if="nextStep?.type==='notification'" class="notification-container">
                <div class="quarter-circle"></div>
                <span class="notification-text">{{ nextStep?.data.name}}</span>
            </span>
          </span>
        </div>
        <div class="submodule-headers">
          <span v-if="step.data?.name" class="submodule-title">{{step.data.name}}</span>
          <span v-if="step?.flowType==='end'" class="submodule-title">{{ $t('ended') }}</span>
          <span v-if="step.data?.description" class="submodule-description">{{step.data.description}}</span>
        </div>
      </div>
      <div class="time-info">
        <img src="@/assets/images/timeClock.svg" style="cursor:initial">
        <span class="submodule-time">{{formattedTime}} ago</span>
      </div>
    </div>
  </div>
</template>

<script>
import textOutline from "@/assets/images/textOutlineWhite.svg";
import audioOutline from "@/assets/images/audioOutlineWhite.svg";
import videoOutline from "@/assets/images/videoOutlineWhite.svg";
import receipt2Outline from "@/assets/images/receipt2OutlineWhite.svg";
import messageOutline from "@/assets/images/messageOutlineWhite.svg";
import sheildOutline from "@/assets/images/sheildOutlineWhite.svg";
import failedSheildOutline from "@/assets/images/failedSheildOutlineWhite.svg";
import receiptOutline from "@/assets/images/receiptOutlineWhite.svg";
import checkCircle from "@/assets/images/checkCircleWhite.svg";
import taskOutline from "@/assets/images/taskOutlineWhite.svg";
import noteOutline from "@/assets/images/noteOutlineWhite.svg";
import calculatorOutline from "@/assets/images/calculatorOutlineWhite.svg";
import settingOutline from "@/assets/images/settingOutlineWhite.svg";
import starOutline from "@/assets/images/starOutlineWhite.svg";
import dragOutline from "@/assets/images/dragOutline.svg";
import fillTheBlankOutline from "@/assets/images/fillTheBlankOutline.svg";
import matchOutline from "@/assets/images/matchOutline.svg";
import linkCircleOutline from "@/assets/images/linkCircleOutlineWhite.svg"
import endJourneyOutlineWhite from "@/assets/images/endJourneyOutlineWhite.svg"

export default {
  data() {
    return {
      formattedTime: '',
    }
  },
  props: {
    stepCount:{
      type: Number,
      default: 0
    },
    step: {
      type: Object,
      default: null
    },
    nextStep:{
      type: Object,
      default: null
    },
    lastData:{
      type:Boolean,
      default:false
    },
    index:{
      type:Number,
      default:null,
    }

  },

  mounted() {
    this.calculateTime()
  },
  methods: {

    getIcon(type){
      if (type === 'welcomeText') {
        return textOutline;
      } else if (type === 'audio') {
        return audioOutline;
      } else  if (type === 'video') {
        return videoOutline;
      } else if (type === 'summary') {
        return receipt2Outline;
      } else if (type === 'selfChat') {
        return messageOutline;
      } else if (type === 'success') {
        return sheildOutline;
      } else if (type === 'failed') {
        return failedSheildOutline;
      }  else if (type === 'text') {
        return receiptOutline;
      }else if (type === 'scenarioText') {
        return messageOutline;
      } else if (type === 'single') {
        return checkCircle;
      }else if (type === 'multiple') {
        return taskOutline;
      }else if (type === 'yesNo') {
        return noteOutline;
      }else if (type === 'number') {
        return calculatorOutline;
      }else if (type === 'text') {
        return receiptOutline;
      }else if (type === 'grading') {
        return settingOutline;
      }else if (type === 'rating') {
        return starOutline;
      }else if (type === 'dragAndDrop') {
        return dragOutline;
      }else if (type === 'fillTheBlank') {
        return fillTheBlankOutline;
      }else if (type === 'matchType') {
        return matchOutline;
      }else if (type === 'assign') {
        return linkCircleOutline;
      }else if (type === 'end') {
        return endJourneyOutlineWhite;
      }
      else  {
        return "Undefined type";
      }
    },
    calculateTime() {
      if (!this.step?.created_at) return

      // Parse the date in DD-MM-YYYY HH:mm:ss format
      const [datePart, timePart] = this.step.created_at.split(' ')
      const [day, month, year] = datePart.split('-')
      const [hours, minutes] = timePart.split(':')

      const createdDate = new Date(year, month - 1, day, hours, minutes)
      const now = new Date()
      const diffInMilliseconds = now - createdDate

      // Convert to various time units
      const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60))
      const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
      const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24))
      const diffInMonths = Math.floor(diffInDays / 30)

      // Format based on the time difference
      if (diffInMinutes < 1) {
        this.formattedTime = 'just now'
      } else if (diffInMinutes < 60) {
        this.formattedTime = `${diffInMinutes} minutes`
      } else if (diffInDays < 1) {
        const remainingMinutes = diffInMinutes % 60
        this.formattedTime = `${diffInHours} hours ${remainingMinutes} minutes`
      } else if (diffInMonths < 1) {
        const remainingHours = diffInHours % 24
        this.formattedTime = `${diffInDays} days ${remainingHours} hours`
      } else {
        const remainingDays = diffInDays % 30
        this.formattedTime = `${diffInMonths} months ${remainingDays} days`
      }
    }
  },
  watch: {
    step: {
      handler() {
        this.calculateTime()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.submodule-icon{
 width: 24px;
  height: 24px;
  cursor: initial;
}
.time-info{
  display: flex;
  flex-direction: row;
  height: min-content;
  margin-top: 12px;
}
.notification-text{
  width: 300px;
  position: absolute;
  bottom:-5px;
  left:45px;
  font-family: euclid_semi_bold, 'sans-serif';
  font-size: 12px;
  color: #1C3CD5;
}
.quarter-circle {
  width: 35px;  /* Daire genişliği */
  height: 30px; /* Daire yüksekliği */
  border: 2px solid #1C3CD5;
  border-radius: 0 0 0 50px; /* Sol alt çeyrek daire */
  border-top: 0;
  border-right: 0;
  position: relative;
}

.notification-container{
  width: 100%;
  position: absolute;
  display: flex;
  align-items: flex-end;
  margin-bottom: 30px;

}
.line-holder{
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.connection{
  display: flex;
  background-color: #1C3CD5;
  height: 100px;
  width: 2px;
  z-index: 1;
  align-items: center;
  justify-content: flex-start;
}
.gradient-connection {
  display: flex;
  background-image: linear-gradient(180deg,
  rgba(28,60,213,1) 0%,
  rgba(28,60,213,1) 20%,
  transparent 20%,
  transparent 30%,
  rgba(28,60,213,0.8) 30%,
  rgba(28,60,213,0.8) 40%,
  transparent 40%,
  transparent 50%,
  rgba(28,60,213,0.6) 50%,
  rgba(28,60,213,0.6) 60%,
  transparent 60%,
  transparent 70%,
  rgba(28,60,213,0.4) 70%,
  rgba(28,60,213,0.4) 80%,
  transparent 80%,
  transparent 90%,
  rgba(28,60,213,0.2) 90%,
  rgba(28,60,213,0.2) 100%
  );
  height: 50px;
  width: 2px;
  z-index: 1;
  align-items: center;
  justify-content: flex-start;
}
.submodule-time{
  font-family: 'euclid_regular', sans-serif;
  font-size: 12px;
  color:#667085;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.submodule-description{
  font-family: 'euclid_medium', sans-serif;
  font-size:12px;
  color:#6D6D6D;

}
.submodule-title{
  max-width:400px ;
  font-family: euclid_semi_bold, 'sans-serif';
  font-size: 18px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.submodule-time{
  margin-left: 10px;
}
.image-background {
  display: flex;
  width: 50px;
  height: 50px;
  background-color: #1C3CD5;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.submodule-headers {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 12px;
}
.submodule-info {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.main-container {
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 100%;
}
.submodule-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>