<template>
  <div class="match-container no-text-select" :ref="'container' + String(order)">
    <div class="input-col">
      <div class="input-row" v-for="inputData in inputs" :key="inputData.id">
        <div class="input-wrapper">
          <div
              class="dot right"
              :style="{ backgroundColor: inputData.color || '#00D68F' }"
              :data-id="inputData.connectionId"
              :data-container="'container' + String(order)"
              @mousedown="startDrag($event, inputData.connectionId, 'right')"
          />
          <input
              v-if="!leftPartImage"
              class="form-input w-100"
              :placeholder="$t('enter_value')"
              v-model="inputData.leftValue"
          />
          <FileDrop
              v-else
              :image-editor-enabled="false"
              :on-file-selected="(file) => onFileSelected(file, inputData.id, 'left')"
              :selected-file="inputData.attachLeft?.file?.attachUrl"
              :not-binary="true"
              :file-info="inputData.attachLeft?.file?.info"
              :on-delete="() => handleImageDelete(inputData.attachLeft)"
              :attach="inputData.attachLeft"



          />
        </div>

        <div class="input-spacer" />

        <div class="input-wrapper">
          <div
              class="dot left"
              :data-id="inputData.connectionId"
              :data-container="'container' + String(order)"
              @mousedown="startDrag($event, inputData.connectionId, 'left')"
          />
          <input
              v-if="!rightPartImage"
              class="form-input w-100"
              :placeholder="$t('enter_value')"
              v-model="inputData.rightValue"
          />
          <FileDrop
              v-else
              :image-editor-enabled="false"
              :on-file-selected="(file) => onFileSelected(file, inputData.id, 'right')"
              :selected-file="inputData.attachRight?.file?.attachUrl"
              :not-binary="true"
              :file-info="inputData.attachRight?.file?.info"
              :on-delete="() => handleImageDelete(inputData.attachRight)"
              :attach="inputData.attachRight"


          />
        </div>

        <div class="remove-wrapper no-text-select">
          <img src="@/assets/images/trash.svg" @click="removeInput(inputData.connectionId)" />
        </div>
      </div>
    </div>

    <div class="bottom-buttons-wrapper">
      <button @click="addInput">{{$t('add_new_input')}}</button>
      <button @click="resetConnections">{{$t('clear_connections')}}</button>
    </div>

    <!-- SVG for drawing the connection lines -->
    <svg class="connection-lines" ref="svgContainer">
      <line
          v-for="connection in connections"
          :key="connection.connectionId"
          :x1="connection.x1"
          :y1="connection.y1"
          :x2="connection.x2"
          :y2="connection.y2"
          :stroke="connection.color ? connection.color : '#00D68F'"
          stroke-width="2"
      />
      <!-- Line being drawn while dragging -->
      <line
          v-if="dragging"
          :x1="currentConnection.x1"
          :y1="currentConnection.y1"
          :x2="currentConnection.x2"
          :y2="currentConnection.y2"
          stroke="#e0e0e0"
          stroke-width="2"
      />
    </svg>
  </div>
</template>

<script>
import {colorPalette, uuidv4} from "@/utils/utils";
import FileDrop from "@/components/FileDrop.vue";

export default {
  components: {FileDrop},
  data() {
    return {
      inputs: [
        {
          id: 1,
          connectionId: uuidv4(),
          leftValue: '',
          rightValue: ''
        },
      ],
      connections: [],
      dragging: false,
      currentConnection: null,
    };
  },
  props: {
    deleteList:{
      type:Array,
      default:()=>[]
    },
    order: {
      type: Number,
      default: 1,
    },
    inputValues: {
      type: Array,
      default: () => [],
    },
    connectionValues: {
      type: Array,
      default: () => [],
    },
    leftPartImage: {
      type: Boolean,
      default: true,
    },
    rightPartImage: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  watch: {
    inputValues: {
      handler: function(newVal) {
        if (newVal.length === 0) {
          return;
        }
        this.inputs = newVal.map(input => ({
          ...input,
          connectionId: input.connectionId || uuidv4()
        }));
      },
      immediate: true,
      deep: true,
    },
    connectionValues: {
      handler(value) {
        if (value.length === 0) {
          return;
        }

        this.connections = value;
        this.debouncedRecalculateLines();
      },
      immediate: true,
    },
    leftPartImage: {
      handler() {
        this.$nextTick(() => {
          this.recalculateLines();
        });
      },
      immediate: true,
    },
    rightPartImage: {
      handler() {
        this.$nextTick(() => {
          this.recalculateLines();
        });
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('resize', this.debouncedRecalculateLines);

    if (this.inputValues.length > 0) {
      this.inputs = this.inputValues.map(input => ({
        ...input,
        connectionId: input.connectionId || uuidv4()
      }));
    }

    if (this.connectionValues.length > 0) {

      this.connections = this.connectionValues;

      this.$nextTick(() => {
        this.recalculateLines();
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debouncedRecalculateLines);
  },
  methods: {
    handleImageDelete(element) {
      this.deleteList.push(element.file.fillableId);
      console.log(this.deleteList);
      element.file.attach = null;
      element.file.attachUrl = null;
    },

    debouncedRecalculateLines() {
      this.recalculateLines();
      clearTimeout(this._recalculateTimeout);
      this._recalculateTimeout = setTimeout(() => {
        this.recalculateLines();
      }, 100);
    },

    startDrag(event, connectionId, side) {
      const containerRect = this.$refs['container' + String(this.order)].getBoundingClientRect();

      this.dragging = true;

      const rect = event.target.getBoundingClientRect();
      const x1 = rect.left + rect.width / 2 - containerRect.left;
      const y1 = rect.top + rect.height / 2 - containerRect.top;

      this.currentConnection = {
        connectionId,
        x1,
        y1,
        x2: x1,
        y2: y1,
        side,
        targetId: null,
        targetSide: null,
      };

      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.endDrag);
    },

    onMouseMove(event) {
      if (!this.dragging) return;

      const containerRect = this.$refs['container' + String(this.order)].getBoundingClientRect();
      this.currentConnection.x2 = event.clientX - containerRect.left;
      this.currentConnection.y2 = event.clientY - containerRect.top;

      this.$forceUpdate();
    },

    endDrag(event) {
      if (!this.dragging) return;

      const containerRect = this.$refs['container' + String(this.order)].getBoundingClientRect();
      const targetDot = document.elementFromPoint(event.clientX, event.clientY);

      if (targetDot &&
          targetDot.classList.contains('dot') &&
          targetDot.classList.contains('left') &&
          targetDot.dataset.container === 'container' + String(this.order)) {

        const existingConnectionIndex = this.connections.findIndex(
            (connection) => connection.targetId === targetDot.dataset.id
        );

        if (existingConnectionIndex !== -1) {
          this.cleanupDrag();
          return;
        }

        const existingSourceConnectionIndex = this.connections.findIndex(
            (connection) => connection.connectionId === this.currentConnection.connectionId
        );

        if (existingSourceConnectionIndex !== -1) {
          this.connections.splice(existingSourceConnectionIndex, 1);
        }

        const rect = targetDot.getBoundingClientRect();
        this.currentConnection.x2 = rect.left + rect.width / 2 - containerRect.left;
        this.currentConnection.y2 = rect.top + rect.height / 2 - containerRect.top;
        this.currentConnection.targetId = targetDot.dataset.id;
        this.currentConnection.targetSide = 'left';
        this.currentConnection.color = colorPalette[this.connections.length % colorPalette.length];

        const input = this.inputs.find((input) => input.connectionId === this.currentConnection.connectionId);
        if (input) {
          input.color = this.currentConnection.color;
        }

        this.connections.push({ ...this.currentConnection });
        this.emitChanges();
      }

      this.cleanupDrag();
    },

    cleanupDrag() {
      this.dragging = false;
      this.currentConnection = null;
      document.removeEventListener('mousemove', this.onMouseMove);
      document.removeEventListener('mouseup', this.endDrag);
    },

    recalculateLines() {
      if (!this.connections.length) return;

      const containerRect = this.$refs['container' + String(this.order)]?.getBoundingClientRect();
      if (!containerRect) return;

      this.connections.forEach((connection) => {
        const startDot = document.querySelector(
            `.dot[data-container="container${this.order}"][data-id="${connection.connectionId}"].${connection.side}`
        );
        const targetDot = document.querySelector(
            `.dot[data-container="container${this.order}"][data-id="${connection.targetId}"].${connection.targetSide}`
        );

        if (startDot && targetDot) {
          const startRect = startDot.getBoundingClientRect();
          const targetRect = targetDot.getBoundingClientRect();

          connection.x1 = startRect.left + startRect.width / 2 - containerRect.left;
          connection.y1 = startRect.top + startRect.height / 2 - containerRect.top;
          connection.x2 = targetRect.left + targetRect.width / 2 - containerRect.left;
          connection.y2 = targetRect.top + targetRect.height / 2 - containerRect.top;
        } else {
          console.warn('Could not find start or target dot', this.order);
        }
      });

      this.$forceUpdate();
    },

    resetConnections() {
      this.connections = [];
      this.inputs.forEach((input) => {
        input.color = null;
      });
      this.emitChanges();
    },

    addInput() {
      this.inputs.push({
        id: this.inputs.length + 1,
        connectionId: uuidv4(),
        leftValue: '',
        rightValue: ''
      });
      this.emitChanges();
    },

    removeInput(connectionId) {
      const inputIndex = this.inputs.findIndex((input) => input.connectionId === connectionId);
      if (inputIndex !== -1) {
        this.inputs.splice(inputIndex, 1);
      }

      this.connections = this.connections.filter(
          (connection) => connection.connectionId !== connectionId && connection.targetId !== connectionId
      );

      this.emitChanges();
    },

    onFileSelected(file, id, side) {
      const input = this.inputs.find((input) => input.id === id);
      if (input) {
        if (side === 'right') {
          input.attachRight = file;
        } else {
          input.attachLeft = file;
        }
      }
      this.emitChanges();
    },

    emitChanges() {
      const tempConnections = this.connections.map((connection) => ({
        id: connection.id,
        connectionId: connection.connectionId,
        side: connection.side,
        targetId: connection.targetId,
        targetSide: connection.targetSide,
        color: connection.color,
      }));

      this.onChange({
        connections: tempConnections,
        inputs: this.inputs,
      });
    }
  },
};
</script>

<style scoped>
.match-container {
  position: relative;
}

.input-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.input-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.input-spacer {
  width:  40px;
  height: 1px;
}

.dot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #667085;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: 3px solid #fff;
  cursor: pointer;
  z-index: 2;
}

.dot.right {
  right: -8px;
  background-color: #00D68F;
}

.dot.left {
  left: -8px;
}

.connection-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.bottom-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.bottom-buttons-wrapper button {
  background-color: transparent;
  color: #667085;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 12px;
  font-family: 'euclid_medium', sans-serif;
  margin-top: 15px;
  transition: color 0.2s;
}

.bottom-buttons-wrapper button:hover {
  color: #4A68F8;
}

.remove-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.remove-wrapper img {
  width: 24px;
  padding: 5px;
  border-radius: 50%;
}

.remove-wrapper img:hover {
  background-color: #f4cac6;
}

.form-input {
  font-size: 14px;
}

svg {
  shape-rendering: crispEdges;
  overflow: visible;
}
</style>
