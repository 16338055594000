import axios from './providers/axios';

const userService = {
    getUsers(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/user/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/user/store?limit=20&page=${page}`);
        }
    },
    getUserDetails(id) {
        return axios.get(`/v1/admin/user/getFromDetail/${id}`);
    },
    addUser(data) {
        return axios.post('/v1/admin/user/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    editUser(data) {
        return axios.post('/v1/admin/user/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteUser(id) {
        return axios.delete(`/v1/admin/user/delete/${id}`);
    },
    getDetailLogs(lang,id){
        return axios.get(`/v1/admin/user/getLogsByUserId/${id}`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    },

    assignDepartmentToUsers(data) {
        return axios.post('/v1/admin/user/assignToDepartment', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

}

export default userService;
