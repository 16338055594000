import axios from './providers/axios';

const fileService = {
    getFiles(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/files/store?page=${page}&limit=20${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/files/store?page=${page}&limit=20`);
        }
    },
    setFileStatus(data) {
        return axios.put(`/v1/admin/files/updateStatus`, data);
    },

    uploadFile(data) {
        return axios.post('/v1/admin/files/upload', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteFile(id) {
        return axios.delete(`/v1/admin/files/fileable/delete/${id}`);
    },
}

export default fileService;
