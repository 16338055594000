<template>
  <div class="header-view">
    <div class="left">
      <img  @click="onMenuClick" src="@/assets/images/menu.svg" class="sidebar-icon" alt="Dashboard Icon" />
      <div class="welcome-view">
        <h3 class="welcome-headertxt">{{ $t('welcome_title') }}, {{getName}}</h3>
        <h3 class="welcome-headerquestion">{{ $t('how_can_we_help') }}</h3>
      </div>
      <div class="company-dropdown-wrapper" v-click-outside="hideCompanyDropdown">
        <div class="company-dropdown" @click="toggleCompanyDropdown" v-if="!companyLoading">
          <span>{{ currentCompany }}</span>
          <img src="../assets/images/arrowdown.svg" class="arrow-down" :class="companyDropdown && 'rotate'"/>
        </div>

        <div class="dropdown-loading-wrapper" v-if="companyLoading">
          <VueSpinnerIos size="14" color="#000000"/>
        </div>

        <div class="dropdown-content" v-if="companyDropdown">
          <div class="search-box" v-if="searchEnabled">
            <input
                type="text"
                class="search-input"
                v-model="searchQuery"
                :placeholder="$t('search')"
                @input="filterCompanyItems"
                ref="searchInput"
            />
          </div>

          <div class="list scroll-design">
            <div class="item" v-for="item in filteredCompanyItems" @click="changeCompany(item)">
              <span>{{ item.name }}</span>
            </div>
            <div class="no-results" v-if="filteredCompanyItems.length === 0 && searchQuery">
              <span>{{ $t('no_results_found') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="locale-changer">
      <TranslationDropdown :show-warning="false" :selected-language="$i18n.locale" :on-select="updateLanguage"/>
      <NotificationModal />
      <img class="header-icon" src="@/assets/images/messageSingleOutline.svg" @click="handleGoMessages" />
    </div>
  </div>
</template>


<script>
import NotificationModal from "@/components/modals/NotificationModal.vue";
import ActionDropdown from "@/components/ActionDropdown.vue";
import TranslationDropdown from "@/components/TranslationDropdown.vue";
import companyService from "@/service/companyService";
import {handleErrorResponse} from "@/utils/utils";
import {VueSpinnerIos} from "vue3-spinners";

export default {
  name: 'Header',
  components: {VueSpinnerIos, TranslationDropdown, ActionDropdown, NotificationModal},
  data() {
    return {
      companyDropdown: false,
      companyLoading: true,
      companyItems: [],
      searchQuery: '',
      filteredCompanyItems: [],
      searchEnabled: true, // search-enabled özelliği
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Türkçe', code: 'tr' },
      ]
    }
  },
  props: {
    currentCompany: {
      type: String,
      default: ''
    },
    onMenuClick: {
      type: Function,
      default: () => {}
    },
    refreshCallback: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    getName() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user.name;
    },
  },
  created(){
    this.getCompanies();
  },
  methods: {
    filterCompanyItems() {
      if (!this.searchQuery) {
        this.filteredCompanyItems = [...this.companyItems];
      } else {
        const query = this.searchQuery.toLowerCase().trim();
        this.filteredCompanyItems = this.companyItems.filter(item =>
            item.name.toLowerCase().includes(query)
        );
      }
    },
    changeCompany(item){
      this.companyLoading = true;
      this.companyDropdown = false;

      companyService.changeCompany(item.companyId).then(response => {
        this.refreshCallback();
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.companyLoading = false;
      });
      window.location.reload();
    },
    toggleCompanyDropdown() {
      this.companyDropdown = !this.companyDropdown;

      if (this.companyDropdown && this.searchEnabled) {
        // Dropdown açıldığında arama sorgusunu sıfırla ve tüm listeyi göster
        this.searchQuery = '';
        this.filteredCompanyItems = [...this.companyItems];

        // Arama kutusuna odaklan
        this.$nextTick(() => {
          if (this.$refs.searchInput) {
            this.$refs.searchInput.focus();
          }
        });
      }
    },
    hideCompanyDropdown() {
      this.companyDropdown = false;
    },
    handleGoMessages() {
      this.$router.push('/messages');
    },
    updateLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('userLanguage', lang);
      window.location.reload();
    },
    getCompanies(){
      companyService.changeCompanyList().then(response => {
        this.companyItems = response.data.data;
        this.filteredCompanyItems = [...this.companyItems];
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.companyLoading = false;
      });
    }
  }
}
</script>



<style scoped>
.header-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: white;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: #EAECF0;
  height: 72px;
}

.header-view .left{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.welcome-view{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.welcome-headertxt{
  color: #040C2D;
  font-size: 14px;
  font-weight: 500;
  font-family: 'euclid_medium', sans-serif;
}
.welcome-headerquestion{
  color: #535560;
  font-size: 12px;
  font-weight: 400;
  font-family: 'euclid_regular', sans-serif;
}

.sidebar-icon {
  display: none;
}

.header-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.lang-select {
  background-color: transparent;
  border: none;
  font-family: 'euclid_medium', sans-serif;
  font-size: 14px;
  color: #535560;
}

.lang-select:focus {
  outline: none;
}

.locale-changer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.company-dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.company-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  padding: 10px;
  border-radius: 8px;
  transition: background 0.2s;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  line-height: 12px;
}

.company-dropdown:hover {
  background-color: #F1F1F1;
}

.dropdown-content {
  position: absolute;
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  min-width: 160px;
  padding: 10px;
  border-radius: 16px;
  z-index: 1;
  top: calc(100% + 15px);
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.search-box {
  margin-bottom: 8px;
}

.search-input {
  width: 100%;
  padding: 6px 8px;
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  outline: none;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  padding: 7px 15px;
  border-radius: 12px;
  color: #000000;
}

.item:hover {
  background-color: #F5F5F5;
}

.no-results {
  display: flex;
  justify-content: center;
  padding: 7px;
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #6C737F;
}

.dropdown-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-down {
  width: 16px;
  height: 16px;
  transition: transform 0.3s;
}

.rotate {
  transform: rotate(180deg);
}

@media (max-width: 672px) {
  .sidebar-icon {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
</style>
