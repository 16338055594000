import { io } from 'socket.io-client';
import { reactive } from 'vue';

class ChatSocketClient {
    constructor() {
        this.state = reactive({
            socket: null,
            messages: [],
            currentRoom: null,
            error: null
        });
    }

    connect() {
        if (!this.state.socket) {
            const user = JSON.parse(localStorage.getItem('user'));

            this.state.socket = io('https://chat.ibilityapp.com', {
                withCredentials: true,
                transports: ['websocket'],
                query: {
                    userId: user.userId,
                }
            });

            this.state.socket.on('connect', () => {
                console.log('Connected to socket server');
            });

            this.state.socket.on('disconnect', () => {
                this.state.currentRoom = null;
            });

            this.state.socket.on('error', (err) => {
                console.error('Socket error:', err);
                this.state.error = err;
            });

            this.state.socket.on('new_message', (message) => {
                this.state.messages.push(message);
            });

            this.state.socket.on('receive_group_message', (message) => {
                this.state.messages.push(message);
            });

        }
    }

    joinRoom(chatId) {
        if (this.state.socket) {
            this.state.socket.emit('join_room', {chatId});
            this.state.currentRoom = chatId;
        } else {
            console.error('Socket not connected. Cannot join room.');
        }
    }

    leaveRoom() {
        if (this.state.socket && this.state.currentRoom) {
            this.state.socket.emit('leave_room', this.state.currentRoom);
            this.state.currentRoom = null;
            this.state.messages = [];
        }
    }

    disconnect() {
        if (this.state.socket) {
            this.leaveRoom();
            this.state.socket.disconnect();
            this.state.socket = null;
            this.state.messages = [];
        }
    }
}

export default new ChatSocketClient();
