import axios from './providers/axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const gameLabService = {
    getGameLabs(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/gamelabs/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/gamelabs/store?limit=20&page=${page}`);
        }
    },
    getGroups(page) {
        return axios.get(`/v1/admin/gamelabs/group/store?page=${page}&limit=20`);
    },
    getGroupList() {
        return axios.get(`/v1/admin/gamelabs/group/store?list=1`);
    },
    addGroup(data) {
        return axios.post(`/v1/admin/gamelabs/group/add`, data);
    },
    updateGroup(data) {
        return axios.put(`/v1/admin/gamelabs/group/update`, data);
    },
    deleteGroup(id) {
        return axios.delete(`/v1/admin/gamelabs/group/delete/${id}`);
    },
    getGameLabDetails(id, lang = 'tr') {
        return axios.get(`/v1/admin/gamelabs/getFromId/${id}`, {
            headers: {
                "Accept-Language": lang,
            }
        });
    },
    addGameLab(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post(`/v1/admin/gamelabs/add`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    updateGameLab(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post(`/v1/admin/gamelabs/update`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    assignGameLabs(data) {
        return axios.post('/v1/admin/gamelabs/assign', data);
    },

    getCopy(gameLabId) {
        return axios.post('/v1/admin/gamelabs/copy', {
            gameLabsId: gameLabId
        });
    },

    deleteGamelabs(id) {
        return axios.delete(`/v1/admin/gamelabs/delete/${id}`);
    },
    getLogs(lang){
        return axios.get(`/v1/admin/gamelabs/getLogs?/list=1`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    },
    getDetailLogs(lang,id){
        return axios.get(`/v1/admin/gamelabs/getLogById/${id}`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    }


}

export default gameLabService;
