<template>
  <Layout>
    <h3 class="page-title">{{ $t('user_flow') }}</h3>
    <div class="filter-wrapper">
      <TherapyFilters />

      <div class="justify-end-div">
        <ExpandableSearch :refresh-callback="() => handlePage(1)"/>

        <router-link to="/userflow/new">
          <div class="justify-end-add-btn no-text-select">
            <img src="../assets/images/plus.svg" />
            <span>{{$t('new_rule')}}</span>
          </div>
        </router-link>
      </div>
    </div>

    <div class="flows-grid">
      <FlowCard
          v-for="item in ruleSets.data"
          :is-publish="item.isPublish"
          :name="item.name"
          :to="'/userflow/' + item.ruleSetId"
          :date="item.createdAt"
      />

      <free-style-shimmer
          :is-loading="isLoading"
          height="150px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />
      <free-style-shimmer
          :is-loading="isLoading"
          height="150px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />
      <free-style-shimmer
          :is-loading="isLoading"
          height="150px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />
    </div>

    <div class="pagination-align-center">
      <Pagination :current-page="currentPage" :total-items="ruleSets.total" :per-page="20" :on-page-change="handlePage"/>
    </div>

  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import TherapyFilters from "@/components/tables/Therapy/TherapyFilters.vue";
import FlowCard from "@/components/FlowCard.vue";
import {handleErrorResponse} from "@/utils/utils";
import userflowService from "@/service/userflowService";
import Pagination from "@/components/Pagination.vue";

export default {
  name: 'Userflow',
  components: {Pagination, FlowCard, TherapyFilters, ExpandableSearch, Layout},
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      ruleSets: {},
    }
  },
  watch: {
    $route() {
      this.handlePage(1);
    }
  },
  methods: {
    handlePage(page) {
      if (this.isLoading) return;

      this.isLoading = true;
      this.currentPage = page;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }

      if (routeQuery.addedDate) {
        tempFilters.push({
          key: 'addedDate',
          value: routeQuery.addedDate
        });
      }

      if (routeQuery.addedDateEnd) {
        tempFilters.push({
          key: 'addedDateEnd',
          value: routeQuery.addedDateEnd
        });
      }

      if (routeQuery.isPublish) {
        if (routeQuery.isPublish === "1") {
          tempFilters.push({
            key: 'isPublish',
            value: "1"

          })
        } else if (routeQuery.isPublish === "0") {
          tempFilters.push({
            key: 'isPublish',
            value: "0"
          })
        }
      }

      userflowService.getRules(page, tempFilters).then((response) => {
        this.ruleSets = response.data.data;
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },

  }
}
</script>

<style scoped>
.page-title {
  font-family: euclid_medium, sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
}

.filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.flows-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 1024px) {
  .flows-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .flows-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
