<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-body">
          <div class="modal-left">
            <h4>{{$t('select_the_assign_dates')}}</h4>

            <VueDatePicker
                v-model="specificDates"
                inline
                auto-apply
                :enable-time-picker="false"
                :multi-dates="true"
                :locale="currentLocale"
            />

          </div>

          <div class="separator" />

          <div class="modal-right">
            <div class="modal-right-body">
              <h4>{{$t('what_are_you_available')}}</h4>

              <div class="time-wrapper" v-for="(slot, index) in slotTimes">
                <SelectBox
                    size="small"
                    :options="timeStartOptions"
                    :placeholder="$t('start_time')"
                    :selected-value="slot.startTime"
                    :on-select="value => {
                     slot.startTime = value;
                     slot.endTime = '';
                  }"
                />
                <div class="row-separator" />
                <SelectBox
                    size="small"
                    :align-right="true"
                    :options="getTimeEndOptions(slot.startTime)"
                    :placeholder="$t('end_time')"
                    :selected-value="slot.endTime"
                    :on-select="value => slot.endTime = value"
                />

                <img class="delete-icon" src="@/assets/images/trash.svg" @click="handleSlotDelete(index)"/>
              </div>

              <button class="add-new-slot" @click="addNewSlot()">
                <img src="@/assets/images/add.svg" />
              </button>
            </div>

            <div class="modal-right-footer">
              <button class="dark-button-outline md" @click="handleHide">
                <span>{{$t('cancel_button')}}</span>
              </button>

              <button class="primary-button md" @click="handleSave">
                <span>{{$t('save')}}</span>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SelectBox from "@/components/SelectBox.vue";
import VueDatePicker from '@vuepic/vue-datepicker';

export default {
  components: {
    SelectBox,
    VueDatePicker
  },
  props: {
    expertId: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    onSave: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    currentLocale() {
      // i18n'in mevcut dil ayarını alın
      // $i18n.locale veya store'dan gelen bir değer olabilir
      // Varsayılan olarak mevcut dilinizi kullanın
      return this.$i18n ? this.$i18n.locale : 'tr';
    }
  },
  data() {
    return {
      timeStartOptions: [
        {text: "09:00", value: "09:00"},
        {text: "09:15", value: "09:15"},
        {text: "09:30", value: "09:30"},
        {text: "09:45", value: "09:45"},
        {text: "10:00", value: "10:00"},
        {text: "10:15", value: "10:15"},
        {text: "10:30", value: "10:30"},
        {text: "10:45", value: "10:45"},
        {text: "11:00", value: "11:00"},
        {text: "11:15", value: "11:15"},
        {text: "11:30", value: "11:30"},
        {text: "11:45", value: "11:45"},
        {text: "12:00", value: "12:00"},
        {text: "12:15", value: "12:15"},
        {text: "12:30", value: "12:30"},
        {text: "12:45", value: "12:45"},
        {text: "13:00", value: "13:00"},
        {text: "13:15", value: "13:15"},
        {text: "13:30", value: "13:30"},
        {text: "13:45", value: "13:45"},
        {text: "14:00", value: "14:00"},
        {text: "14:15", value: "14:15"},
        {text: "14:30", value: "14:30"},
        {text: "14:45", value: "14:45"},
        {text: "15:00", value: "15:00"},
        {text: "15:15", value: "15:15"},
        {text: "15:30", value: "15:30"},
        {text: "15:45", value: "15:45"},
        {text: "16:00", value: "16:00"},
        {text: "16:15", value: "16:15"},
        {text: "16:30", value: "16:30"},
        {text: "16:45", value: "16:45"},
        {text: "17:00", value: "17:00"},
        {text: "17:15", value: "17:15"},
        {text: "17:30", value: "17:30"},
        {text: "17:45", value: "17:45"},
        {text: "18:00", value: "18:00"},
        {text: "18:15", value: "18:15"},
        {text: "18:30", value: "18:30"},
        {text: "18:45", value: "18:45"},
        {text: "19:00", value: "19:00"},
        {text: "19:15", value: "19:15"},
        {text: "19:30", value: "19:30"},
        {text: "19:45", value: "19:45"},
        {text: "20:00", value: "20:00"},
        {text: "20:15", value: "20:15"},
        {text: "20:30", value: "20:30"},
        {text: "20:45", value: "20:45"},
        {text: "21:00", value: "21:00"},
        {text: "21:15", value: "21:15"},
        {text: "21:30", value: "21:30"},
        {text: "21:45", value: "21:45"},
        {text: "22:00", value: "22:00"},
      ],
      slotTimes: [],
      specificDates: []
    }
  },
  methods: {
    getTimeEndOptions(startTime) {
      if (!startTime) {
        return [];
      }

      // Parse the start time
      const [hours, minutes] = startTime.split(':').map(Number);
      const baseDate = new Date();
      baseDate.setHours(hours, minutes, 0, 0);

      const options = [];
      const intervals = [15, 30, 45, 60]; // minutes to add

      intervals.forEach(interval => {
        const newDate = new Date(baseDate.getTime() + interval * 60000);
        const newHours = newDate.getHours().toString().padStart(2, '0');
        const newMinutes = newDate.getMinutes().toString().padStart(2, '0');
        const timeString = `${newHours}:${newMinutes}`;

        // Only add the option if it's not past 22:00
        if (newHours < 23 || (newHours === 23 && newMinutes === '00')) {
          options.push({
            text: timeString,
            value: timeString
          });
        }
      });

      return options;
    },
    addNewSlot() {
      this.slotTimes.push({
        startTime: '',
        endTime: ''
      });
    },
    handleSlotDelete(index) {
      this.slotTimes.splice(index, 1);
    },
    handleHide() {
      this.slotTimes = [];
      this.specificDates = [];
      this.onHide();
    },
    handleSave() {
      if (this.specificDates.length === 0) {
        this.$snackbar.add({
          text: this.$t('please_select_least_one_date'),
          type: 'error'
        });
        return;
      }

      if (this.slotTimes.length === 0) {
        this.$snackbar.add({
          text: this.$t('please_add_one_slot'),
          type: 'error'
        })
        return;
      }
      this.onSave({
        dates: this.specificDates.map(date => {
          return {
            date: date,
            isChecked: true
          }
        }),
        slotTimes: this.slotTimes
      });
      this.specificDates = [];
      this.slotTimes = [];
      this.onHide();
    },
  },
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  border-radius: 12px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-body{
  display: flex;
  flex-direction: row;
  padding: 0;
}

.modal-left, .modal-right{
  width: 50%;
}

.modal-left {
  padding: 24px;
}

.modal-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.modal-right-body{
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px;
  height: 100%;
  max-height: 500px;
  overflow-y: scroll;
}

.modal-right-footer{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  border-top: 1px solid #EAECF0;
  padding: 12px;
}

.separator{
  width: 1px;
  background-color: #EAECF0;
}

h4 {
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 14px;
  margin-bottom: 15px;
}


.add-new-slot img {
  width: 16px;
  height: 16px;
}

.add-new-slot{
  display: flex;
  flex-direction: row;
  gap: 5px;
  height: 26px;
  width: 26px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 5px;
  border-radius: 50%;
  background-color: #F2F4F7;
  outline: none;
}

.add-new-slot:hover{
  background-color: #EAECF0;
}

.time-wrapper{
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.row-separator{
  height: 2px;
  background-color: #D0D5DD;
  width: 8px;
  align-self: center;
}

.delete-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.modal-footer{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  border-top: 1px solid #EAECF0;
}
</style>
