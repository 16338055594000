<template>
  <Layout>
    <h3 class="page-title">{{$t('sliders')}}</h3>
    <div class="filter-wrapper">
      <div class="filter-row" />

      <div class="justify-end-div">
        <ExpandableSearch/>
        <div class="log-icon-wrapper">
          <img src="@/assets/images/logIconNew.svg" @click="showLogModal" class="log-icon" />
          <span class="tooltip">{{ $t('log_records') }}</span>
        </div>        <LogModal
          :visible="isLogModalVisible"
          :on-hide="hideLogModal"
          :data="logData"
      />
        <router-link to="/slider/new">
          <div class="justify-end-add-btn no-text-select">
            <img src="../assets/images/plus.svg" />
            <span>{{$t('add_new')}}</span>
          </div>
        </router-link>
      </div>
    </div>


    <div class="slider-list-grid">
      <SliderCard
          v-for="item in sliderData.data"
          :image="item.imageUrl"
          :title="item.name"
          :to="`/slider/${item.sliderId}`"

      />

      <SliderCard v-if="isLoading" :is-loading="true" />
      <SliderCard v-if="isLoading" :is-loading="true" />
      <SliderCard v-if="isLoading" :is-loading="true" />
    </div>

    <div class="pagination-align-center">
      <Pagination :current-page="1" :total-items="sliderData.total" :per-page="20" :on-page-change="handlePage"/>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layouts/Layout.vue';
import sliderService from "@/service/sliderService";
import {handleErrorResponse} from "@/utils/utils";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import SliderCard from "@/components/SliderCard.vue";
import Pagination from "@/components/Pagination.vue";
import LogModal from "@/components/modals/LogModal.vue";

export default {
  components: {
    LogModal,
    Pagination,
    SliderCard,
    ExpandableSearch,
    Layout
  },
  data() {
    return {
      logData:[],
      isLogModalVisible:false,
      sliderData: [],
      isLoading: true
    };
  },
  mounted() {
    this.getSliderPage(1);
    this.getLogs(this.$i18n.locale)

  },
  watch: {
    $route() {
      this.handleSearch(1);
    }
  },
  methods: {
    getLogs(lang){
      sliderService.getLogs(lang).then((res) => {
        this.logData=res.data.data.data;
      })
    },
    showLogModal(){
      this.isLogModalVisible=true;
    },
    hideLogModal(){
      this.isLogModalVisible=false;
    },
    getSliderPage(page) {
      this.isLoading = true;
      sliderService.getSliders(page).then((response) => {
        this.sliderData = response.data.data;
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    handlePage(page) {
      this.getSliderPage(page);
    },
    handleSearch(page) {
      if (this.isLoading && this.currentPage !== 1) return;

      this.isLoading = true;
      this.currentPage = page;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }

      sliderService.getSliders(page, tempFilters).then((res) => {
        this.sliderData = res.data.data;
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    }
  },
}
</script>

<style>
.page-title {
  font-family: euclid_medium, sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
}
.filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.filter-dropdown {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}
.dropdown-text {
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.slider-list-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.log-icon-wrapper {
  position: relative;
  cursor: pointer;
}

.log-icon-wrapper .tooltip {
  visibility: hidden;
  background-color: #040C2D;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 10px;
  font-family: euclid_semi_bold;

  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);

  opacity: 0;
  transition: opacity 0.3s;
}

.log-icon-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.log-icon-wrapper .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #040C2D transparent transparent transparent;
}

@media (max-width: 1024px) {
  .slider-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .slider-list-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
