<template>
  <Layout>
      <h3 class="page-title">{{$t('plans_and_billing')}}</h3>

      <div v-if="activeTab === 'plan-management'" class="content">
        <section class="payment-method">
          <h4>{{$t('payment_method')}}</h4>
          <p>Plans and payment</p>
          <div class="payment-card">
         <!--   <div class="card-info">
              <div class="option-div">
                <div class="option-two-div">
                  <img src="../assets/images/visa.svg" alt="">
                  <span>**** **** **** 1234 <br> <span style="color:#98a2b2; ">{{$t('expires_on')}} 09/26</span></span>
                </div>
                <div class="other-div">
                  <span>...</span>
                </div>
              </div>
            </div>
            -->
            <button class="add-payment" @click="isPaymentModalOpen = true">          <span>+</span>
              <p>{{$t('add_payment_method')}}</p>
            </button>
          </div>
        </section>

        <PaymentModal :visible="isPaymentModalOpen" :on-hide="hideModal" @close="isPaymentModalOpen = false" />

        <section class="your-plan">
          <h4>{{$t('your_plan')}}</h4>
          <span>{{$t('member_since')}} 2023</span>
          <div class="plans">
            <div class="plan free">
              <div style="display: flex;justify-content: center; padding-top:10px">
                <h6 style="font-weight: bold">{{$t('starter')}}</h6>
              </div>
              <br>
              <br>
              <div class="text-left">
                <div class="column-gap-4">
                  <span style="color:#040c2d; font-weight: 600; font-size: 16px">{{$t('mobile')}}</span>
                  <p style="color: #98a2b2;"> $5 / {{$t('users')}} (1-10 {{$t('users_pricing')}} )</p>
                  <p style="color: #98a2b2;"> $4 / {{$t('users')}} (11-50 {{$t('users_pricing')}})</p>
                  <div class="column-gap-4">
                    <span style="color:#040c2d; font-weight: 600; font-size: 16px">{{$t('admin_seat')}}</span>
                    <p style="color: #98a2b2;"> $25 / admin (1-10 {{$t('users_pricing')}} )</p>
                  </div>
                  <br>
                </div>

              </div>
              <div class="text-left">
                <ul>
                  <li style="color: #98a2b2;"><img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                    {{$t('up_to_users')}}/ 3 {{$t('admin')}}
                  </li>
                  <li style="color: #98a2b2;"><img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                    {{$t('unlimited_content_creation')}}
                  </li>
                  <li style="color: #98a2b2;"><img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                    {{$t('standard_support')}}
                  </li>
                  <li style="color: #98a2b2;"><img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                    {{$t('basic_reporting')}}
                  </li>
                </ul>
              </div>

            </div>
            <div class="plan basic active">
              <div style="display: flex;justify-content: center;">
                <h6 style="font-weight: bold; padding-top: 35px">{{$t('growth')}}</h6>
              </div>
              <br>
              <div class="text-left">

                <div class="column-gap-2">
                  <span style="color:#040c2d; font-weight: 600; font-size: 16px">{{$t('mobile')}}</span>
                  <p style="color: #98a2b2;"> $2.5 / {{$t('users')}}</p>
                  <div class="column-gap-2">
                    <span style="color:#040c2d; font-weight: 600; font-size: 16px">{{$t('admin_seat')}}</span>
                    <p style="color: #98a2b2;"> $20 / {{$t('admin')}} (11-20 {{$t('users_pricing')}} )</p>
                  </div>
                  <br>
                </div>

              </div>
              <ul>
                <li style="color: #98a2b2;"><img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('up_to')}} 100 {{$t('users_pricing')}}/ 20 {{$t('admin')}}
                </li>
                <li style="color: #98a2b2;"><img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('some_ai_features')}}
                </li>
                <li style="color: #98a2b2;"><img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('custom_views_dashboards')}}
                </li>
                <li style="color: #98a2b2;"><img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('advanced_reporting')}}
                </li>
                <li style="color: #98a2b2;"><img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('api_access')}}
                </li>
                <li style="color: #98a2b2;"><img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('integrations')}}
                </li>

              </ul>
            </div>
            <div class="plan pro">
              <div style="display: flex;justify-content: center; padding-top:10px">
                <h6 style="font-weight: bold;">{{$t('enterprise')}}</h6>
              </div>
              <br>
              <br>
              <div class="column-gap-4" style="color: #98a2b2;">
                <p>{{$t('designed_for')}}.</p>
              </div>

              <div class="column-gap-4">
                <span style="color:#040c2d; font-weight: 600; font-size: 16px">{{$t('book_a_demo')}}</span>
              </div>

              <ul>
                <li style="color: #98a2b2;">
                  <img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('for')}} 100+ {{$t('users_pricing')}}
                </li>
                <li style="color: #98a2b2;">
                  <img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('full_ai_Access')}}
                </li>
                <li style="color: #98a2b2;">
                  <img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('full_api_access')}}
                </li>
                <li style="color: #98a2b2;">
                  <img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('custom_reports')}}
                </li>
                <li style="color: #98a2b2;">
                  <img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('dedicated_support')}}
                </li>
                <li style="color: #98a2b2;">
                  <img class="icons-check" src="../assets/images/check-circle.svg" alt="">
                  {{$t('customizable_branding')}}
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section class="contact-info">
          <h4>{{$t('contact_information')}}</h4>
          <span>{{$t('card_billing_information')}}</span>
          <br>
          <br>
          <p><span>{{$t('email')}}:</span> info@ibilityapp.com</p>
          <p><span>{{$t('address')}}:</span> Dumankaya Ritim İstanbul, Maltepe/İSTANBUL</p>
          <p><span>{{$t('telephone')}}:</span> +90 212 355 89 50</p>
        </section>
      </div>

  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import TherapyFilters from "@/components/tables/Therapy/TherapyFilters.vue";
import FlowCard from "@/components/FlowCard.vue";
import PaymentModal from "../components/modals/PaymentModal.vue";

export default {
  name: 'Pricing',
  components: {PaymentModal, FlowCard, TherapyFilters, ExpandableSearch, Layout},

  props:{
    visible: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      activeTab: "plan-management",
      isPaymentModalOpen: false, // Modal başlangıçta kapalı
    };

  },

  methods:{
    hideModal(){
      this.isPaymentModalOpen=false;
    }
  }

}
</script>

<style scoped>
.plans-and-billing {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  background-color: #f8f9fa;

}

.title{
  font-size: 20px;
  color: #040c2d;
  font-weight: bold;
}

.tabs div {
  padding: 5px 0px;
  border-radius: 8px;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.tabs span {
  color: black;
}

.content {
  overflow-y: auto;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.payment-method, .your-plan, .contact-info {
  margin-bottom: 30px;
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #EAECF0;
}

.your-plan span{
  color: #98a2b2;
}

.contact-info span{
  color: gray;
  font-size: 13px;
}

.payment-method p{
  color: #98a2b2;
}

.payment-card {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  justify-content: center;
  flex-direction: row;
  gap: 50px;
  min-height: 180px; /* Minimum yükseklik tanımı */
  min-width: 400px;
}

.option-div {
  display: flex;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #EAECF0;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  width: 400px;
  flex-direction: row;
  gap: 80px;
  flex-wrap: wrap;
}

.option-two-div{
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.option-two-div span{
  color: #98a2b2;
}

.option-two-div img{
  border: 1px solid #EAECF0;
  border-radius: 4px;
}

.option-div span {
  color: #040c2d;
  font-size: 14px;
  font-weight: bold;
}

.option-div img{
  height: 40px;
  width: 65px;
}

.other-div{
  display: flex;
  height: 30px;
  width: 35px;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 4px;
}

.add-payment {
  display: flex;
  background-color: transparent;
  color: #040c2d;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 400px;
  border: 1px solid #EAECF0;
  flex-direction: row;
  gap: 20px;
}

.add-payment span{
  display: flex;
  height: 30px;
  width: 35px;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 4px;
}

.add-payment p{
  font-weight: 600;
  color: #040c2d;
}

.plans {
  display: flex;
  gap: 20px;
  height: 400px;
  justify-content:flex-start;
  align-items: center;
}

.plan {
  display: flex;
  flex: 1;
  min-width: 280px;
  height: 380px;
  padding: 20px;
  border-radius: 10px;
  transition: transform 0.3s ease;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  border: 1px solid #EAECF0;
}

.plan:hover {
  transform: translateY(-5px);
}

.plan h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.plan ul {
  list-style: none;
  padding: 0;
  margin-bottom: 15px;
}

.plan ul li {
  margin-bottom: 5px;
  font-size: 0.9rem;
}

.basic.active {
}

.icons-check{
  height: 12px;
  width: 12px;
}

.text-left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: auto;
}

.your-plan h4{
  font-weight: 600;
  font-size: 18px;
}

.contact-info h4{
  font-weight: 600;
  font-size: 17px;
}

.contact-info p{
  font-weight: 600;
  font-size: 13px;
}

.payment-method h4{
  font-weight: 600;
  font-size: 20px;
}

@media screen and (max-width: 1024px) {
  .plans {
    flex-direction: column;
    height: auto;
    gap: 30px;
    padding: 20px 0;
  }

  .plan {
    width: 100%;
    min-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .payment-card {
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    display: grid;
  }

  .option-div,
  .add-payment {
    width: 100%;
    max-width: 400px;
  }

  .content {
    padding: 15px;
  }

  .payment-method,
  .your-plan,
  .contact-info {
    padding: 15px;
  }

  .add-payment {
    padding: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
  }

}

@media screen and (max-width: 768px) {
  .plans-and-billing {
    padding: 10px;
  }

  .title {
    font-size: 18px;
  }

  .option-div {
    height: auto;
    padding: 15px;
    gap: 20px;
    justify-content: center;
    align-items: center;
    max-width: fit-content;
  }

  .option-two-div {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .payment-card {
    padding: 10px;
  }

  .add-payment {
    height: auto;
    padding: 15px;
    display: grid;
    justify-content: center;
    align-items: center;
  }

  .plan {
    padding: 15px;
  }

  .plan ul li {
    font-size: 0.85rem;
  }

  .your-plan h4 {
    font-size: 16px;
  }

  .contact-info h4 {
    font-size: 15px;
  }

  .contact-info p {
    font-size: 12px;
  }

  .payment-method h4 {
    font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  .plans-and-billing {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto; /* Ortalanma */
    padding: 10px;
    width: 100%;
    overflow-x: hidden; /* Yatay taşmayı önler */
  }

  .content {
    padding: 10px;
    overflow-x: hidden; /* Yatay taşmayı engelle */
    width: 100%; /* Ekrana tam uyum */
    box-sizing: border-box;
  }

  /* Payment Card düzeltmeleri */
  .payment-card {
    flex-direction: column; /* Dikey dizilim */
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 15px;
    width: 100%; /* Genişliği ekrana göre ayarla */
    box-sizing: border-box;
  }

  /* Option Div düzeltmeleri */
  .option-div {
    display: flex;
    flex-direction: column; /* İçeriği dikey hizalar */
    justify-content: center;
    align-items: center;
    text-align: center; /* Ortalanmış metin */
    gap: 10px; /* Daha küçük boşluk */
    padding: 10px;
    width: 100%; /* Taşmayı engelle */
    box-sizing: border-box; /* Uyumlu kutu modeli */
    overflow-wrap: break-word; /* Metin taşmalarını kırar */
  }

  .option-two-div {
    flex-direction: column; /* Görseller ve metni alt alta sıralar */
    align-items: center;
    text-align: center;
    gap: 5px;
  }

  .other-div {
    margin: 10px auto; /* İçeriğin merkezde kalması */
  }

  /* Add Payment düzeltilmesi */
  .add-payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%; /* Yatay taşmaları engelle */
    padding: 15px;
    gap: 10px;
    box-sizing: border-box;
  }

  /* Plans (modüller) düzenlemesi */
  .plans {
    flex-direction: column;
    gap: 20px;
    padding: 10px 0;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
  }

  .plan {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 100%; /* Tam genişlikte uyum */
    height: auto;
    margin-bottom: 15px;
    padding: 15px;
  }

  .plan ul li {
    list-style: none;
    font-size: 0.85em; /* Metni küçült */
    text-align: center;
  }
}
</style>
