<template>
  <Layout>
    <div class="main-wrapper">
      <div class="header">
        <Switch v-model="ruleData.isPublish" />

        <input class="title-input" placeholder="New Rule" v-model="ruleData.name" />

        <button :disabled="saveLoading" class="justify-end-add-btn no-text-select" @click="handleSave">
          <VueSpinnerIos v-if="saveLoading" size="14" color="#FFFFFF" />
          <span v-else>Save Rule</span>
        </button>
      </div>
      <div class="user-flow-wrapper">
        <div class="left">
          <div class="flow-list">
            <FlowComponent
                :data="ruleData.trigger"
                :selected="selectedComponentId === 1"
                :on-click="() => handleComponentClick(1)"
                :on-delete="() => handleComponentDelete(1)"
            />

            <template class="flow-item" v-for="component in ruleData.components" :key="component.id">
              <FlowComponent
                  :data="component"
                  :selected="selectedComponentId === component.id"
                  :on-click="() => handleComponentClick(component.id)"
                  :on-delete="() => handleComponentDelete(component.id)"
              />

              <BranchRenderer
                  :handle-component-click="(cid) => handleComponentClick(cid)"
                  :handle-component-delete="(cid) => handleComponentDelete(cid)"
                  :selected-component-id="selectedComponentId"
                  :branch-data="component"
                  :handle-branch-component-add="(compData, side) => handleBranchComponentAdd(compData, side)"
                  :nesting-level="1"
              />
            </template>

            <div class="flow-item">
              <AddComponentButton :disabled="addComponentDisabled" :on-click="handleAddComponent"/>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="component-list" v-if="getComponentById(selectedComponentId)?.type === 'whenStart'">
            <template v-for="(components, groupKey) in whenComponents" :key="groupKey">
              <h3 class="group-title">{{ formatGroupTitle(groupKey) }}</h3>
              <div class="list-items">
                <div
                    v-for="component in components"
                    :key="component.type"
                    class="list-item-wrapper"
                    @click="handleWhenSelect(component)"
                >
                  <div class="icon-wrapper green">
                    <img :src="component.icon" />
                  </div>
                  <span class="name">{{ component.name }}</span>
                </div>
              </div>
            </template>
          </div>


          <div class="component-list" v-else-if="getComponentById(selectedComponentId)?.type === 'default'">
            <h3 class="group-title">Actions</h3>
            <div class="list-items">
              <div class="list-item-wrapper" @click="onThenSelected()">
                <div class="icon-wrapper blue">
                  <img src="@/assets/images/lightningBlue.svg" />
                </div>
                <span class="name">THEN: Add an action</span>
              </div>

              <div class="list-item-wrapper" @click="onIfSelected()">
                <div class="icon-wrapper yellow">
                  <img src="@/assets/images/filterYellow.svg" />
                </div>
                <span class="name">IF: Add a condition</span>
              </div>
            </div>
          </div>

          <div class="component-list" v-else-if="getComponentById(selectedComponentId)?.type === 'thenStart'">
            <template v-for="(components, groupKey) in thenComponents" :key="groupKey">
              <h3 class="group-title">{{ formatGroupTitle(groupKey) }}</h3>
              <div class="list-items">
                <div
                    v-for="component in components"
                    :key="component.type"
                    class="list-item-wrapper"
                    @click="handleThenSelect(component)"
                >
                  <div class="icon-wrapper blue">
                    <img :src="component.icon" />
                  </div>
                  <span class="name">{{ component.name }}</span>
                </div>
              </div>
            </template>
          </div>

          <div class="component-list" v-else-if="getComponentById(selectedComponentId)?.trigger?.type === 'ifStart'">
            <template v-for="(components, groupKey) in ifComponents" :key="groupKey">
              <h3 class="group-title">{{ formatGroupTitle(groupKey) }} {{getComponentById(selectedComponentId)?.type}}</h3>
              <div class="list-items">
                <div v-for="component in components">
                <div
                    v-if="component.shownInMenu"
                    :key="component.type"
                    class="list-item-wrapper"
                    @click="handleIfSelect(component)"
                >
                  <div class="icon-wrapper yellow">
                    <img :src="component.icon" />
                  </div>
                  <span class="name">{{ component.name }}</span>
                </div>
                </div>
              </div>
            </template>
          </div>

          <div class="component-list" v-else-if="findWhenComponentByType(getComponentById(selectedComponentId)?.type)?.settings?.length > 0">
            <h3 class="group-title">Component Settings</h3>

            <div class="setting-list">
              <SettingsRenderer
                  :key="setting.settingsId"
                  :setting="setting"
                  :optionList="optionList"
                  :componentData="getComponentById(selectedComponentId)"
                  v-for="setting in findWhenComponentByType(getComponentById(selectedComponentId)?.type)?.settings"
              />
            </div>
          </div>

          <div class="component-list" v-else-if="findThenComponentByType(getComponentById(selectedComponentId)?.type)?.settings?.length > 0">
            <h3 class="group-title">Component Settings</h3>

            <div class="setting-list">
              <SettingsRenderer
                  :key="setting.settingsId"
                  :setting="setting"
                  :optionList="optionList"
                  :componentData="getComponentById(selectedComponentId)"
                  v-for="setting in findThenComponentByType(getComponentById(selectedComponentId)?.type)?.settings"
              />
            </div>
          </div>

          <div class="component-list" v-else-if="findIfComponentByType(getComponentById(selectedComponentId)?.trigger?.type)?.settings?.length > 0">
            <h3 class="group-title">Component Settings</h3>

            <div class="setting-list">
              <SettingsRenderer
                  :key="setting.settingsId"
                  :setting="setting"
                  :optionList="optionList"
                  :componentData="getComponentById(selectedComponentId)?.trigger"
                  v-for="setting in findIfComponentByType(getComponentById(selectedComponentId)?.trigger?.type)?.settings"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <LoadingOverlay :is-loading="isLoading" />
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import FlowComponent from "@/components/user-flow/FlowComponent.vue";
import AddComponentButton from "@/components/user-flow/AddComponentButton.vue";
import BranchRenderer from "@/components/user-flow/BranchRenderer.vue";
import SettingsRenderer from "@/components/user-flow/SettingsRenderer.vue";
import userflowService from "@/service/userflowService";
import {handleErrorResponse} from "@/utils/utils";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import Switch from "@/components/Switch.vue";
import notificationContentService from "@/service/notificationContentService";
import testService from "@/service/testService";
import roleService from "@/service/roleService";
import depService from "@/service/depService";
import {VueSpinnerIos} from "vue3-spinners";

export default {
  name: 'UserflowDetails',
  components: {
    VueSpinnerIos,
    Switch, LoadingOverlay, SettingsRenderer, BranchRenderer, AddComponentButton, FlowComponent, Layout},
  data() {
    return {
      whenComponents: {},
      thenComponents: {},
      ifComponents: {},
      isLoading: true,
      isNew: true,
      editMode: false,
      selectedComponentId: null,
      saveLoading: false,
      ruleData: {
        name: '',
        isPublish: false,
        trigger: {
          id: 1,
          componentId: -1,
          type: 'whenStart',
          data: {},
        },
        components: [
        ],
      },
      optionList: {
        notificationOptions: [],
        testOptions: [],
        programOptions: [],
        gameLabsOptions: [],
        roleOptions: [],
        departmentOptions: [],
      }
    }
  },
  async mounted() {
    const routeParams = this.$route.params;

    await this.handleGetComponents();
    this.fetchOptions();

    if (routeParams.id !== "new") {
      this.isNew = false;
      this.handleGetDetail();
    } else {
      this.isNew = true;
      this.selectedComponentId = 1;
      this.isLoading = false;
    }
  },
  methods: {
    findWhenComponentByType(type) {
      for (const category of Object.values(this.whenComponents)) {
        const found = category.find(component => component.type === type);
        if (found) return found;
      }
      return null;
    },
    findThenComponentByType(type) {
      for (const category of Object.values(this.thenComponents)) {
        const found = category.find(component => component.type === type);
        if (found) return found;
      }
      return null;
    },
    findIfComponentByType(type) {
      for (const category of Object.values(this.ifComponents)) {
        const found = category.find(component => component.type === type);
        if (found) return found;
      }
      return null;
    },
    async handleGetComponents() {
      await userflowService.getRuleComponents().then(response => {
        this.whenComponents = response.data.data.WHEN_COMPONENTS;
        this.thenComponents = response.data.data.THEN_COMPONENTS;
        this.ifComponents = response.data.data.IF_COMPONENTS;
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      });
    },
    handleGetDetail() {
      const routeParams = this.$route.params;
      this.isLoading = true;
      this.editMode = true;

      userflowService.getRuleById(routeParams.id)
          .then(response => {
            let idCounter = 1;

            const assignUniqueIds = (component) => {
              component.id = idCounter++;

              if (component.trigger) {
                component.trigger.id = idCounter++;

                if (component.trigger.components) {
                  component.trigger.components.forEach(nestedComponent => {
                    assignUniqueIds(nestedComponent);
                  });
                }
              }

              if (component.ifComponents && component.ifComponents.length > 0) {
                component.ifComponents.forEach(ifComponent => {
                  assignUniqueIds(ifComponent);
                });
              }

              if (component.elseComponents && component.elseComponents.length > 0) {
                component.elseComponents.forEach(elseComponent => {
                  assignUniqueIds(elseComponent);
                });
              }

              return component;
            };

            const processedTrigger = response.data.data.trigger ?
                { ...response.data.data.trigger, id: idCounter++ } : null;

            const processedComponents = response.data.data.components?.map(component => {
              return assignUniqueIds({ ...component });
            });

            this.ruleData = {
              isPublish: response.data.data.isPublish,
              name: response.data.data.name,
              trigger: processedTrigger,
              components: processedComponents
            };

            this.isLoading = false;
          })
          .catch(error => {
            handleErrorResponse(error, this.$snackbar);
          });
    },
    handleComponentClick(id) {
      this.selectedComponentId = id;
    },
    formatGroupTitle(groupKey) {
      return groupKey
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, str => str.toUpperCase())
          .trim()
    },
    handleWhenSelect(component) {
      this.ruleData.trigger = {
        ...this.ruleData.trigger,
        type: component.type,
        componentId: this.findWhenComponentByType(component.type).componentId,
      };
    },
    handleThenSelect(component) {
      const selectedComponent = this.getComponentById(this.selectedComponentId);
      if (selectedComponent) {
        selectedComponent.type = component.type;
        selectedComponent.componentId = this.findThenComponentByType(component.type).componentId;
      }
    },
    handleIfSelect(component) {
      const selectedComponent = this.getComponentById(this.selectedComponentId);

      if (selectedComponent) {
        console.log('settingTrigger', this.ruleData);
        selectedComponent.trigger = {
          ...selectedComponent.trigger,
          type: component.type,
          componentId: this.findIfComponentByType(component.type).componentId || -1,
        }
      }
    },
    onThenSelected() {
      const selectedComponent = this.getComponentById(this.selectedComponentId);
      if (selectedComponent) {
        selectedComponent.type = 'thenStart';
      }
    },
    onIfSelected() {
      const selectedComponent = this.getComponentById(this.selectedComponentId);
      if (selectedComponent) {
        selectedComponent.type = 'ifCondition';
        selectedComponent.trigger = {
          type: 'ifStart',
          id: this.findLastComponentId() + 1,
          componentId: -1,
        };
      }
    },
    handleAddComponent() {
      const lastComponentId = this.findLastComponentId();
      this.ruleData.components.push({
        id: lastComponentId + 1,
        componentId: -1,
        type: 'default',
      });
    },
    handleBranchComponentAdd(component, side = 'if') {
      const lastComponentId = this.findLastComponentId();

      if (component) {
        if (side === 'if') {
          if (!component.ifComponents) {
            component.ifComponents = [];
          }

          component.ifComponents.push({
            id: lastComponentId + 1,
            componentId: -1,
            type: 'default',
            side: side,
          });
        } else {
          if (!component.elseComponents) {
            component.elseComponents = [];
          }

          component.elseComponents.push({
            id: lastComponentId + 1,
            componentId: -1,
            type: 'default',
            side: side,
          });
        }
      }
    },
    handleComponentDelete(id) {
      if (id === 1) {
        this.ruleData.trigger = {
          componentId: -1,
          id: 1,
          type: 'whenStart',
          data: {},
        };
      } else {
        this.deleteComponentById(id);
      }
    },
    getComponentById(id) {
      const components = this.ruleData.components;
      let foundComponent = null;

      if (this.ruleData.trigger && this.ruleData.trigger.id === id) {
        return this.ruleData.trigger;
      }

      const traverseComponents = (component) => {
        if (component.id === id) {
          foundComponent = component;
          return;
        }

        if (component.trigger && component.trigger.id === id) {
          foundComponent = component;
          return;
        }

        if (component.ifComponents && component.ifComponents.length > 0) {
          component.ifComponents.forEach(child => {
            if (!foundComponent) traverseComponents(child);
          });
        }

        if (component.elseComponents && component.elseComponents.length > 0) {
          component.elseComponents.forEach(child => {
            if (!foundComponent) traverseComponents(child);
          });
        }
      };

      if (Array.isArray(components)) {
        components.forEach(component => {
          if (!foundComponent) traverseComponents(component);
        });
      } else if (components) {
        traverseComponents(components);
      }

      return foundComponent;
    },
    validateComponentTypes() {
      const components = this.ruleData.components;
      const invalidTypes = ['default', 'whenStart', 'thenStart', 'ifStart'];
      const foundInvalidTypes = [];

      const traverseComponents = (component) => {
        if (invalidTypes.includes(component.type)) {
          foundInvalidTypes.push({
            id: component.id,
            type: component.type
          });
        }

        if (component.trigger && invalidTypes.includes(component.trigger.type)) {
          foundInvalidTypes.push({
            id: component.trigger.id || 'trigger-of-' + component.id,
            type: component.trigger.type
          });
        }

        if (component.ifComponents && component.ifComponents.length > 0) {
          component.ifComponents.forEach(child => {
            traverseComponents(child);
          });
        }

        if (component.elseComponents && component.elseComponents.length > 0) {
          component.elseComponents.forEach(child => {
            traverseComponents(child);
          });
        }
      };

      if (Array.isArray(components)) {
        components.forEach(component => {
          traverseComponents(component);
        });
      } else if (components) {
        traverseComponents(components);
      }

      if (this.ruleData.trigger && invalidTypes.includes(this.ruleData.trigger.type)) {
        foundInvalidTypes.push({
          id: this.ruleData.trigger.componentId || 'top-level-trigger',
          type: this.ruleData.trigger.type
        });
      }

      return {
        isValid: foundInvalidTypes.length === 0,
        invalidComponents: foundInvalidTypes
      };
    },
    deleteComponentById(idToDelete) {
      const deleteRecursive = (components) => {
        if (Array.isArray(components)) {
          const componentIndex = components.findIndex(component => component.id === idToDelete);

          if (componentIndex !== -1) {
            components.splice(componentIndex, 1);
            if (this.selectedComponentId === idToDelete) {
              this.selectedComponentId = null;
            }
            return true;
          }

          for (const component of components) {
            if (component.branches && deleteRecursive(component.branches)) {
              return true;
            }
          }
        }
        return false;
      };

      deleteRecursive(this.ruleData.components);
    },
    handleSave() {
      if (!this.validateComponentTypes().isValid) {
        this.$snackbar.add({
          text: 'There is not finished components',
          type: 'error'
        });

        return;
      }

      if (this.isNew) {
        this.handleSaveNew();
      } else {
        this.handleSaveEdit();
      }
    },
    handleSaveNew() {
      this.saveLoading = true;
      userflowService.addRuleSet(this.ruleData)
          .then(response => {
            this.$router.push(`/userflow/${response.data.data.ruleSetId}`);
            this.isNew = false;
            this.saveLoading = false;
          })
          .catch(error => {
            handleErrorResponse(error, this.$snackbar);
            this.saveLoading = false;
          });
    },
    handleSaveEdit() {
      this.saveLoading = true;
      userflowService.updateRuleSet({
        ruleSetId: this.$route.params?.id,
        ...this.ruleData
      })
          .then(() => {
            this.saveLoading = false;
          })
          .catch(error => {
            handleErrorResponse(error, this.$snackbar);
            this.saveLoading = false;
          });
    },
    findLastComponentId() {
      const components = this.ruleData.components;
      let maxId = 1;

      const traverseComponents = (component) => {
        // Convert component.id to number for proper comparison
        const componentId = parseInt(component.id);
        if (!isNaN(componentId) && componentId > maxId) {
          maxId = componentId;
        }

        // Check trigger id if exists
        if (component.trigger && component.trigger.id) {
          const triggerId = parseInt(component.trigger.id);
          if (!isNaN(triggerId) && triggerId > maxId) {
            maxId = triggerId;
          }
        }

        // Check ifComponents if they exist
        if (component.ifComponents && component.ifComponents.length > 0) {
          component.ifComponents.forEach(child => {
            traverseComponents(child);
          });
        }

        // Check elseComponents if they exist
        if (component.elseComponents && component.elseComponents.length > 0) {
          component.elseComponents.forEach(child => {
            traverseComponents(child);
          });
        }
      };

      if (Array.isArray(components)) {
        components.forEach(component => {
          traverseComponents(component);
        });
      } else if (components) {
        traverseComponents(components);
      }

      return maxId;
    },
    fetchOptions() {
      notificationContentService.getNotificationContentList(true).then((response) => {
        const notificationOptions = response.data.data.map(notification => {
          return {
            value: notification.notificationId,
            text: notification.content?.label
          };
        });

        this.optionList = {
          ...this.optionList,
          notificationOptions: notificationOptions
        }
      }).catch(() => {
        this.$snackbar.add({
          text: this.$t('fetching_notification_list_failed'),
          type: "error",
        });
      });


      testService.getTestList().then((response) => {
        const testOptions = [];
        const programOptions = [];
        const gameLabsOptions = [];

        response.data.data.map(test => {
          if (test.type === 'test') {
            testOptions.push({
              value: test.id,
              text: test.name
            });
          } else if (test.type === 'therapy') {
            programOptions.push({
              value: test.id,
              text: test.name
            });
          } else if (test.type === 'gamelabs') {
            gameLabsOptions.push({
              value: test.id,
              text: test.name
            });
          }
        });

        this.optionList = {
          ...this.optionList,
          testOptions: testOptions,
          programOptions: programOptions,
          gameLabsOptions: gameLabsOptions
        }
      }).catch(() => {
        this.$snackbar.add({
          text: this.$t('fetching_test_list_failed'),
          type: "error",
        });
      });

      roleService.getRoles().then((response) => {
        const roleOptions = response.data.data.map(role => {
          return {
            value: role.id,
            text: role.name
          };
        });

        this.optionList = {
          ...this.optionList,
          roleOptions: roleOptions
        }
      }).catch(() => {
        this.$snackbar.add({
          text: this.$t('fetching_role_list_failed'),
          type: "error",
        });
      });

      depService.getDepList().then((response) => {
        const departmentOptions = response.data.data.map(dep => {
          return {
            value: dep.departmentId,
            text: dep.name
          };
        });

        this.optionList = {
          ...this.optionList,
          departmentOptions: departmentOptions
        }
      }).catch(() => {
        this.$snackbar.add({
          text: this.$t('fetching_department_list_failed'),
          type: "error",
        });
      });
    }
  },
  computed: {
    addComponentDisabled() {
      const whenStartComponent = this.ruleData.components.filter(component => component.type === 'whenStart');
      const defaultComponents = this.ruleData.components.filter(component => component.type === 'default');

      if (whenStartComponent?.length > 0) {
        return true;
      }

      if (defaultComponents?.length > 0) {
        return true;
      }

      return false;
    },
  }
}
</script>

<style scoped>
.main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}

.header {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.user-flow-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(100% - 94px);
  gap: 10px;
}

.flow-list {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.flow-item {
  position: relative;
  width: 100%;
}

.user-flow-wrapper .left {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 20px;
}

.user-flow-wrapper .right {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #FCFCFD;
  border: 1px solid #EAECF0;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  background-color: transparent;
  color: #040C2D;
  flex: 1;
}

.component-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  height: 100%;
}

.setting-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
}

.component-list h3 {
  font-family: euclid_semi_bold, sans-serif;
  font-size: 14px;
  color: rgba(4, 12, 45, 0.6);
  text-transform: uppercase;
  padding: 0;
  letter-spacing: 0.6px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 10px;
}

.list-item-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
  transition: background-color 0.1s;
}

.list-item-wrapper:hover {
  background-color: #F5F7FA;
}

.list-item-wrapper .name {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: rgba(4, 12, 45, 0.5);
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 12px;
}

.icon-wrapper.green {
  background-color: #e3fcef;
}

.icon-wrapper.yellow {
  background-color: #FFF0B3;
}

.icon-wrapper.blue {
  background-color: #DEEBFF;
}

.icon-wrapper img {
  width: 18px;
  height: 18px;
}
</style>
