import axios from './providers/axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const therapyService = {
    getTherapies(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/therapy/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/therapy/store?limit=20&page=${page}`);
        }
    },
    getTherapyModules(id) {
        return axios.get(`/v1/admin/therapy/module/store/${id}?list=1`);
    },
    addTherapy(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v2/admin/therapy/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    updateTherapy(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v2/admin/therapy/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    getTherapyDetail(id, lang = 'tr') {
        return axios.get(`/v2/admin/therapy/getFromId/${id}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Accept-Language": lang,
            },
        });
    },
    assignTherapy(data) {
        return axios.post('/v1/admin/therapy/assign', data);
    },
    fetchTherapyWeights(id) {
        return axios.get(`/v1/admin/therapy/weightNote/store?therapyId=${id}`);
    },
    saveWeights(data) {
        return axios.post('/v1/admin/therapy/weightNote/addOrUpdate', data);
    },
    getCopy(therapyId) {
        return axios.post('/v2/admin/therapy/copy', {
            therapyId: therapyId
        });
    },
    getModulesSubModules(therapyId) {
        return axios.get(`/v2/admin/therapy/getModulesSubModules/${therapyId}`);
    },
    getProgramList() {
        return axios.get('/v1/admin/therapy/store?list=1')
    },

    deleteTherapy(id) {
        return axios.delete(`/v1/admin/therapy/delete/${id}`);
    },
}

export default therapyService;
