<template>
  <Layout padding="0">
    <JourneyUserHeader
        :is-loading="isLoading"
        :title="data.general?.name + ' ' + data.general?.surname"
        :status="data.general?.status"
        :created-at="data.general?.createdAt"
        :photo="data.general?.avatarUrl"
        :journey-id="journeyId"
        :user-id="data.general?.userId"
        :on-end-journey="handleEndJourney"
        :program-name="data.therapyName"
        :general-point="data.general?.generalPoint"
    />
    <div class="main-content">
      <div class="tab-panel-row">
        <div class="tab-panel">
          <button class="tab-button" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
            <span>{{$t('general_tab')}}</span>
          </button>

          <button class="tab-button" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
            <span>{{$t('flow')}}</span>
          </button>

          <button class="tab-button" :class="{'active': tabIndex === 2}" @click="() => changeTab(2)">
            <span>{{$t('interview')}}</span>
          </button>

          <button class="tab-button" :class="{'active': tabIndex === 3}" @click="() => changeTab(3)">
            <span>{{$t('files')}}</span>
          </button>

          <button class="tab-button" :class="{'active': tabIndex === 4}" @click="() => changeTab(4)">
            <span>{{$t('tests')}}</span>
          </button>
        </div>
      </div>

      <div v-if="tabIndex === 0" class="tab-container">
        <h3>{{$t('user_details')}}</h3>
        <div class="company-details">
          <div class="company-details-col">

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/avatar.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('full_name')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.name + ' ' + data.general?.surname}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/message.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('email_address')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.email}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/buildings.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title" v-if="!isLoading">
                  {{$t('city_person')}}
                </span>
                <span class="detail-text">
                  {{data.general?.city}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

          </div>

          <div class="company-details-col">

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/phone.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('phone')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.phone}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/cake.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('birthday')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.birthday}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/education.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('education')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.educationLevel}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

          </div>

          <div class="company-details-col">

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/twoUser.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('marital_status')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.maritalStatus}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/medal.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('general_point')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.generalPoint}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/notifications.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('notifications_title')}}
                </span>
                <a :href="'/notifications?journeyId=' + journeyId" class="detail-text link" v-if="!isLoading">
                  {{$t('see_all')}}
                </a>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>


          </div>
        </div>
      </div>

      <div v-if="tabIndex === 1" class="tab-container two-row">
          <Transition name="slide-fade-rtl">
            <div class="sidebar">
                <div v-for="(element, index) in moduleList" :key="index">
                  <div class="module-block">
                    <div class="module-block-header" @click="() => {
                  if (activeModuleCard === index) {
                    activeModuleCard = -1;
                  } else {
                    activeModuleCard = index;
                  }
                }">
                      <div class="module-block-icon">
                        <span class="module-name-input" >{{element.name}}</span>
                      </div>
                      <img src="@/assets/images/arrowdown.svg" class="module-block-arrow" :class="{'rotate': activeModuleCard === index}"/>
                    </div>

                    <Transition name="slide-up">
                      <div v-if="activeModuleCard === index" class="module-block-body">
                        <div class="module-block-list">
                          <div
                              class="module-name-row"
                              :class="{'active': checkActiveModule(subModule.order)}"
                              v-for="subModule in element.subModules"
                              @click="setActiveSubModule(subModule.order)"
                              :key="subModule.order + forceRerender"
                          >
                            <span class="module-name">{{subModule.name}}</span>
                            <span class="module-step">{{$t('step_count', {stepCount: subModule.userFlow.length-1})}}</span>
                          </div>
                        </div>
                      </div>
                    </Transition>
                  </div>
                </div>
            </div>
          </Transition>
        <div class="flow-wrapper">
          <div v-for="(step, index) in currentSubModule.userFlow">
            <JourneyFlow
                :index="index"
                :step="step"
                :step-count="currentSubModule.userFlow.length"
                :next-step="currentSubModule.userFlow[index + 1] || null"
                :last-data="(index === currentSubModule.userFlow.length - 2) && currentSubModule.userFlow[index + 1]?.type==='end'"
            ></JourneyFlow>
          </div>
        </div>
        </div>

      <div class="chat-tab" v-if="tabIndex===2">
        <div class="chat-wrapper">
          <div class="chat-list scroll-design">
            <router-link class="w-100" :to="'/notification/new?journeyId=' + journeyId + '&userIds=' + data.general?.userId">
              <button class="new-interview-button" @click="() => chatGroupModalVisible = true">
                <img src="@/assets/images/add.svg" />
                <span>New interview</span>
              </button>
            </router-link>

            <h4>{{$t('interviews')}}</h4>


            <div class="interview-list">

              <div class="no-messages-wrapper" v-if="!chatLoading && data.interview?.length === 0">
                <span>No interviews found</span>
              </div>

              <div class="chat-list-item-wrapper"
                   v-if="!isLoading"
                   v-for="item in data.interview"
              >
                <div
                    class="chat-list-item"
                    :class="{'active': activeInterviewId === item.notificationId}"
                >
                  <img src="@/assets/images/linkOutline.svg" />
                  <div class="chat-info"                     @click="() => handleInterviewClick(item.notificationId)"
                  >
                    <span class="info-tag" :class="item.status === 2 ? 'green' : item.status === 3 &&  'red'">
                      {{ item.status === 2 ? 'Done' : item.status === 3 ? 'Unattended' : 'Canceled/Pending' }}
                    </span>

                    <span class="chat-name">{{item.content.info}}</span>
                    <span class="chat-date">{{ item.content.interviewDate }}</span>
                  </div>

                  <MorePane
                      :pane-items="[
        {
          text: 'Cancel interview',
          action: () => {
            this.handleInterviewAction('cancel', item);
          },
        },
        {
          text: 'Mark as done',
          action: () => {
            this.handleInterviewAction('done', item);
          },
        },
        {
          text: 'Mark unattended',
          action: () => {
            this.handleInterviewAction('unattended', item);
          },
        }
      ]"
                      :on-action="action => handleInterviewAction(action, item)" />
                </div>

                <div class="chat-list-sub-item-wrapper" v-if="activeInterviewId === item.notificationId">
                  <div v-if="interviewGroupsLoading" class="group-loading-wrapper">
                    <VueSpinnerIos class="mt-2" />
                  </div>

                  <div v-else class="chat-group-list">
                    <div
                        class="group-item"
                        v-for="group in activeInterviewGroups"
                        :key="group.id"
                        @click="() => handleGroupClick(group)"
                        :class="{'active': activeChatGroupId === group.id}"
                    >
                      <img src="@/assets/images/hashtag.svg" />
                      <span>{{group.name}}</span>
                    </div>

                    <button class="create-group-button" @click="() => chatGroupModalVisible = true">
                      <img src="@/assets/images/add.svg" />
                      <span>Create a chat group</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="chat-list-item" v-if="isLoading">
                <free-style-shimmer height="64px" width="64px" border-radius="50%" color="#EAECF0" />

                <div class="chat-info">
                  <div class="chat-name">
                    <free-style-shimmer  height="10px" width="40%" border-radius="12px" color="#EAECF0" />
                  </div>
                  <div class="last-message">
                    <free-style-shimmer  height="8px" width="80%" border-radius="12px" color="#EAECF0" />
                  </div>
                </div>
              </div>

              <div class="chat-list-item" v-if="isLoading">
                <free-style-shimmer height="64px" width="64px" border-radius="50%" color="#EAECF0" />

                <div class="chat-info">
                  <div class="chat-name">
                    <free-style-shimmer  height="10px" width="40%" border-radius="12px" color="#EAECF0" />
                  </div>
                  <div class="last-message">
                    <free-style-shimmer  height="8px" width="80%" border-radius="12px" color="#EAECF0" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="chat" v-if="!chatLoading">
            <div class="chat-header" v-if="activeChatGroupId !== -1">
              <img src="" class="chat-avatar" />
              <div class="chat-info">
                <span class="chat-name">{{ activeChatGroupName }}</span>

                <span class="chat-participants">
                  {{getParticipantList()}}
                </span>
              </div>
            </div>

            <div class="chat-content scroll-design"  v-if="activeChatGroupId !== -1">
              <div class="load-more-wrapper" v-if="activeChatHasMore">
                <button class="load-more-button" @click="handleLoadMore">
                  Load more
                </button>
              </div>

              <div class="load-more-wrapper" v-if="moreLoading">
                <VueSpinnerIos />
              </div>

              <div class="chat-message" v-for="item in activeInterviewMessages" :class="{'incoming': item.senderId !== senderId}">
                <div class="chat-message-wrapper" :class="{'incoming': item.senderId !== senderId}">
                  <span class="chat-sender-name" v-if="item.senderId !== senderId">
                    {{getParticipantName(item.senderId)}}
                  </span>
                  <span class="chat-message-text" v-if="item.content">{{item.content}}</span>
                  <a :href="item.file" v-if="item.file" class="mt-1" target="_blank">
                    <div class="chat-message-attach" >
                      <img src="@/assets/images/downloadOutline.svg" />
                      <span>{{getFileName(item.file)}}</span>
                    </div>
                  </a>
                  <span class="chat-message-time">{{this.$moment(item.createdAt).fromNow()}}</span>
                </div>
              </div>
            </div>

            <div class="chat-content not-selected" v-if="activeChatGroupId === -1">
              <span>{{$t('no_conversation_selected')}}</span>
            </div>

            <div class="chat-footer" v-if="activeChatGroupId !== -1">
              <input class="chat-input" placeholder="Type your message here" v-model="chatMessage" :disabled="sendLoading" />

              <button class="attach-button" @click="handleAttachChatFile">
                <img src="@/assets/images/attach.svg" />

                <span class="attach-count" v-if="chatFile">
                  1
                </span>
              </button>

              <button class="send-button" @click="handleSendMessage" :disabled="sendLoading">
                <img src="@/assets/images/sendArrow.svg" v-if="!sendLoading"/>
                <VueSpinnerIos v-if="sendLoading" size="20" height="100%" color="#fff" />
              </button>
            </div>
          </div>

          <div class="chat-loading" v-else>
            <VueSpinnerIos :size="24" />
          </div>
        </div>

      </div>

      <div v-if="tabIndex === 3" class="tab-container"  v-click-outside="removeSelectedFiles">
        <div class="title-row">
          <h3>{{$t('uploaded_files')}}</h3>

          <div class="select-actions"  v-if="selectedFiles.length > 0">
            <span class="selected-count">{{$t('selected')}} {{selectedFiles.length}} {{$t('files_title')}}</span>
            <ActionDropdown :actions="fileActions" :on-action="handleFileAction" :placeholder="$t('actions')"/>
          </div>
        </div>

        <div class="file-grid">
          <FileCard
              v-for="file in data.files"
              :file="{
                fileStatus: file.status === '1' ? 'approved' : file.status === '2' ? 'rejected' : 'pending',
                fileName: file.name,
                fileCover: file.coverImageUrl,
                fileAttachment: file.attachUrl,
                fileSize: file.info?.size,
                fileType: file.info?.extension
              }"
              :user="{
                avatarUrl: file.userInfo?.avatarUrl,
                name: file.userInfo?.name
              }"
              :on-click="() => selectFile(file.fileId)"
              :selected="selectedFiles.includes(file.fileId)"
          />
        </div>
      </div>

      <div v-if="tabIndex === 4" class="tab-container">
        <div class="test-grid">
          <div class="test-card" v-for="test in data.tests">
            <div class="title-holder">
              <span class="test-name">{{test.name}}</span>
              <MorePane
                  :disabled="test.solved !== 1"
                  :pane-items="[
    ...(test.solved === 1 && test.totalScore === 0 ? [
      {
        text: $t('download_pdf'),
        icon: DocumentOutline,
        action: () => {
          openPdfUrl(test.pdfUrl);
        }
      }
    ] : []),
    {
      text: $t('send_report_to_user'),
      icon: NotificationOutline,
      action: () => {
        sendReportNotification(this.journeyId, test.testId);
      }
    }
  ]"
              />
            </div>

            <div class="result-row">
              <div class="result-block">
                <span class="block-title">
                  {{$t('weight_note')}}
                </span>
                <span class="block-result">
                  {{test.weightNote}}
                </span>
              </div>

              <div class="result-block">
                <span class="block-title">
                  {{$t('user_score')}}
                </span>
                <span class="block-result">
                  {{test.userScore}}
                </span>
              </div>

              <div class="result-block">
                <span class="block-title">
                  {{$t('total_score')}}
                </span>
                <span class="block-result">
                  {{test.totalScore}}
                </span>
              </div>

              <div class="result-block">
                <span class="block-title">
                  {{$t('solved')}}
                </span>
                <span class="block-result">
                  {{test.solved === 1 ? 'Yes' : 'No'}}
                </span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>



    <CreateChatGroupModal
        :visible="chatGroupModalVisible"
        :on-hide="hideChatGroupModal"
        :notification-id="activeInterviewId"
        :refresh-callback="refreshChatGroups"
    />
    <LoadingOverlay :is-loading="overlayVisible" />
  </Layout>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import Layout from "@/components/layouts/Layout.vue";
import AvatarArray from "@/components/AvatarArray.vue";
import Pagination from "@/components/Pagination.vue";
import NotificationNode from "@/components/condition-flow/nodes/NotificationNode.vue";
import PartNode from "@/components/condition-flow/nodes/PartNode.vue";
import DropzoneBackground from "@/components/condition-flow/DropzoneBackground.vue";
import AnswerConditionNode from "@/components/condition-flow/nodes/AnswerConditionNode.vue";
import StartNode from "@/components/condition-flow/nodes/StartNode.vue";
import EndNode from "@/components/condition-flow/nodes/EndNode.vue";
import QuestionNode from "@/components/condition-flow/nodes/QuestionNode.vue";
import {VueFlow} from "@vue-flow/core";
import testService from "@/service/testService";
import notificationContentService from "@/service/notificationContentService";
import {Background} from "@vue-flow/background";
import JourneyUserHeader from "@/components/JourneyUserHeader.vue";
import ActionDropdown from "@/components/ActionDropdown.vue";
import FileCard from "@/components/FileCard.vue";
import NotesModal from "@/components/modals/NotesModal.vue";
import journeyService from "@/service/journeyService";
import SelectBox from "@/components/SelectBox.vue";
import AndNode from "@/components/condition-flow/nodes/AndNode.vue";
import OrNode from "@/components/condition-flow/nodes/OrNode.vue";
import AssignNode from "@/components/condition-flow/nodes/AssignNode.vue";
import TestScoreCondition from "@/components/condition-flow/nodes/TestScoreCondition.vue";
import fileService from "@/service/fileService";
import {handleErrorResponse} from "@/utils/utils";
import NumberQuestionNode from "@/components/condition-flow/nodes/NumberQuestionNode.vue";
import MorePane from "@/components/MorePane.vue";
import DocumentOutline from '@/assets/images/documentOutline.svg'
import NotificationOutline from '@/assets/images/notificationOutline.svg';
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import draggable from "vuedraggable";
import FlowComponent from "@/components/user-flow/FlowComponent.vue";
import JourneyFlow from "@/components/JourneyFlow.vue";
import CreateChatGroupModal from "@/components/modals/CreateChatGroupModal.vue";
import {VueSpinnerIos} from "vue3-spinners";
import socketChatService from "@/service/socketChatService";
import chatSocket from "@/utils/chatSocket";

export default {
  name: 'JourneyDetails',
  components: {
    VueSpinnerIos,
    CreateChatGroupModal,
    JourneyFlow,
    FlowComponent,
    draggable,
    LoadingOverlay,
    MorePane,
    NumberQuestionNode,
    TestScoreCondition, AssignNode, OrNode, AndNode,
    SelectBox,
    NotesModal,
    FileCard,
    ActionDropdown,
    JourneyUserHeader,
    Background,
    VueFlow,
    QuestionNode, EndNode, StartNode, AnswerConditionNode, DropzoneBackground, PartNode, NotificationNode,
    Pagination,
    AvatarArray,
    Layout,
    BarChart,
  },
  data() {
    return {
      chatSocket,
      senderId: -1,
      activeModuleCard: 0,
      activeSubModule: 1,
      forceRerender:0,
      NotificationOutline,
      DocumentOutline,
      isLoading: true,
      journeyId: '',
      actionDropVisible: false,
      testList: [],
      notificationList: [],
      tabIndex: 0,
      data: {},
      nodes: [],
      edges: [],
      moduleList:[],
      currentSubModule:[],
      currentModule:{},
      fileList: [
        {
          fileId: 1,
          fileStatus: 'pending',
          fileName: 'File 01',
          fileSize: '10MB',
          fileType: 'mp4'
        },
        {
          fileId: 2,
          fileStatus: 'approved',
          fileName: 'File 02',
          fileSize: '8MB',
          fileType: 'mp4'
        },
        {
          fileId: 3,
          fileName: 'File 03',
          fileStatus: 'rejected',
          fileSize: '10MB',
          fileType: 'mp4'
        },
        {
          fileId: 4,
          fileName: 'File 04',
          fileStatus: 'approved',
          fileSize: '10MB',
          fileType: 'mp4'
        },
        {
          fileId: 5,
          fileName: 'File 05',
          fileStatus: 'approved',
          fileSize: '10MB',
          fileType: 'mp4'
        },
      ],
      flowList: [],
      currentFlow: {},
      selectedFiles: [],
      fileActions: [
        {
          name: 'Set as approved',
          action: 'approve'
        },
        {
          name: 'Set as rejected',
          action: 'cancel'
        }
      ],
      overlayVisible: false,
      chatLoading: false,
      activeInterviewId: -1,
      chatGroupModalVisible: false,
      interviewGroupsLoading: true,
      activeInterviewGroups: [],
      activeChatGroupName: '',
      activeChatGroupId: -1,
      activeInterviewMessages: [],
      sendLoading: false,
      chatMessage: '',
      activeGroupParticipants: [],
      chatFile: null,
      activeChatPageIndex: 1,
      activeChatHasMore: false,
      moreLoading: false,
    }
  },
  async mounted() {
    const routeParams = this.$route.params;

    await this.fetchData();

    if (routeParams.id !== undefined && routeParams.id !== 'undefined') {
      this.isLoading = true;
      this.journeyId = routeParams.id;
      await this.fetchJourneyDetails(routeParams.id);
      await this.getFirst(routeParams.id);
    } else {
      window.location.href = "/journeys";
    }

    chatSocket.connect();

    const user = JSON.parse(localStorage.getItem('user'));
    this.senderId = user.userId;
  },
  unmounted() {
    chatSocket.disconnect();
  },
  watch: {
    'chatSocket.state.messages': {
      handler: function (val) {
        val.map((data) => {
          if (this.activeInterviewMessages.length > 0 && this.activeInterviewMessages.find((message) => message.id === data.message.id)) {
            return;
          }
          this.activeInterviewMessages.push(data.message);
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getFirst(journeyId){
      journeyService.getJourneyWithDetail(journeyId).then((response) => {
        this.moduleList=response.data.data.modules;
        this.currentModule=response.data.data.modules[0];
        this.currentSubModule=response.data.data.modules[0].subModules[0];
      })
    },
    async fetchJourneyDetails(journeyId) {
      this.isLoading = true;
      journeyService.getJourneyDetails(journeyId).then((response) => {
        this.data = response.data.data;
        this.data.flow?.modules?.forEach((module) => {
          this.flowList.push({
            text: module.moduleName ? module.moduleName : 'No name',
            value: module.moduleId ? module.moduleId : '-1',
          });
        });
        this.currentFlow = this.data.flow?.modules[0];
        this.isLoading = false;
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching journey details failed",
          type: "error",
        });
      });
    },
    async fetchData() {
      await testService.getTestList().then((response) => {
        this.testList = response.data.data;
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching test list failed",
          type: "error",
        });
      });

      await notificationContentService.getNotificationContentList().then((response) => {
        this.notificationList = response.data.data;
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching notification list failed",
          type: "error",
        });
      });
    },
    checkActiveModule(order) {
      return this.activeSubModule === order;
    },
    setActiveSubModule(order) {
      this.activeSubModule = order;
      this.forceRerender++;
    },
    changeTab(index) {
      if (this.isLoading) {
        return;
      }
      this.tabIndex = index;
    },
    removeSelectedFiles() {
      this.selectedFiles = [];
    },
    selectFile(fileId) {
      if (this.selectedFiles.includes(fileId)) {
        this.selectedFiles = this.selectedFiles.filter((id) => id !== fileId);
      } else {
        this.selectedFiles.push(fileId);
      }
    },
    handleFileAction(action) {
      this.fileActionLoading = true;
      fileService.setFileStatus({
        fileIds: this.selectedFiles,
        status: action === 'approve' ? '1' : '2'
      }).then(() => {
        this.selectedFiles = [];
        this.fetchJourneyDetails(this.journeyId);
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.fileActionLoading = false;
      });
    },
    handleEndJourney() {
      journeyService.endJourney([this.journeyId]).then(() =>
          this.$snackbar.add({
            text: "Journey ended successfully",
            type: "success",
          })
      ).catch(() =>
          this.$snackbar.add({
            text: "Journey end failed",
            type: "error",
          })
      );
    },
    handleInterviewAction(action, interview) {
      const status = action === 'cancel' ? '1' : action === 'done' ? '2' : '3';

      this.isLoading = true;
      journeyService.updateInterviewStatus(interview.notificationId, status).then(() => {
        this.fetchJourneyDetails(this.journeyId);
      }).catch(err => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    openPdfUrl(url) {
      if (!url || !url.startsWith('http')) {
        return;
      }
      window.open(url, '_blank');
    },
    sendReportNotification(journeyId,testId) {
      if (!journeyId || !testId || this.overlayVisible) {
        return;
      }

      this.overlayVisible = true;

      testService.sendReport(journeyId,testId).then(() => {
        this.$snackbar.add({
          text: "Report sent successfully",
          type: "success",
        });
      }).catch(() => {
        this.$snackbar.add({
          text: "Sending report failed",
          type: "error",
        });
      }).finally(() => {
        this.overlayVisible = false;
      });
    },
    handleInterviewClick(interviewId) {
      this.activeInterviewId = interviewId;
      this.currentMessages = [];
      this.activeInterviewGroups = [];
      this.interviewGroupsLoading = true;
      this.activeChatGroupId = -1;

      this.messagesLoading = true;

      socketChatService.getInterviewChatGroups(interviewId).then((response) => {
        this.interviewGroupsLoading = false;
        this.activeInterviewGroups = response.data;
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching interview messages failed",
          type: "error",
        });
      });
    },
    refreshChatGroups() {
      this.activeInterviewGroups = [];
      this.interviewGroupsLoading = true;
      socketChatService.getInterviewChatGroups(this.activeInterviewId).then((response) => {
        this.interviewGroupsLoading = false;
        this.activeInterviewGroups = response.data;
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching interview messages failed",
          type: "error",
        });
      });
    },
    hideChatGroupModal() {
      this.chatGroupModalVisible = false;
    },
    handleGroupClick(group) {
      this.activeChatGroupId = group.id;

      this.activeInterviewMessages = [];
      this.chatLoading = true;
      this.handleChatPage(group.id, 1);

      chatSocket.joinRoom(group.id);
    },
    handleChatPage(chatId, page) {
      this.activeChatPageIndex = page;
      this.activeChatHasMore = false;

      socketChatService.getChat(chatId, page).then((response) => {
        this.activeInterviewMessages = [
          ...response.data.messages,
          ...this.activeInterviewMessages,
        ];
        this.activeChatGroupName = response.data.chat?.name;
        this.activeGroupParticipants = response.data.chat?.participantDetails;

        this.activeChatHasMore = response.data.pagination.hasNextPage;
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching chat messages failed",
          type: "error",
        });
      }).finally(() => {
        this.chatLoading = false;
        this.moreLoading = false;
      });
    },
    handleLoadMore() {
      this.moreLoading = true;
      this.handleChatPage(this.activeChatGroupId, this.activeChatPageIndex + 1);
    },
    handleSendMessage() {
      if (this.activeChatGroupId === -1 || this.sendLoading) {
        return;
      }

      this.sendLoading = true;

      socketChatService.sendMessage(this.activeChatGroupId, this.chatMessage, this.chatFile).then(res => {
        this.chatMessage = '';
      }).catch(() => {
        this.$snackbar.add({
          text: "Sending message failed",
          type: "error",
        });
      }).finally(() => {
        this.chatFile = null;
        this.sendLoading = false;
      })
    },
    handleAttachChatFile() {
      this.chatFile = null;

      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*,application/pdf,.doc,.docx,.xml,.xlsx,.xls,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      fileInput.onchange = (e) => {
        const file = e.target.files[0];
        this.chatFile = file;
      };

      fileInput.click();
    },
    getParticipantName(userId) {
      if (!this.activeGroupParticipants) {
        return '';
      }

      const foundUser = this.activeGroupParticipants?.find((participant) => participant.userId === userId);

      if (!foundUser) {
        return '';
      }

      return foundUser?.name + ' ' + foundUser?.surname;
    },
    getParticipantList() {
      if (!this.activeGroupParticipants) {
        return [];
      }

      const tempList = this.activeGroupParticipants?.map((participant, index) => {
        return participant.name + ' ' + participant.surname;
      });

      return tempList.join(', ');
    },
   getFileName(fileUrl){
      if (!fileUrl) {
        return 'Attachment';
      }

      const urlWithoutParams = fileUrl.split('?')[0];

      return urlWithoutParams.split('/').pop();
    },
  },

}
</script>

<style scoped>

.module-block {
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  border-radius: 8px;
  padding: 15px;
  margin-top: 25px;
}

.module-block-list {
  display: flex;
  flex-direction: column;
  position: relative;
}

.module-name-row::before,
.module-name-row::after {
  content: "";
  position: absolute;
  background-color: rgba(28, 60, 213, 0.4);
}

.module-name-row::before {
  top: 50%;
  left: 0;
  width: 10px;
  height: 1.5px;
  transform: translateY(-50%);
}

.module-name-row::after {
  top: 0;
  left: 0;
  width: 1.5px;
  height: 100%;
}

.module-name-row:last-child::after {
  height: 50%;
}

.module-block-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.module-block-body {
  padding-top: 20px;
}

.module-block-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.module-block-arrow {
  height: 20px;
  width: 20px;
  transition: transform 0.2s;
}

.module-block-arrow.rotate {
  transform: rotate(180deg);
}

.module-name-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 15px;
  cursor: pointer;
  position: relative;
}

.module-name-row .module-name {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: rgba(28, 60, 213, 0.4);
}

.module-name-row.active .module-name {
  color: #1C3CD5;
}

.module-step {
  font-size: 12px;
  color: #667085;
  white-space: nowrap;
}

.module-name-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
  background-color: transparent;
  flex: 1;
  margin-right: 5px;
}

.module-name-input:focus {
  outline: none;
  border-bottom: 1px solid #D0D5DD;
}

.sidebar{
  width: 30%;
  height: calc(100vh - 64px);
  background-color: #FFFFFF;
  border-right: 1px solid #EAECF0;
  padding: 20px;
  padding-top: 0px;
  overflow-y: auto;
}
.flow-wrapper {
  padding: 30px;
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 10px;
}

.tab-panel {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}
.main-content {
  padding: 25px;
  height: auto;
  display: block;
}
.tab-panel-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #F2F4F7;
  border-bottom: 1px solid #F2F4F7;
  padding: 10px 0;
  margin-bottom: 20px;
}
.tab-button {
  padding: 6px 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.tab-button span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #344054;
}
.tab-button.active, .tab-button:hover {
  background-color: #F2F4F7;
}

.title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
  height: 40px;
}

h3 {
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title-row h3 {
  margin-bottom: 0;
  margin-top: 0;
}

h4 {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
  padding: 0;
  margin: 0;
}
.tab-container {
  height: 100%;
  width: 100%;
}
.tab-container.two-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.tab-container.two-row .left-card, .tab-container.two-row .right-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tab-container .tab-sidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.company-details {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.company-details-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;
}
.company-detail-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.company-detail-icon {
  background-color: #F5F5F5;
  border-radius: 16px;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-detail-icon img {
  cursor: default;
  width: 20px;
  height: 20px;
}
.detail-title {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #667085;
  font-size: 12px;
}
.detail-text {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #101828;
  font-size: 14px;
}
.detail-text.link {
  color: #4A68F8;
  cursor: pointer;
}

.note-file-item img {
  width: 18px;
  height: 18px;
}

.note-file-item span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #4A68F8;
}

.note-file-item:hover {
  background-color: #F2F4F7;
}

.file-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.select-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.selected-count {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
}

.test-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.test-card {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.test-name {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.result-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.result-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  border: 1px solid #EAECF0;
  padding: 10px;
  border-radius: 12px;
  flex: 1;
}

.block-title {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #667085;
}

.block-result {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.title-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}



.chat-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.chat-list {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  height: 600px;
  overflow-y: auto;
  border-radius: 14px;
  background-color: transparent;
  border: 1px solid #EAECF0;
  padding: 10px;
}

.chat-list-item-wrapper {
  display: flex;
  flex-direction: column;
}

.chat-tab {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat-list-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 16px;
  border-radius: 12px;
  background-color:  transparent;
  border: 1px solid #EAECF0;
}

.chat-list-item.active {
  background-color: #F2F4F7;
}

.chat-info {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}


.chat-info .chat-name {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #101828;
  line-height: 1;
}

.chat-info .chat-date {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #98A2B3;
  line-height: 1;
}

.chat-info:hover .chat-info .chat-name, .chat-name:hover {
  color: #4A68F8 !important;
  cursor: pointer;
}

.chat {
  width: 65%;
  height: 600px;
}

.chat-loading {
  width: 65%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  padding: 0 15px;
  height: 70px;
}

.chat-content {
  width: 100%;
  height: calc(100% - 150px);
  overflow-y: auto;
  padding: 20px;
  background-color: #FCFCFC;
  border-left: 1px solid #EAECF0;
  border-right: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.chat-content.not-selected {
  border: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.chat-content.not-selected span {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
}

.chat-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  padding: 0 15px;
  height: 80px;
}

.chat-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.chat-message.incoming {
  align-items: flex-start;
}


.chat-message-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-message-wrapper.incoming {
  align-items: flex-start;
}

.chat-message-attach {
  background-color: #EAECF0;
  padding: 5px 15px;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.chat-message-attach img {
  height: 18px;
  width: 18px;
}

.chat-message-attach span {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667084;
  text-decoration: underline;
}

.chat-message-attach:hover span {
  color: #4A68F8;
}

.chat-message-text {
  background-color: #EAECF0;
  color: #101828;
  padding: 5px 15px;
  border-radius: 24px;
  font-size: 14px;
}

.chat-message-wrapper.incoming  .chat-message-attach {
  background-color: #4A68F8;
}

.chat-message-wrapper.incoming .chat-message-text {
  background-color: #4A68F8;
  color: #FFFFFF;
}

.chat-message-time {
  font-size: 10px;
  color: #98A2B3;
  margin-right: 8px;
  margin-left: 8px;
}

.chat-input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #F5F5F5;
  padding: 15px;
  font-family: euclid_medium, sans-serif;
  color: #010511;
}

.interview-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.new-interview-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 15px 10px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #EAECF0;
  color: #667085;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.new-interview-button:hover {
  background-color: #F2F4F7;
}

.new-interview-button img {
  height: 18px;
  width: 18px;
}

.chat-list-sub-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.group-loading-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.chat-group-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 25px;
  margin-top: 10px;
}

.group-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 16px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #EAECF0;
  cursor: pointer;
  position: relative;
  height: 46px;
}

.group-item::before {
  content: "";
  position: absolute;
  height: 66px;
  width: 12px;
  border-left: 1px solid #EAECF0;
  border-bottom: 1px solid #EAECF0;
  left: -12px;
  bottom: 22px;
  z-index: -1;
}

.group-item span {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.group-item.active span {
  color: #4A68F8;
}

.group-item:hover {
  background-color: #F2F4F7;
}

.group-item.active {
  background-color: #F2F4F7;
}

.group-item img {
  height: 16px;
  width: 16px;
}

.create-group-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  gap: 5px;
  border-radius: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-group-button img {
  height: 16px;
  width: 16px;
}

.create-group-button span {
  color: #667085;
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
}

.create-group-button:hover {
  background-color: rgba(74, 104, 248, 0.15);
}

.send-button {
  background-color: #4A68F8;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-button img {
  height: 22px;
  width: 22px;
}

.chat-sender-name {
  font-size: 10px;
  color: #98A2B3;
  margin-right: 8px;
  margin-left: 8px;
}

.chat-participants {
  font-size: 10px;
  color: #98A2B3;
}

.info-tag {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #fff;
  padding: 2px 10px;
  border-radius: 8px;
  background-color: #ffa123;
  flex-wrap: wrap;
  align-self: flex-start;
}
.info-tag.blue {
  background-color: #4A68F8;
}
.info-tag.green {
  background-color: #3da33e;
}
.info-tag.red {
  background-color: #ed4343;
}

.attach-button {
  background-color: #F5F5F5;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.attach-button img {
  height: 22px;
  width: 22px;
}

.attach-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #4A68F8;
  color: #fff;
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-messages-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.no-messages-wrapper span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
}

.load-more-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.load-more-button {
  background-color: #4A68F8;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 10px;
  color: #fff;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
}
</style>
