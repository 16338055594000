<script setup>
import Sidebar from "@/components/Sidebar.vue";
import Header from "@/components/Header.vue";
import {ref} from "vue";
import settingsService from "@/service/settingsService";
import {handleErrorResponse} from "@/utils/utils";

const logo = ref("");
const companyName = ref("");

const props = defineProps({
  padding: {
    type: Number,
    default: 20,
  },
});
const sideBarRef = ref();

const onMenuClick = () => {
  sideBarRef.value.toggleCollapse();
};

const getCompanyDetails = () => {
  const cachedLogo = localStorage.getItem('logoCacheUrl')
  if (cachedLogo) {
    logo.value = cachedLogo
  }

  settingsService.getCompanySettings().then(response => {
    const newLogoUrl = response.data.data.appIconUrl;

    if (newLogoUrl && newLogoUrl !== logo.value) {
      localStorage.setItem('logoCacheUrl', newLogoUrl)
      logo.value = newLogoUrl
    }
    companyName.value = response.data.data.name;
  }).catch(error => {
    console.log("error", error);
  });
};

getCompanyDetails();
</script>

<template>
  <div id="app" class="app-container">
    <Sidebar ref="sideBarRef" :logo="logo"/>
    <div class="main-content">
      <Header :on-menu-click="onMenuClick" :current-company="companyName" :refresh-callback="getCompanyDetails"/>
      <div class="content" :style="{ padding: `${padding}px` }">
        <slot/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.app-container {
  display: flex;
  margin: 0;
  padding: 0;
}
.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.content {
  height: calc(100vh - 72px);
  flex-shrink: 0;
  overflow-y: auto;
}

</style>
