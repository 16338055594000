<template>
  <div v-if="setting?.type === 'info'" class="info-box">
      <img src="@/assets/images/warning.svg" />

      <p>{{setting?.description}}</p>
  </div>

  <div v-if="setting?.type === 'programSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="optionList.programOptions"
        :selected-value="getSettingValue(setting.variableName)"
        :on-select="(val) => {
          setSettingValue(setting.variableName, val, setting.settingsId);
          emitChanges();
        }"
    />
  </div>

  <div v-if="setting?.type === 'numberInput'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <input
        class="form-input"
        type="number"
        :placeholder="setting?.name"
        :value="getSettingValue(setting.variableName) || ''"
        @input="(e) => {
          setSettingValue(setting.variableName, e.target.value, setting.settingsId);
          emitChanges();
        }"
    />
  </div>

  <div v-if="setting?.type === 'numberComparisonInput'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>

    <div class="input-row">
      <input
          class="form-input"
          type="number"
          :placeholder="setting?.name"
          :value="getSettingValue(setting.variableName)?.value || ''"
          @input="(e) => {
            setSettingValue(setting.variableName, {
              value: e.target.value,
              comparison: getSettingValue(setting.variableName)?.comparison || ''
            }, setting.settingsId);

            emitChanges();
          }"
      />

      <SelectBox
          placeholder="Comparison"
          :full-width="false"
          :options="comparisonOptions"
          :on-select="val => {
            setSettingValue(setting.variableName, {
              comparison: val,
              value: getSettingValue(setting.variableName)?.value || ''
            }, setting.settingsId);

            emitChanges();
          }"
          :selected-value="getSettingValue(setting.variableName)?.comparison || ''"
          :align-right="true"
      />
    </div>
  </div>

  <div v-if="setting?.type === 'starSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>

    <div class="input-row">
      <SelectBox
          :placeholder="setting?.name"
          :full-width="true"
          :options="starOptions"
          :on-select="val => {
            setSettingValue(setting.variableName, {
              value: val,
              comparison: getSettingValue(setting.variableName)?.comparison || ''
            }, setting.settingsId);

            emitChanges();
          }"
          :selected-value="getSettingValue(setting.variableName)?.value || ''"
      />

      <SelectBox
          placeholder="Comparison"
          :full-width="false"
          :options="comparisonOptions"
          :align-right="true"
          :on-select="val => {
            setSettingValue(setting.variableName, {
              comparison: val,
              value: getSettingValue(setting.variableName)?.value || ''
            }, setting.settingsId);

            emitChanges();
          }"
          :selected-value="getSettingValue(setting.variableName)?.comparison || ''"
      />
    </div>
  </div>

  <div v-if="setting?.type === 'testSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="optionList.testOptions"
        :on-select="val => {
          setSettingValue(setting.variableName, val, setting.settingsId);
          emitChanges();
        }"
        :selected-value="getSettingValue(setting.variableName)"
    />
  </div>

  <div v-if="setting?.type === 'visualTestSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="optionList.gameLabsOptions"
        :on-select="val => {
          setSettingValue(setting.variableName, val, setting.settingsId);
          emitChanges();
        }"
        :selected-value="getSettingValue(setting.variableName)"
    />
  </div>

  <div v-if="setting?.type === 'textInput'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <input
        class="form-input"
        type="text"
        :placeholder="setting?.name"
        :value="getSettingValue(setting.variableName) || ''"
        @input="(e) => {
          setSettingValue(setting.variableName, e.target.value, setting.settingsId);
          emitChanges();
        }"
    />
  </div>

  <div v-if="setting?.type === 'textArea'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <textarea
        class="form-textarea w-100"
        :placeholder="setting?.name"
        :value="getSettingValue(setting.variableName) || ''"
        @input="(e) => {
          setSettingValue(setting.variableName, e.target.value, setting.settingsId);
          emitChanges();
        }"
    ></textarea>
  </div>

  <div v-if="setting?.type === 'notificationData'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="optionList.notificationOptions"
        :on-select="val => {
          setSettingValue(setting.variableName, val, setting.settingsId);
          emitChanges();
        }"
        :selected-value="getSettingValue(setting.variableName)"
    />
  </div>

  <div v-if="setting?.type === 'userGroupSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="userGroupOptions"
        :on-select="val => {
          setSettingValue(setting.variableName, val, setting.settingsId);
          emitChanges();
        }"
        :selected-value="getSettingValue(setting.variableName)"
    />
  </div>

  <div v-if="setting?.type === 'emojiSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="emojiOptions"
        :on-select="val => {
          setSettingValue(setting.variableName, val, setting.settingsId);
          emitChanges();
        }"
        :selected-value="getSettingValue(setting.variableName)"
    />
  </div>

  <div v-if="setting?.type === 'departmentSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="optionList.departmentOptions"
        :on-select="val => {
          setSettingValue(setting.variableName, val, setting.settingsId);
          emitChanges();
        }"
        :selected-value="getSettingValue(setting.variableName)"
    />
  </div>

  <div v-if="setting?.type === 'roleSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="optionList.roleOptions"
        :on-select="val => {
          setSettingValue(setting.variableName, val, setting.settingsId);
          emitChanges();
        }"
        :selected-value="getSettingValue(setting.variableName)"
    />
  </div>

  <div v-if="setting?.type === 'programStatusSelect' || setting?.type === 'testStatusSelect' || setting?.type === 'visualTestStatusSelect'">
    <h4 class="setting-name">{{setting?.name}} <span v-if="setting.required" class="required-star">*</span></h4>
    <p class="setting-description">{{setting?.description}}</p>
    <SelectBox
        :placeholder="setting?.name"
        :full-width="true"
        :options="programStatusOptions"
        :on-select="val => {
          setSettingValue(setting.variableName, val, setting.settingsId);
          emitChanges();
        }"
        :selected-value="getSettingValue(setting.variableName)"
    />
  </div>
</template>

<script>
import SelectBox from "@/components/SelectBox.vue";

export default {
  name: 'SettingsRenderer',
  components: {SelectBox},
  props: {
    setting: {
      type: Object,
      required: true,
    },
    componentData: {
      type: Object,
      required: true,
    },
    optionList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userGroupOptions: [
        {text: 'Initiator', value: 'initiator'},
        {text: 'Admin', value: 'admin'},
      ],
      emojiOptions: [
        {text: 'Happy', value: 1},
        {text: 'Angry', value: 2},
        {text: 'Anxious', value: 3},
        {text: 'Guilty', value: 4},
        {text: 'Hurt', value: 5},
      ],
      starOptions: [
        {text: '1 stars', value: '1'},
        {text: '2 stars', value: '2'},
        {text: '3 stars', value: '3'},
        {text: '4 stars', value: '4'},
        {text: '5 stars', value: '5'},
      ],
      comparisonOptions: [
        {text: '>', value: 'greater'},
        {text: '<', value: 'less'},
        {text: '=', value: 'equal'},
        {text: '>=', value: 'greater-equal'},
        {text: '<=', value: 'less-equal'},
      ],
      programStatusOptions: [
        {text: 'Done', value: 'done'},
        {text: 'In Progress', value: 'inProgress'},
        {text: 'Not Started', value: 'notStarted'},
      ],
    };
  },
  methods: {
    emitChanges() {
      this.$emit('change', this.componentData);
    },
    getSettingValue(settingVariable) {
      const setting = this.componentData?.settings?.find(setting => setting[settingVariable] !== undefined);
      return setting ? setting[settingVariable] : null;
    },
    setSettingValue(settingVariable, newValue, settingsId) {
      if (!this.componentData?.settings) {
        this.componentData.settings = [];
      }

      const settingIndex = this.componentData?.settings?.findIndex(setting => setting[settingVariable] !== undefined);

      if (settingIndex !== -1) {
        this.componentData.settings[settingIndex][settingVariable] = newValue;
        return true;
      } else {
        const newSetting = {
          [settingVariable]: newValue,
          settingsId: settingsId,
        };
        this.componentData?.settings?.push(newSetting);
        return true;
      }

      return false;
    },

  }
}
</script>

<style scoped>
.setting-name {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
}

.setting-description {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040C2D;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.info-box {
  background-color: #FFF0B3;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.info-box img {
  width: 26px;
  height: 26px;
}

.info-box p {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040C2D;
}

.input-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.required-star {
  color: #F09460;
  display: inline-block;
}
</style>
