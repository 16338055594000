<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content" v-if="page === 1">
        <div class="modal-header">
          <span class="modal-header-txt">{{$t('edit_education')}}</span>
        </div>

        <button class="add-button" @click="page = 2">
          {{$t('add_new_school')}} <img src="@/assets/images/addWhite.svg" />
        </button>

        <div class="info-list">
          <div class="info-card" v-for="item in educationData">
            <div class="info-left">
              <img src="https://i.hizliresim.com/gzzvvnq.png" />
              <div class="info">
                <span class="title">{{ item.schoole.name }}, {{ item.schoole.country }}</span>
                <span class="description">{{ item.degree.name }}</span>
                <span class="date">
                {{item.finishAt}}
              </span>
              </div>
            </div>
            <img src="@/assets/images/editPen.svg" class="edit-icon" @click="editSchool(item.educationId)"/>
          </div>
        </div>

        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>

      <div class="modal-content" v-if="page === 2">
        <div class="modal-header">
          <img src="@/assets/images/arrowLeft.svg" @click="page = 1" />
          <span class="modal-header-txt">{{$t('add_new_school')}}</span>
        </div>

        <div class="school-form">
          <span class="form-label">{{$t('degree')}}</span>
          <SelectBox
              class="select-box"
              :full-width="true"
              :options="degreeOptions"
              :on-select="onSelectDegree"
              :selected-value="schoolData.degreeId"
              :placeholder="this.$t('degree')" />

          <span class="form-label">{{$t('school')}}</span>
          <SelectBox
              class="select-box"
              :full-width="true"
              :options="schoolOptions"
              :on-select="onSelectSchool"
              :selected-value="schoolData.schooleId"
              :placeholder="this.$t('school')"
              :search-enabled="true"
          />

          <div class="double-form">
            <div class="w-100">
              <span class="form-label">{{$t('start_date')}}</span>
              <input type="date" class="form-input" :placeholder="this.$t('start_date')" @input="handleAddStartDate" />
            </div>

            <div class="w-100">
              <span class="form-label">{{$t('finish_date')}}</span>
              <input type="date" class="form-input" :placeholder="this.$t('finish_date')" @input="handleAddEndDate" />
            </div>
          </div>

          <div class="checkbox no-text-select" @click="toggleDefault">
            <img src="@/assets/images/checkbox.svg" v-if="schoolData.default === 0" />
            <img src="@/assets/images/checkboxChecked.svg"  v-if="schoolData.default === 1"/>
            <span>{{$t('default')}}</span>
          </div>
        </div>

        <div class="btn-div2">
          <div></div>
          <div class="action-button" @click="onAddSchool">
            <span v-if="!isLoading">{{$t('save_button')}}</span>
            <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>

        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>

      <div class="modal-content" v-if="page === 3">
        <div class="modal-header">
          <img src="@/assets/images/arrowLeft.svg" @click="page = 1" />
          <span class="modal-header-txt">{{$t('edit_school')}}</span>
        </div>

        <div class="school-form">
          <span class="form-label">{{$t('degree')}}</span>
          <SelectBox
              class="select-box"
              :full-width="true"
              :options="degreeOptions"
              :on-select="onSelectDegree"
              :selected-value="editData.degree.id"
              :placeholder="this.$t('degree')" />

          <span class="form-label">{{$t('school')}}</span>
          <SelectBox
              class="select-box"
              :full-width="true"
              :options="schoolOptions"
              :on-select="onSelectSchool"
              :selected-value="editData.schoole.id"
              :placeholder="this.$t('school')" />

          <div class="double-form">
            <div class="w-100">
              <span class="form-label">{{$t('start_date')}}</span>
              <input type="date"  class="form-input" :placeholder="this.$t('start_date')" :value="editData.startAt" @input="handleEditStartDate"/>
            </div>

            <div class="w-100">
              <span class="form-label">{{$t('finish_date')}}</span>
              <input type="date" class="form-input" :placeholder="this.$t('finish_date')" :value="editData.finishAt" @input="handleEditEndDate" />
            </div>
          </div>

          <div class="checkbox no-text-select" @click="toggleEditDefault">
            <img src="@/assets/images/checkbox.svg" v-if="editData.default === 0 || editData.default === false" />
            <img src="@/assets/images/checkboxChecked.svg"  v-if="editData.default === 1 || editData.default === true" />
            <span>{{$t('default')}}</span>
          </div>
        </div>

        <div class="btn-div2">
          <div></div>
          <div class="action-button" @click="onUpdateSchool">
            <span v-if="!isLoading">{{$t('save_button')}}</span>
            <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>

        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>

    </div>
  </Transition>
</template>

<script>
import expertService from "@/service/expertService";

import {VueSpinnerIos} from 'vue3-spinners'
import SelectBox from "@/components/SelectBox.vue";
import commonService from "@/service/commonService";
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    SelectBox,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    expertId: {
      type: String,
      default: ""
    },
    educationData: {
      type: Object,
      default: []
    },
    refreshCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      editId: "",
      schoolOptions: [],
      degreeOptions: [],
      schoolData: {
        degreeId: "",
        schooleId: "",
        startAt: new Date(),
        finishAt: new Date(),
        default: 0,
      },
      editData: {
        degreeId: "",
        schooleId: "",
        startAt: new Date(),
        finishAt: new Date(),
        default: 0,
      }
    };
  },
  mounted() {
    commonService.getSchools().then((response) => {
      response.data.data.school.forEach((item) => {
        this.schoolOptions.push({
          value: item.id,
          text: item.name
        });
      });
    });
    commonService.getDegrees().then((response) => {
      response.data.data.degree.forEach((item) => {
        this.degreeOptions.push({
          value: item.id,
          text: item.name
        });
      });
    });
  },
  methods: {
    onUpdate() {
      this.isLoading = true;
    },
    toggleDefault() {
      if (this.schoolData.default === 0) {
        this.schoolData.default = 1;
      } else {
        this.schoolData.default = 0;
      }
    },
    toggleEditDefault() {
      if (this.editData.default === 0) {
        this.editData.default = 1;
      } else {
        this.editData.default = 0;
      }
    },
    onSelectDegree(value) {
      this.schoolData.degreeId = value;
    },
    onSelectSchool(value) {
      this.schoolData.schooleId = value;
    },
    editSchool(id) {
      this.page = 3;
      this.editId = id;
      const tempData = this.educationData.find(item => item.educationId === id);
      this.editData = {
        educationId: tempData.educationId,
        degree: {
          id: tempData.degree.id,
          name: tempData.degree.name
        },
        schoole: {
          id: tempData.schoole.id,
          name: tempData.schoole.name
        },
        startAt: this.convertDmyToYMD(tempData.startAt),
        finishAt: this.convertDmyToYMD(tempData.finishAt),
        default: tempData.default,
      }
    },
    onAddSchool() {
      this.isLoading = true;

      const tempData = {
        expertId: this.expertId,
        degreeId: this.schoolData.degreeId,
        schooleId: this.schoolData.schooleId,
        startAt: this.convertYMDToDmy(this.schoolData.startAt),
        finishAt: this.convertYMDToDmy(this.schoolData.finishAt),
        default: 0,
      }

      expertService
        .addSchool(tempData)
        .then(() => {
          this.isLoading = false;

          this.$snackbar.add({
            text: "School added successfully",
            type: "success",
          });

          this.refreshCallback();
          this.page = 1;
          this.schoolData = {
            degreeId: "",
            schooleId: "",
            startAt: new Date(),
            finishAt: new Date(),
            default: 0,
          };
        })
        .catch((error) => {
          this.isLoading = false;
          handleErrorResponse(error, this.$snackbar);
        });
    },
    onUpdateSchool() {
      this.isLoading = true;

      const tempData = {
        educationId: this.editId,
        expertId: this.expertId,
        degreeId: this.editData.degree.id,
        schooleId: this.editData.schoole.id,
        startAt: this.convertYMDToDmy(this.editData.startAt),
        finishAt: this.convertYMDToDmy(this.editData.finishAt),
        default: this.editData.default,
      }

      expertService
          .updateSchool(tempData)
          .then(() => {
            this.isLoading = false;

            this.$snackbar.add({
              text: "School updated successfully",
              type: "success",
            });

            this.page = 1;
            this.refreshCallback();
          })
          .catch((error) => {
            this.isLoading = false;
            handleErrorResponse(error, this.$snackbar);
          });
    },
    convertDmyToYMD(dateString) {
      const [day, month, year] = dateString.split('.');

      const dateObject = new Date(`${year}-${month}-${day}`);

      const yearResult = dateObject.getFullYear();
      const monthResult = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const dayResult = String(dateObject.getDate()).padStart(2, '0');

      return `${yearResult}-${monthResult}-${dayResult}`;
    },
    convertYMDToDmy(dateString) {
      const dateObject = new Date(dateString);

      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const year = dateObject.getFullYear();

      return `${day}.${month}.${year}`;
    },
    handleAddStartDate(event) {
      this.schoolData.startAt = event.target.value;
    },
    handleAddEndDate(event) {
      this.schoolData.finishAt = event.target.value;
    },
    handleEditStartDate(event) {
      this.editData.startAt = event.target.value;
    },
    handleEditEndDate(event) {
      this.editData.finishAt = event.target.value;
    }
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.action-button{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}

.action-button span {
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.btn-div2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}

.info-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.info-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.info-card .info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.info-card img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.info-card .title {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
}

.info-card .description {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #667085;
}

.info-card .date {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #667085;
}

.info-list {
  height: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.edit-icon {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #3DA33E;
  border-radius: 12px;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  color: white;
  width: fit-content;
  margin-top: 15px;
  margin-bottom: 15px;
}

.school-form {
  height: 100%;
}

.double-form{
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
}

.select-box {
  margin-bottom: 10px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  font-size: 14px;
}
</style>
