<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">

        <div class="modal-header">
          <span class="modal-header-txt">{{$t('update_session')}}</span>
        </div>

        <div class="session-form">
          <span class="form-label">{{$t('meeting_link')}}</span>
          <input class="form-input" :placeholder="$t('meeting_link')" v-model="meetUrl" @blur="formatMeetUrl" />
        </div>


        <div class="btn-div2">
          <div></div>
          <div class="action-button" @click="handleSave">
            <span v-if="!isLoading">{{$t('save_button')}}</span>
            <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>
        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import expertService from "@/service/expertService";

import {
  VueSpinnerIos
} from 'vue3-spinners'
import SelectBox from "@/components/SelectBox.vue";
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    SelectBox,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    editSessionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      meetUrl: "",
    };
  },
  watch: {
    editSessionData: {
      handler: function (val) {
        if (val) {
          this.meetUrl = val.meetUrl;
        }
      },
      deep: true,
    },
  },
  methods: {
    // URL formatını düzeltme metodu
    formatMeetUrl() {
      if (this.meetUrl && this.meetUrl.trim() !== '') {
        // URL'in başında https:// veya http:// yoksa https:// ekle
        if (!this.meetUrl.match(/^https?:\/\//i)) {
          this.meetUrl = 'https://' + this.meetUrl;
        }
      }
    },
    handleSave() {
      if (!this.editSessionData) {
        return;
      }

      // Kaydetmeden önce URL formatını kontrol et
      this.formatMeetUrl();

      this.isLoading = true;

      const data = {
        expertId: this.editSessionData.expert.expertId,
        expertReservationId: this.editSessionData.expertReservationId,
        userId: this.editSessionData.userId,
        expertSlotTimeId: this.editSessionData.slotTimes?.uuid,
        meetUrl: this.meetUrl,
        date: this.$moment(this.editSessionData.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY'),
      };

      expertService.updateReservation(data).then((res) => {
        this.$snackbar.add({
          text: this.$t('session_updated_success'),
          type: "success",
        });
        this.isLoading = false;
        this.onHide();
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar)
        this.isLoading = false;
      });
    },
    formatDate(date) {
      const dateObject = new Date(date);

      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const year = dateObject.getFullYear();

      return `${day}.${month}.${year}`;
    }
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.action-button{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}

.action-button span {
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.btn-div2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}


.double-form{
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
}

.session-form {
  height: 100%;
}

.select-box {
  margin-bottom: 10px;
}
</style>
