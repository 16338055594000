import axios from './providers/axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const blogService = {
    getBlogs(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/blog/store?page=${page}&limit=20${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/blog/store?page=${page}&limit=20`);
        }
    },
    getBlogList() {
        return axios.get(`/v1/admin/blog/store?list=1`);
    },
    getBlogDetail(id, lang = 'tr') {
        return axios.get(`/v1/admin/blog/getFromId/${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    addBlog(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);


        return axios.post('/v1/admin/blog/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    updateBlog(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/blog/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    deleteBlog(id) {
        return axios.delete(`/v1/admin/blog/delete/${id}`);
    },

    getLogs(lang){
        return axios.get(`/v1/admin/blog/getLogs?/list=1`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    },
    getDetailLogs(lang,id){
        return axios.get(`/v1/admin/blog/getLogById/${id}`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    }
}

export default blogService;
