<template>
  <div
      class="branch-structure"
      v-if="branchData?.trigger"
      :key="branchData?.trigger.id"
  >
    <div class="branch-row">
      <div
          class="left-spacer"
          v-for="index in getIndentationLevel()"
      >
        <div class="connection-line" />
      </div>

      <FlowComponent
          :data="branchData?.trigger"
          :selected="selectedComponentId === branchData?.trigger.id"
          :on-click="() => handleComponentClick(branchData?.trigger.id)"
          :on-delete="() => handleComponentDelete(branchData?.trigger.id)"
          :indentation-level="nestingLevel + 1"
      />
    </div>
  </div>

  <div
      class="branch-structure"
      v-if="branchData?.ifComponents?.length > 0"
      v-for="component in branchData?.ifComponents"
      :key="component.id"
  >
    <div class="branch-row">
      <div
          class="left-spacer"
          v-for="index in getIndentationLevel()"
      >
        <div class="connection-line" />
      </div>
      <FlowComponent
          :data="component"
          :selected="selectedComponentId === component.id"
          :on-click="() => handleComponentClick(component.id)"
          :on-delete="() => handleComponentDelete(component.id)"
          :indentation-level="nestingLevel + 1"
      />
    </div>

    <BranchRenderer
        :handle-component-click="(cid) => handleComponentClick(cid)"
        :handle-component-delete="(cid) => handleComponentDelete(cid)"
        :selected-component-id="selectedComponentId"
        :branch-data="component"
        :handle-branch-component-add="(compData, side) => handleBranchComponentAdd(compData, side)"
        :nesting-level="nestingLevel + 1"
    />
  </div>

  <div class="add-wrapper">
    <div
        v-for="index in getIndentationLevel()"
        class="left-spacer"
        v-if="branchData.type === 'ifCondition'"
    >
      <div class="connection-line" />
    </div>

    <AddComponentButton
        v-if="branchData.type === 'ifCondition'"
        :on-click="() => handleBranchComponentAdd(branchData, 'if')"
    />
  </div>

  <div
      class="branch-structure"
      v-if="branchData?.elseComponents?.length > 0"
      v-for="(component, index) in branchData?.elseComponents"
      :key="component.id"
  >
    <div class="branch-row">
      <div
          class="left-spacer"
          v-for="index in getIndentationLevel()"
      >
        <div class="connection-line" />
      </div>
      <FlowComponent
          :data="component"
          :show-else-block="index === 0"
          :selected="selectedComponentId === component.id"
          :on-click="() => handleComponentClick(component.id)"
          :on-delete="() => handleComponentDelete(component.id)"
          :indentation-level="nestingLevel + 1"
      />
    </div>

    <BranchRenderer
        :handle-component-click="(cid) => handleComponentClick(cid)"
        :handle-component-delete="(cid) => handleComponentDelete(cid)"
        :selected-component-id="selectedComponentId"
        :branch-data="component"
        :handle-branch-component-add="(compData, side) => handleBranchComponentAdd(compData, side)"
        :nesting-level="nestingLevel + 1"
    />
  </div>

  <div class="add-wrapper">
    <div
        v-for="index in getIndentationLevel()"
        class="left-spacer"
        v-if="branchData.type === 'ifCondition'"
    >
      <div class="connection-line" />
    </div>

    <AddElseButton
        v-if="branchData.type === 'ifCondition' && (!branchData.elseComponents || branchData.elseComponents?.length === 0)"
        :on-click="() => handleBranchComponentAdd(branchData, 'else')"
    />

    <AddComponentButton
        v-else-if="branchData.type === 'ifCondition'"
        :on-click="() => handleBranchComponentAdd(branchData, 'else')"
    />
  </div>
</template>

<script>
import FlowComponent from "@/components/user-flow/FlowComponent.vue";
import AddComponentButton from "@/components/user-flow/AddComponentButton.vue";
import AddElseButton from "@/components/user-flow/AddElseButton.vue";

export default {
  name: 'BranchRenderer',
  components: {AddElseButton, AddComponentButton, FlowComponent},
  props: {
    branchData: {
      type: Object,
      required: true,
    },
    selectedComponentId: {
      type: Number,
      required: true,
    },
    handleComponentClick: {
      type: Function,
      default: () => {},
    },
    handleComponentDelete: {
      type: Function,
      default: () => {},
    },
    handleBranchComponentAdd: {
      type: Function,
      default: () => {},
    },
    nestingLevel: {
      type: Number,
      default: 0
    },
  },
  methods: {
    getIndentationLevel() {
      return this.nestingLevel;
    },
  },
}
</script>

<style scoped>
.branch-structure {
  position: relative;
  display: flex;
  flex-direction: column;
}

.branch-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.left-spacer {
  width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.connection-line {
  width: 2px;
  height: 100%;
  background-color: #EAECF0;
}

.add-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}
</style>
