import axios from 'axios';

const baseDomain = import.meta.env.VITE_API_URL;

const instance = axios.create({
    baseURL: baseDomain,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Accept-Language": "tr",
    },
});

// Request interceptor for API calls
instance.interceptors.request.use(
    (config) => {
        const clientToken = localStorage.getItem('clientToken');
        const authToken = localStorage.getItem('authToken');

        if (authToken) {
            config.headers.Authorization = `Bearer ${authToken}`;
        } else if (clientToken) {
            config.headers.Authorization = `Bearer ${clientToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor for API calls
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        // Check if error is due to unauthorized access
        if (error.response?.status === 403) {

            // You might want to clear other auth-related data from localStorage here

            // Clear any auth-related data from memory if needed
            // For example: store.dispatch('auth/clearAuthState') if using Vuex

            // Redirect to unauthorized page
            window.location.href = '/401';
        }

        if (error.response?.status === 401) {
            localStorage.removeItem('authToken');
            window.location.href = '/login';
        }


        return Promise.reject(error);
    }
);

export default instance;
