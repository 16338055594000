<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-top">
          <div class="modal-header">
            <span class="modal-header-txt">{{$t('edit_specialties')}}</span>
          </div>


          <span class="speciality-txt mt-3">{{$t('search_speciality')}}</span>

          <multiselect
              class="mt-3"
              v-model="tagIds"
              :options="tagList"
              track-by="id"
              label="name"
              :multiple="true"
              :aria-placeholder="$t('search_speciality')"
          ></multiselect>

        </div>
        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>


        <div class="modal-bottom">
          <button class="dark-button" @click="saveTags" :disabled="saveLoading">
            <VueSpinnerIos v-if="saveLoading" />
            <span v-else>{{$t('save')}}</span>
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import expertService from "@/service/expertService";

import {
  VueSpinnerIos
} from 'vue3-spinners'
import {handleErrorResponse} from "@/utils/utils";
import commonService from "@/service/commonService";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    expertId: {
      type: String,
      default: ""
    },
    tagData: {
      type: Object,
      default: [],
    },
    refreshCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      saveLoading: false,
      tagList: [],
      tagIds: []
    };
  },
  mounted() {
    this.getTags();
  },
  methods: {
    getTags() {
      commonService.getTags().then((res) => {
        this.tagList = res.data.data?.tag?.map((item) => {
          return {
            id: item.id,
            name: item.name
          };
        });
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      });
    },
    saveTags() {
      this.saveLoading = true;

      const tagIds = this.tagIds.map((item) => item.id);

      expertService.updateExpertTags(this.expertId, tagIds).then(() => {
        this.saveLoading = false;
        this.refreshCallback();
        this.onHide();
      }).catch((error) => {
        this.saveLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    }
  },
  watch: {
    tagData: {
      handler: function (val) {
        this.tagIds = val.map((item) => {
          return {
            id: item.tagId,
            name: item.name
          };
        });
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}

.cross{
  padding: 8px;
}

.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.modal-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}


.modal-top {
  display: flex;
  flex-direction: column;
  height: 100%;
}

</style>
