<template>
  <div class="card-wrapper" :class="disabled && 'disabled'">
    <div class="question-header">
      <div class="left-wrapper">
        <div class="question-number-wrapper">
          <span class="question-number">{{ index + 1 }}.</span>
        </div>

        <span class="question-type-text">{{ $t('step') }}: </span>

        <div class="question-dropdown" v-click-outside="typeDropdownOutsideClick">
          <div class="dropdown-button" :class="{'active': typeDropdownVisible}" @click="toggleTypeDropdown">
            <img :src="onboardingTypes.find((item) => item.id === selectedTypeID).icon"/>
            <span>{{ onboardingTypes.find((item) => item.id === selectedTypeID).name }}</span>
            <img src="@/assets/images/arrowdown.svg" class="arrow"/>
          </div>

          <div class="dropdown-items" v-if="typeDropdownVisible">
            <div v-for="item in onboardingTypes" class="dropdown-item" @click="() => selectType(item.id)">
              <img :src="item.icon"/>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="right-wrapper">
        <img src="@/assets/images/arrow-up.svg" @click="onChangePosition('up', question.order)"/>
        <img src="@/assets/images/arrow-down.svg" @click="onChangePosition('down', question.order)" />
        <img src="@/assets/images/copyOutline.svg" @click="onDuplicateQuestion(question.order)" />
        <img src="@/assets/images/trashOutline.svg" @click="handleQuestionDelete" />
      </div>

    </div>

    <Transition name="slide-up">
      <div v-if="expanded" class="question-body">
        <div v-if="selectedTypeID === 1 || selectedTypeID === 2 || selectedTypeID === 4 || selectedTypeID === 5">
          <span class="title-input">{{ $t('title') }}</span>
          <textarea
              class="form-input"
              :disabled="disabled"
              style="width: 100%"
              :placeholder="this.$t('title')"
              v-model="question.name" v-textarea-autoresize></textarea>

          <span class="title-input mt-2">{{ $t('description') }}</span>
          <textarea
              class="form-input"
              :disabled="disabled"
              style="width: 100%"
              :placeholder="this.$t('description')"
              v-model="question.description" v-textarea-autoresize></textarea>


          <div class="answers-body">
            <h4>{{ $t('answers') }}</h4>

            <draggable
                v-model="question.content.options"
                :disabled="disabled"
                item-key="id"
            >
              <template #item="{element}">
                <div class="answer-input-wrapper">
                  <div class="answer-input-row">
                    <img src="@/assets/images/drag.svg"/>
                    <IconDrop
                        :selected-file="element.file?.attachUrl"
                        :on-file-selected="(file) => handleFileSelected(file, element)"
                        :not-binary="true"
                    />
                    <input
                        class="answer-input"
                        :placeholder="$t('add_your_answer_here')"
                        :disabled="disabled"
                        v-model="element.name"
                    />
                    <img
                        src="@/assets/images/trashOutline.svg"
                        @click="() => handleDelete(element.id)"
                    />
                  </div>
                </div>
              </template>
            </draggable>

            <button class="add-answer-button no-text-select" @click="addAnswer" v-if="!disabled">
              <img src="@/assets/images/plusBlue.svg"/>
              <span>{{ $t('add_an_answer') }}</span>
            </button>
          </div>
        </div>

        <div v-if="selectedTypeID === 3">
          <span class="title-input">{{ $t('title') }}</span>
          <textarea class="form-input" :disabled="disabled" style="width: 100%" :placeholder="this.$t('title')"
                    v-model="question.name" v-textarea-autoresize></textarea>

          <span class="title-input mt-2">{{ $t('description') }}</span>
          <textarea class="form-input" :disabled="disabled" style="width: 100%" :placeholder="this.$t('description')"
                    v-model="question.description" v-textarea-autoresize></textarea>

          <span class="title-input mt-2">{{ $t('textarea_placeholder') }}</span>
          <textarea class="form-input" :disabled="disabled" style="width: 100%"
                    :placeholder="this.$t('textarea_placeholder')" v-model="question.shortDescription"
                    v-textarea-autoresize></textarea>
        </div>
      </div>
    </Transition>
  </div>
</template>


<script>
import checkCircle from "@/assets/images/checkCircle.svg";
import taskOutline from "@/assets/images/taskOutline.svg";
import editPenOutline from "@/assets/images/editPenOutline.svg";
import educationOutline from "@/assets/images/educationOutline.svg";
import usersOutline from "@/assets/images/usersOutline.svg";
import Switch from "@/components/Switch.vue";
import SelectBox from "@/components/SelectBox.vue";
import draggable from "vuedraggable";
import IconDrop from "@/components/IconDrop.vue";

export default {
  name: 'OnboardQuestion',
  components: {IconDrop, draggable, SelectBox, Switch},
  props: {
    index: {
      type: Number
    },

    onDelete: {
      type: Function,
      default: () => {}
    },

    onDuplicateQuestion: {
      type: Function,
      default: () => {}
    },

    question: {
      type: Object,
      required: true,
    },

    computed: {
      question() {
        return this.questionData;
      }
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    onUpdate: {
      type: Function,
    },

    onChangePosition: {
      type: Function,
      default: () => {}
    },

  },
  data() {
    return {
      typeDropdownVisible: false,
      selectedTypeID: 1,
      questionSettingsVisible: false,
      onboardingTypes: [
        {
          id: 1,
          name: this.$t('single_choice'),
          icon: checkCircle,
          data: {
            name: '',
            description: '',
            shortDescription: '',
            content: {
              type:'single',
              options: [
                {
                  id: 0,
                  name: '',
                },
              ],
            },
          }
        },
        {
          id: 2,
          name: this.$t('multiple_choice'),
          icon: taskOutline,
          data: {
            name: '',
            description: '',
            shortDescription: '',
            content: {
              type:'multiple',
              options: [
                {
                  id: 0,
                  name: '',
                },
              ],
            },
          }
        },
        {
          id: 3,
          name: this.$t('text_question'),
          icon: editPenOutline,
          data: {
            name: '',
            description: '',
            shortDescription: '',
            content: {
              type:'text',
              settings:{
                placeholder:'',
                characterLimit:{min:'1',max:'10000'},
              }
            },
          }
        },
        {
          id: 4,
          name: this.$t('education'),
          icon: educationOutline,
          data: {
            name: '',
            description: '',
            shortDescription: '',
            content: {
              type:'education',
              options: [
                {
                  id: 1,
                  name: 'İlkokul',
                },
                {
                  id: 2,
                  name: 'Ortaokul',
                },
                {
                  id: 3,
                  name: 'Lise',
                },
                {
                  id: 4,
                  name: 'Üniversite',
                },
                {
                  id: 5,
                  name: 'Yüksek Lisans',
                },
                {
                  id: 6,
                  name: 'Doktora',
                },
              ],
            },
          }
        },
        {
          id: 5,
          name: this.$t('gender'),
          icon: usersOutline,
          order: 1,

          data: {
            name: '',
            description: '',
            shortDescription: '',
            content: {
              type:'gender',
              options: [
                {
                  id: 2,
                  name: 'Kadın',
                },
                {
                  id: 1,
                  name: 'Erkek',
                },
                {
                  id: 3,
                  name: 'Diğer',
                },
              ],
            },
          }
        }
      ],
      expanded: true,
    };
  },
  watch: {
    question: {
      handler: function (newVal, oldVal) {
        this.selectedTypeID = this.onboardingTypes.find((item) => item.data.content.type === newVal.content.type).id
      },
      deep: true
    },

    questionData: {
      handler: function (newVal, oldVal) {
        this.questionUpdate(newVal);
      },
      deep: true
    },

  },
  created() {
    //this.onboardingData = this.question;

    //this.selectedTypeID = this.onboardingTypes.find((item) => item.data.content.type === this.question.content.type).id

  },
  methods: {
    handleFileSelected(file, element) {
      element.attach = file;
      element.attachUrl = "";
    },

    selectType(id) {
      this.typeDropdownVisible = false;
      this.question.content = this.onboardingTypes.find((item) => item.id === id).data.content;
      this.selectedTypeID = id;
      this.$emit('updateType', id);
    },
    typeDropdownOutsideClick() {
      this.typeDropdownVisible = false;
    },
    toggleTypeDropdown() {
      if (this.disabled) return;
      this.typeDropdownVisible = !this.typeDropdownVisible;
    },
    addAnswer() {
      console.log(this.question.content)
      this.question.content.options.push({
        id: this.question.content.options.length + 1,
        name: ""
      });
    },
    handleDelete(id) {
      if (this.disabled) return;
      if (this.question.content.options.length === 1) {
        this.question.content.options[0] = "";
      } else {
        this.question.content.options = this.question.content.options.filter(answer => answer.id !== id);
      }
    },
    toggleCard() {
      this.expanded = !this.expanded;
    },

    handleQuestionDelete() {
      this.onDelete(this.question.order);
    },

    hideQuestionSettings() {
      this.questionSettingsVisible = false;
    },
  },
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-wrapper.disabled {
  opacity: 0.7;
}

.question-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.question-number-wrapper {
  background-color: #040C2D;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-number {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #FFFFFF;
}

.question-body {
  padding: 15px 0;
}

.left-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.right-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px
}

.question-dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
  white-space: nowrap;
}

.question-dropdown img {
  height: 16px;
  width: 16px;
}

.question-dropdown span {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-button:hover, .dropdown-button.active {
  background-color: #F2F4F7;
}

.dropdown-button .arrow {
  transition: transform 0.1s linear;
}

.dropdown-button.active .arrow {
  transform: rotate(180deg);
}

.dropdown-items {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 99;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-item:hover {
  background-color: #F5F7FA;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  background-color: transparent;
  color: #040C2D;
  display: block;
  margin-bottom: 10px;
}

.answers-body {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

h4 {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
  margin: 0;
  padding: 0;
}

.add-answer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 8px;
  color: #4A68F8;
  margin-top: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  width: fit-content;
  transition: background-color 0.2s ease-in-out;
}

.add-answer-button:hover {
  background-color: #f3f4ff;
}

.answer-input {
  width: 300px;
  height: 100%;
  border: none;
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #F5F5F5;
  color: #282A36;
  padding: 15px;
  border-radius: 8px;
}

.answer-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.answer-input-row .input-row {
  width: 100%;
  box-sizing: border-box;
}

.answer-input-row span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
}
</style>
