import axios from './providers/axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const sliderService = {
    getSliders(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/slider/store?page=${page}&limit=20${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/slider/store?page=${page}&limit=20`);
        }
    },
    getSliderDetail(id, lang = 'tr') {
        return axios.get(`/v1/admin/slider/getFromId/${id}`, {
            headers: {
                "Accept-Language": lang,
            }
        });
    },
    addSlider(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);
        return axios.post(`/v1/admin/slider/add`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    updateSlider(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);
        return axios.post(`/v1/admin/slider/update`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    deleteSlider(id) {
        return axios.delete(`/v1/admin/slider/delete/${id}`);
    },
    getLogs(lang){
        return axios.get(`/v1/admin/slider/getLogs?/list=1`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    },
    getDetailLogs(lang,id){
        return axios.get(`/v1/admin/slider/getLogById/${id}`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    }
}

export default sliderService;
