<template>
  <Layout>
    <div class="row">
      <div class="col-sm">
        <div class="card" v-if="!isLoading">
          <div class="card-header">
            <div class="icon-title">
              <div class="icon-holder">
                <img src="../assets/images/usersOutline.svg" />
              </div>
              <span>{{ $t('total_users') }}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-stats">
              <span class="chart-value">{{ data.totalUsers.data.total }}</span>
              <img src="../assets/images/upTrend.svg" alt="Up Trend"/>
              <span class="chart-percent up">{{ data.totalUsers.data.percentage }}%</span>
            </div>
            <BarChart :data="data.totalUsers.list" :suffix="$t('users')" bar-color="#FAD9C6" bar-color-active="#F09460" />
          </div>
        </div>


        <free-style-shimmer
            :is-loading="isLoading"
            height="240px"
            width="100%"
            border-radius="20px"
            color="#EAECF0"
        />
      </div>
      <div class="col-sm">
        <div class="card" v-if="!isLoading">
          <div class="card-header">
            <div class="icon-title">
              <div class="icon-holder">
                <img src="../assets/images/userTick.svg" />
              </div>
              <span>{{$t('active_users')}}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-stats">
              <span class="chart-value">{{ data.totalActiveUsers.data.total }}</span>
              <img src="../assets/images/upTrend.svg" alt="Up Trend"/>
              <span class="chart-percent up">{{ data.totalActiveUsers.data.percentage }}%</span>
            </div>
            <BarChart :data="data.totalActiveUsers.list" :suffix="$t('users')" bar-color="#D1E7D2" bar-color-active="#3DA33E" />
          </div>
        </div>

        <free-style-shimmer
            :is-loading="isLoading"
            height="240px"
            width="100%"
            border-radius="20px"
            color="#EAECF0"
        />
      </div>
      <div class="col-sm">
        <div class="card" v-if="!isLoading">
          <div class="card-header">
            <div class="icon-title">
              <div class="icon-holder">
                <img src="../assets/images/therapies.svg" />
              </div>
              <span>{{ $t('therapies_assigned') }}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-stats">
              <span class="chart-value">{{ data.totalTherapiesUserAssigned.data.total }}</span>
              <img src="../assets/images/downTrend.svg" alt="Down Trend"/>
              <span class="chart-percent down">{{ data.totalTherapiesUserAssigned.data.percentage }}%</span>
            </div>
            <BarChart :data="data.totalTherapiesUserAssigned.list" :suffix="$t('programs')" bar-color="#EBEDFB" bar-color-active="#4A68F8" />
          </div>
        </div>

        <free-style-shimmer
            :is-loading="isLoading"
            height="240px"
            width="100%"
            border-radius="20px"
            color="#EAECF0"
        />
      </div>
    </div>
    <div class="table-holder">
      <h3 class="table-title">{{$t('all_users')}}</h3>
      <UserTable :add-new-user="showAddUserModal" :loading="isLoading" :users="tableData.data" />
      <div class="pagination-align-center">
        <Pagination :total-items="tableData.total" :per-page="20" :current-page="currentPage" :on-page-change="handlePage"/>
      </div>
      <AddUserModal :visible="addUserModal" :on-hide="hideAddUserModal" :refresh-callback="() => handlePage(1)" :role-list="roleList"/>
    </div>
  </Layout>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import Layout from "@/components/layouts/Layout.vue";
import AvatarArray from "@/components/AvatarArray.vue";
import UserTable from "@/components/tables/User/UserTable.vue";
import AddUserModal from "@/components/modals/UserModal.vue";
import userService from "@/service/userService";
import Pagination from "@/components/Pagination.vue";
import CompanyTable from "@/components/tables/Company/CompanyTable.vue";
import {handleErrorResponse} from "@/utils/utils";
import roleService from "@/service/roleService";
export default {
  name: 'Home',
  components: {
    CompanyTable,
    Pagination,
    AddUserModal,
    UserTable,
    AvatarArray,
    Layout,
    BarChart,
  },
  data() {
    return {
      isLoading: true,
      addUserModal: false,
      data: {},
      tableData: {},
      currentPage: 1,
      roleList: [],
    }
  },
  watch: {
    $route() {
      this.handlePage(1);
    }
  },
  methods: {
    showAddUserModal() {
      this.addUserModal = true;
    },
    hideAddUserModal() {
      this.addUserModal = false;
    },
    handlePage(page) {
      if (this.isLoading && this.currentPage !== 1) return;
      this.isLoading = true;
      this.currentPage = page;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }

      if (routeQuery.addedDate) {
        tempFilters.push({
          key: 'addedDate',
          value: routeQuery.addedDate
        });
      }

      if (routeQuery.addedDateEnd) {
        tempFilters.push({
          key: 'addedDateEnd',
          value: routeQuery.addedDateEnd
        });
      }

      if (routeQuery.programNumberMin) {
        tempFilters.push({
          key: 'programNumberMin',
          value: routeQuery.programNumberMin
        });
      }

      if (routeQuery.programNumberMax) {
        tempFilters.push({
          key: 'programNumberMax',
          value: routeQuery.programNumberMax
        });
      }

      if (routeQuery.status) {
        if(routeQuery.status === "1") {
          tempFilters.push({
            key: 'status',
            value: "1"

          })}
        else if(routeQuery.status==="0"){
          tempFilters.push({
            key: 'status',
            value: "0"
          })
        }
      }

      userService.getUsers(page, tempFilters).then(response => {
        this.data = response.data.data;
        this.tableData = response.data.data.store;
        this.isLoading = false;
      })
    }
  },
  mounted() {
    this.isLoading = true;
    const user = JSON.parse(localStorage.getItem('user'));
    roleService.getStoreByCompanyRole(user.companyId).then(response => {
      this.roleList = response.data.data;
    }).catch(err => {
      handleErrorResponse(err, this.$snackbar);
    });
  }
}
</script>

<style scoped>
.icon-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.icon-title span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 18px;
  white-space: nowrap
}

.card {
  margin-bottom: 10px;
}

.icon-holder {
  background-color: #EAECF0;
  border-radius: 8px;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.icon-holder img {
  cursor: default;
}

.chart-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.chart-value {
  font-family: 'euclid_medium', sans-serif;
  font-size: 28px;
  margin-right: 10px;
}

.chart-percent {
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 12px;
  margin-left: 5px;
}

.chart-percent.up {
  color: #3DA33E;
}

.chart-percent.down {
  color: #F09460;
}

.table-holder {
  margin-top: 20px;
}

.table-title {
  font-family: 'euclid_medium', sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
  position: sticky;
}
</style>
