<template>
  <div class="session-wrapper">
    <div class="session-header" :class="sessionType">
      <img :src="sessionData?.avatarUrl" class="avatar"/>
      <div class="header-info">
        <div v-if="sessionType === 'upcoming'" class="header-tag upcoming">{{$t('upcoming')}}</div>
        <div v-if="sessionType === 'pending'" class="header-tag pending">{{$t('pending')}}</div>
        <div v-if="sessionType === 'done'" class="header-tag done">{{$t('completed')}}</div>
        <div v-if="sessionType === 'scheduled'" class="header-tag scheduled">{{$t('scheduled')}}</div>
        <div v-if="sessionType === 'cancelled'" class="header-tag cancelled">{{$t('cancelled')}}</div>
      </div>
    </div>
    <div class="session-body">
      <span class="name">{{sessionData?.user?.name}} {{sessionData?.user?.surname}}</span>
      <span class="title">{{sessionData?.user?.title || sessionData?.user?.email}}</span>

      <div class="notes-wrapper" v-if="sessionData.note && sessionData.note !== ''">
        <span class="session-info-title">{{$t('notes')}}: </span>
        <span class="session-info-text">{{sessionData.note}}</span>
      </div>

      <div class="card-footer">
        <div class="session-info">
          <div class="info-card">
            <div class="icon-bg">
              <img src="@/assets/images/calendarFill.svg" />
            </div>
            <div class="session-info-row">
              <span class="session-info-title">{{this.$moment(sessionData?.date).format('DD.MM.YYYY')}}</span>
              <span class="session-info-text">{{$t('session_date')}}</span>
            </div>
          </div>

          <div class="info-card">
            <div class="icon-bg">
              <img src="@/assets/images/timer.svg" />
            </div>
            <div class="session-info-row">
              <span class="session-info-title">{{ this.$moment(sessionData.slotTimes?.startTime, 'HH:mm:ss').format('HH:mm') }} - {{ this.$moment(sessionData.slotTimes?.endTime, 'HH:mm:ss').format('HH:mm')  }}</span>
              <span class="session-info-text">{{$t('time')}}</span>
            </div>
          </div>

          <div class="info-card" v-if="sessionType === 'upcoming' && sessionData.meetUrl">
            <div class="icon-bg">
              <img src="@/assets/images/cameraFill.svg" />
            </div>
            <div class="session-info-row">
              <span class="session-info-title">{{$t('meeting_link')}}</span>
              <span class="session-info-link">{{sessionData.meetUrl}}</span>
            </div>
          </div>


          <div class="action-wrapper">

            <button
                v-if="sessionType !== 'cancelled' && sessionType !== 'done'"
                class="text-button"
                @click="editSession(sessionData)"
            >
              {{$t('edit')}}
            </button>

            <button
                class="primary-button"
                :class="!sessionData.meetUrl ? 'disabled' : ''"
                v-if="sessionType === 'upcoming'"
                :disabled="!sessionData.meetUrl"
                @click="handleJoin(sessionData.meetUrl)"
            >
              {{$t('join')}}
            </button>

            <button
                class="primary-button-outline"
                v-if="sessionType === 'pending'"
                @click="handleReject(sessionData.expertReservationId)"
            >
              {{$t('reject')}}
            </button>

            <button
                class="primary-button"
                v-if="sessionType === 'pending'"
                @click="handleAccept(sessionData.expertReservationId)"
            >
              {{$t('confirm')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sessionData: {
      type: Object,
      required: true
    },
    handleAccept: {
      type: Function,
      required: false
    },
    handleReject: {
      type: Function,
      required: false
    },
    editSession: {
      type: Function,
      required: false
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleJoin(meetUrl) {
      window.open(meetUrl, '_blank');
    }
  },
  computed: {
    sessionType() {
      if (this.sessionData.status === '0') {
        return 'pending';
      } else if (this.sessionData.status === '1') {
        return 'upcoming';
      } else if (this.sessionData.status === '2') {
        return 'scheduled';
      } else if (this.sessionData.status === '3') {
        return 'done';
      } else if (this.sessionData.status === '4') {
        return 'cancelled';
      }
    }
  }
}
</script>

<style scoped>
.session-wrapper {
  width: 728px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}
.session-header {
  position: relative;
  width: 100%;
  height: 80px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 15px;
  object-fit: cover;
  background-repeat: no-repeat;
}

.avatar {
  position: absolute;
  height: 80px;
  width: 80px;
  bottom: -30px;
  left: 20px;
  border-radius: 50%;
  border: 5px solid #FFFFFF;
}

.session-header.scheduled {
  background-color: #EDF0F6;
  background-image: url("../assets/images/scheduledHeader.svg");
}

.session-header.upcoming {
  background-color: #F8FDFB;
  background-image: url("../assets/images/upcomingHeader.svg");
  object-fit: cover;
  background-repeat: no-repeat;
}

.session-header.pending {
  background-color: #FEF5F1;
  background-image: url("../assets/images/pendingHeader.svg");
}

.session-header.done {
  background-color: #EBFFF9;
  background-image: url("../assets/images/completedHeader.svg");
}

.session-header.cancelled {
  background-color: #FAC6C6;
  background-image: url("../assets/images/cancelledHeader.svg");
}

.session-body {
  padding: 45px 15px 15px 15px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.name {
  font-family: 'euclid_medium', sans-serif;
  font-size: 16px;
  color: #101828;
}

.title {
  font-family: 'euclid_regular', sans-serif;
  font-size: 12px;
  color: #667085;
}

.header-info {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.header-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #FFFFFF;
  border-radius: 8px;
  padding: 2px 15px;
}

.header-tag.upcoming {
  background-color: #3DA33E;
}

.header-tag.pending {
  background-color: #F09460;
}

.header-tag.done {
  background-color: #3DA33E;
}

.header-tag.scheduled {
  background-color: #4A68F8;
}


.header-tag.cancelled {
  background-color: #F06060;
}



.session-info {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-top: 20px;
}

.info-card {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.info-card .icon-bg {
  background-color: #F5F5F5;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-bg img {
  cursor: default;
  height: 24px;
  width: 24px;
}

.zoom-icon {
  height: 36px;
}

.session-info-row {
  display: flex;
  flex-direction: column;
}

.session-info-title {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #101828;
}

.session-info-text {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #98A2B3;
}

.session-info-link {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #4A68F8;
}

.action-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
}
</style>
