<template>
  <div class="notification-wrapper">
    <div v-click-outside="actionDropdownOutsideClick">
      <img @click="dropdownActive = !dropdownActive" src="@/assets/images/ring.svg" class="header-icon" alt="Accounting Icon" />
      <Transition name="slide-fadeUp" appear>
        <div class="notification-dropdown" v-if="dropdownActive">
          <div class="notification-header">
            <span class="notification-header-txt">{{$t('notifications_title')}}</span>
            <div @click="markAllRead" class="header-right">
              <span class="import-toggle-text">{{$t('read_all')}}</span>
              <img src="../../assets/images/settings.svg" />
            </div>
          </div>
          <div class="tab-select">
            <div class="tab-holder" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
              <span class="tab-text">{{$t('all')}}</span>
              <div v-if="tabIndex===1" class="tab-box" ></div>
            </div>
            <div class="tab-holder" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
              <span class="tab-text">{{$t('unread')}}</span>
              <div v-if="tabIndex===0" class="tab-box"></div>
            </div>
            </div>
          <div v-if="tabIndex === 0">
            <div v-for="(Notification, index) in NotificationList" :key="index" class="notification-container">
              <span class="notification-icon-holder">
              <img src="../../assets/images/settings.svg" />
              </span>
              <span class="notification-holder">
                <span class="notification-content-header">{{ Notification.subject }}</span>
                <span class="notification-content" v-html="Notification.content"></span>
              </span>
              <div v-click-outside="() => closeDropdown(Notification)" class="more-holder">
                <span class="notification-time">{{ calculateTimeDifference(Notification.time) }}</span>
                <span @click="toggleDropdown(Notification)" class="more-icon-holder">...</span>
                <div class="more-dropdown" v-if="Notification.moreDropdownActive">
                  <div @click="Notification.selected1 = !Notification.selected1; makeRead(Notification.id)" class="more-dropdown-item">
                    <span class="more-dropdown-text">{{$t('mark_as_read')}}</span>
                    <img v-if="Notification.selected1" class="more-dropdown-image" src="../../assets/images/blueTick.svg" alt="Tick Icon" />
                  </div>
                  <div @click="Notification.selected2 = !Notification.selected2; deleteNotification(Notification.id)" class="more-dropdown-item">
                    <span class="more-dropdown-text">{{$t('archive')}}</span>
                    <img v-if="Notification.selected2" class="more-dropdown-image" src="../../assets/images/blueTick.svg" alt="Tick Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tabIndex === 1">
            <div v-for="(Notification, index) in NotificationList" :key="index" class="notification-container">
              <span class="notification-icon-holder">
              <img src="../../assets/images/settings.svg" />
              </span>
              <span class="notification-holder">
                <span class="notification-content-header">{{ Notification.subject }}</span>
                <span class="notification-content" v-html="Notification.content"></span>
              </span>
              <div v-click-outside="() => closeDropdown(Notification)" class="more-holder">
                <span class="notification-time">{{ calculateTimeDifference(Notification.time) }}</span>
                <span @click="toggleDropdown(Notification)" class="more-icon-holder">...</span>
                <div class="more-dropdown" v-if="Notification.moreDropdownActive">
                  <div @click="Notification.selected1 = !Notification.selected1; makeRead(Notification.id)" class="more-dropdown-item">
                    <span class="more-dropdown-text">{{$t('mark_as_read')}}</span>
                    <img v-if="Notification.selected1" class="more-dropdown-image" src="../../assets/images/blueTick.svg" alt="Tick Icon" />
                  </div>
                  <div @click="Notification.selected2 = !Notification.selected2; deleteNotification(Notification.id)" class="more-dropdown-item">
                    <span class="more-dropdown-text">{{$t('archive')}}</span>
                    <img v-if="Notification.selected2" class="more-dropdown-image" src="../../assets/images/blueTick.svg" alt="Tick Icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="no-input" v-if="NotificationList.length === 0">
            {{ $t("no_notification") }}
          </div>
          <div class="all-notifications-area">
            <span class="all-notifications-area-text">{{$t('all')}}</span>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import FileDrop from "@/components/FileDrop.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import notificationService from "@/service/notificationService";
export default {
  components: {SuccessModal,FileDrop},

  data() {
    return {
      NotificationList:[],
      read: 0,
      tabIndex:0,
      NotReadList:{
        Notification1:{
          header:"Bu bildirim okunmadı",
          text:"Demo File.pdf",
          time:"23m",
        },
        Notification2:{
          header:"Bu da okunmadı ",
          text:"27.11.2024 tarihinde 'User' ile mülakatın var",
          time:"44m",
        }

      },
      NotificationList1:{
        Notification1:{
          header:"Onayını bekleyen bir dosya var",
          text:"Demo File.pdf",
          time:"23m",
        },
        Notification2:{
          header:"Mülakatın Yaklaşıyor ",
          text:"27.11.2024 tarihinde 'User' ile mülakatın var",
          time:"44m",
        }

      },
      dropdownActive: false,
    }
  },
  created() {
    this.getNotifications();
  },
  methods: {
    markAllRead(){
      notificationService.readAllNotification();
    },
    calculateTimeDifference(notificationTime){
      const currentTime = new Date()
      const notificationDate = new Date(notificationTime)

      const differenceMs = currentTime - notificationDate

      const seconds = Math.floor(differenceMs / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      if (days > 0) return `${days}d`
      if (hours > 0) return `${hours}h`
      if (minutes > 0) return `${minutes}min`
      return `${seconds} sec`
    },
    changeTab(index) {
      this.tabIndex = index;
      this.read=index;
      this.getNotifications();
    },
    actionDropdownOutsideClick() {
      this.dropdownActive = false;
    },
    toggleDropdown(Notification) {
      Notification.moreDropdownActive = !Notification.moreDropdownActive;
    },
    closeDropdown(Notification) {
      Notification.moreDropdownActive = false;
    },
    getNotifications(){
      notificationService.getNotification(1,[],this.read).then(res => {
        this.NotificationList = res.data.data;
        console.log(res.data);
        console.log(this.NotificationList);
        this.isLoading = false;
      }).catch(err => {
        this.$snackbar.add({
          text: err.response.data.message,
          type: 'danger'
        });
      });
      console.log(this.NotificationList);
    },
    makeRead(id){
      notificationService.readNotification(id);
      console.log(id + "maked read");
    },
    deleteNotification(id){
      notificationService.deleteNotification(id);
      console.log(id + "deleted");
    }
  }

}
</script>

<style scoped>
/* Temel stiller */
.no-input {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tab-box {
  height: 3px;
  background-color: #2E70E8;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  justify-content: center;
}

.all-notifications-area-text {
  font-family: euclid_semi_bold, sans-serif;
  margin-right: 15px;
  font-size: 14px;
  color: #2E70E8;
  cursor: pointer;
}

.all-notifications-area {
  padding: 20px 16px 20px 16px;
  display: flex;
  flex-direction: row-reverse;
}

.more-dropdown-image {
  right: 0;
  position: absolute;
  margin-right: 20px;
  pointer-events: none;
  width: 30px;
  height: 30px;
  color: #2E70E8;
}

.more-dropdown-text {
  display: flex;
  font-family: 'euclid_regular', sans-serif;
  font-size: 14px;
  color: #1E293B;
  margin-right: 50px;
}

.more-dropdown-item {
  padding-top: 12px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 8px;
  flex-direction: row;
  display: flex;
  gap: 30px;
  border-radius: 6px;
  align-items: center;
}

.more-dropdown-item:hover {
  background-color: #F9FAFB;
}

.more-icon-holder {
  color: #1E293B;
  font-size: 30px;
  line-height: 20px;
  cursor: pointer;
}

.notification-time {
  color: #475569;
  font-size: 12px;
  font-family: 'euclid_medium', sans-serif;
}

.more-holder {
  display: flex;
  right: 0;
  position: absolute;
  flex-direction: column;
  margin-right: 20px;
  min-height: 100%;
}

.notification-content-header {
  display: inline-block;
  font-family: 'euclid_regular', sans-serif;
  font-size: 14px;
  height: 20px;
  overflow: hidden;
  max-width: 80%;
}

.notification-content {
  display: inline-block;
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 12px;
  height: 18px;
  overflow: hidden;
  max-width: 80%;
}

.notification-holder {
  flex-direction: column;
  align-items: start;
  display: flex;
  gap: 8px;
}

.notification-icon-holder {
  border-radius: 50%;
  background-color: #E2E8F0;
  height: 48px;
  min-width: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.notification-container {
  padding: 24px;
  border-bottom: 1px solid #E2E8F0;
  flex-direction: row;
  display: flex;
  gap: 12px;
  padding-bottom: 39px;
}

.notification-container:hover {
  background-color: #EDF3FF;
}

.tab-select {
  align-items: center;
  padding-top: 10px;
  border-bottom: 1px solid #E2E8F0;
  flex-direction: row;
  display: flex;
}

.notification-wrapper {
  position: relative;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.header-right {
  gap: 12px;
}

.notification-dropdown {
  border: 1px solid #E2E8F0;
  position: absolute;
  top: 40px;
  width: 450px;
  flex-direction: column;
  gap: 5px;
  z-index: 99999;
  right: 0;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 17px 0px #E2E8F0;
}

.more-dropdown {
  display: flex;
  padding: 2px 6px 2px 6px;
  top: 50px;
  position: absolute;
  z-index: 99999;
  right: 0;
  background-color: #FFFFFF;
  height: max-content;
  box-shadow: 0px 4px 17px 0px #E2E8F0;
  border-bottom: 1px solid #EAECF0;
  flex-direction: column;
  gap: 10px;
  width: max-content;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 24px;
  border-bottom: 1px solid #E2E8F0;
}

.notification-header-txt {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 20px;
  font-style: normal;
  line-height: 30px;
}

.close-btn {
  cursor: pointer;
}

.import-toggle-text {
  font-family: euclid_semi_bold, sans-serif;
  margin-right: 15px;
  font-size: 14px;
  color: #2E70E8;
  cursor: pointer;
}

.tab-text {
  color: #64748B;
  font-size: 14px;
  font-family: euclid_semi_bold, sans-serif;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.tab-holder {
  margin-left: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tab-text:hover,
.tab-text.active {
  color: #000000;
}

/* Media Queries */
@media (max-width: 768px) {
  .notification-dropdown {
    width: 380px;
  }

  .notification-content-header,
  .notification-content {
    max-width: 70%;
  }

  .notification-container {
    padding: 16px;
    padding-bottom: 30px;
  }
}

@media (max-width: 672px) {
  .notification-dropdown {
    width: 350px;
    max-height: 80vh;
    overflow-y: auto;
  }

  .notification-content-header,
  .notification-content {
    max-width: 65%;
  }

  .notification-container {
    padding: 14px;
    padding-bottom: 25px;
    min-height: auto;
  }

  .more-holder {
    min-height: unset;
    height: auto;
  }
}

@media (max-width: 638px) {
  .notification-dropdown {
    width: 350px;
    right: 0;
    max-height: 80vh;
    overflow-y: auto;
  }

  .notification-wrapper {
    position: relative;
  }

  .more-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    width: 200px;
    border-radius: 4px;
    box-shadow: 0px 4px 17px 0px #E2E8F0;
  }

  .notification-container {
    padding: 14px;
    padding-bottom: 28px;
  }

  .notification-content-header,
  .notification-content {
    max-width: 68%;
    font-size: 13.5px;
  }

  .more-holder {
    margin-right: 14px;
  }

  .notification-header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
  }
}

@media (max-width: 555px) {
  .notification-dropdown {
    width: 320px;
    right: -16px;
  }

  .notification-content-header,
  .notification-content {
    max-width: 70%;
    font-size: 13.5px;
  }

  .more-holder {
    margin-right: 12px;
  }

  .notification-header {
    padding: 16px;
  }

  .more-dropdown {
    width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
  }
}

@media (max-width: 480px) {
  .notification-dropdown {
    width: 300px;
  }

  .notification-content-header,
  .notification-content {
    max-width: 65%;
    font-size: 13px;
  }

  .notification-header-txt {
    font-size: 18px;
  }

  .tab-holder {
    margin-left: 16px;
  }
}

.header-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

</style>
