import axios from './providers/axios';

const settingsService = {
    getAccountSettings() {
        return axios.get('/v1/admin/settings/myAccount');
    },
    getCompanySettings() {
        return axios.get('/v1/admin/settings/company');
    },
    updateAccountSettings(data) {
        return axios.post('/v1/admin/settings/myAccountSave', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateCompanySettings(data) {
        return axios.post('/v1/admin/settings/companySave', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
}

export default settingsService;
