<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-header-txt">{{ $t('log_records') }}</span>
        </div>
        <div class="log-body">
          <div v-if="data.length>0" class="log-row" v-for="log in data">
            <div class="log-information" >
              <div class="left-side">
                <img v-if="log?.causer?.avatarUrl" :src=log?.causer?.avatarUrl class="profile-img" @click="goToUser(log?.causerId)"/>
                <img v-else src="@/assets/images/user.svg" class="default-profile" @click="goToUser(log?.causerId)"/>
                <span class="profile-name" @click="goToUser(log?.causerId)">{{log?.properties?.user_full_name}}</span>
                <span class="log-description" @click="gotoAction(log?.logName,log?.subjectId)">{{ log?.description}}</span>
              </div>

              <div class="right-side">
                <img src="@/assets/images/datepicker.svg" class="date-img"/>
                <span class="date-text">{{ formatDate(log?.createdAt) }}</span>
              </div>
            </div>
          </div>
          <div v-else class="no-data-text">{{$t("no_data_found")}}</div>
        </div>
        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg"/>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  data(){
    return({
      rootRoute:null,
    })
  },
  props:{
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {
      }
    },
    data: {
      type: Array,
      default:() => []
    },
  },
  methods: {
    gotoAction(actionType, actionId){
      if(actionType==='blog_log'){
        this.rootRoute='blog';
        this.$router.push(`/${this.rootRoute}/${actionId}`);
      }else if(actionType==='company_log'){
        this.rootRoute='companies/detail';
        this.$router.push(`/${this.rootRoute}/${actionId}`);
      }else if(actionType==='slider_log'){
        this.rootRoute='slider';
        this.$router.push(`/${this.rootRoute}/${actionId}`);
      }else if(actionType==='pages_log'){
        this.rootRoute='page';
        this.$router.push(`/${this.rootRoute}/${actionId}`);
      }else if(actionType==='gamelabs_log'){
        this.rootRoute='game';
        this.$router.push(`/${this.rootRoute}/${actionId}`);
      }else if(actionType==='notification_log'){
        this.rootRoute='notification';
        this.$router.push(`/${this.rootRoute}/${actionId}`);
      }else if(actionType==='onboarding_log'){
        this.rootRoute='onboarding';
        this.$router.push(`/${this.rootRoute}/${actionId}`);
      }else if(actionType==='open_position_log'){
        this.rootRoute='positions';
        this.$router.push(`/${this.rootRoute}/${actionId}`);
      }

    },
    goToUser(userId) {
      this.$router.push(`/users/detail/${userId}`);
      console.log(userId);
    },
    formatDate(dateString) {
      if (!dateString) return '';


      const [datePart, timePart] = dateString.split(' ');

      if (datePart.includes('-')) {
        return dateString.slice(0, -3);
      }


      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    }
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 99999;
}
.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}

.cross {
  padding: 8px;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.log-body{
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
}

.log-row{
  display: flex;
  border-bottom: 1px solid #F2F4F7;
  padding: 16px;
  width: 100%;
}

.log-information{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.left-side{
  display: flex;
  flex-direction: row;
  gap:10px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.profile-img{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  background-color:#ececec;
}
.default-profile{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  background-color:#ececec;
  padding: 5px;
}

.profile-name{
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #000000;
  cursor: pointer;
}

.profile-name:hover{
  font-family: euclid_semi_bold, 'sans-serif';
  color:#000000;
}

.log-description{
  margin-left: 10px;
  color:#344054;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  cursor: pointer;
}

.log-description:hover{
  font-family: euclid_semi_bold, 'sans-serif';
  color:#000000;
}

.date-text{
  color:#344054;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
}

.date-img{
  width: 16px;
  height: 16px;
  margin-right: 3px;
}

.no-data-text{
  font-family: euclid_semi_bold, 'sans-serif';
  font-size: 14px;
  color: #6B7C93;
}
</style>