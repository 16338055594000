import axios from './providers/axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const userflowService = {
    getRules(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/processManager/ruleSet/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/processManager/ruleSet/store?limit=20&page=${page}`);
        }
    },
    getRuleById(ruleSetId) {
        return axios.get(`/v1/admin/processManager/ruleSet/getFromId/${ruleSetId}`);
    },
    getRuleComponents() {
        return axios.get(`/v1/admin/processManager/components/get`);
    },
    addRuleSet(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/processManager/ruleSet/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
    updateRuleSet(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/processManager/ruleSet/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Accept-Language": lang,
            }
        });
    },
}


export default userflowService;
