<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt">{{ $t('payment_method') }}</span>
          </div>
          <div class="forms">
            <div class="double-form">
              <div class="form-div w80">
                <span class="form-title">{{ $t('credit_card_number') }}</span>
                <input type="text" class="form-input" v-model="expertData.name"
                       :placeholder="$t('credit_card_number')"/>
              </div>
              <div class="form-div w20">
                <span class="form-title">{{ $t('CVV') }}</span>
                <input type="text" class="form-input" v-model="expertData.surname" :placeholder="$t('CVV')"/>
              </div>
            </div>

            <div class="double-form">
              <div class="form-div w40">
                <span class="form-title">{{ $t('month') }}</span>
                <SelectBox :full-width="true" class="w-100" :options="expertTypes" :on-select="handleTypeSelect"
                           :selected-value="expertData.type" :placeholder="$t('MM')"/>
              </div>

              <div class="form-div w40">
                <span class="form-title">{{ $t('year') }}</span>
                <SelectBox :full-width="true" class="w-100" :options="expertTypes" :on-select="handleTypeSelect"
                           :selected-value="expertData.type" :placeholder="$t('YY')"/>
              </div>

              <div class="form-div w20">
                <img src="/src/assets/images/visa.svg" alt="">
              </div>
            </div>

              <span class="modal-header-txt">{{ $t('billing_information') }}</span>

            <div class="double-form">
              <div class="form-div">
                <input type="text" class="form-input" v-model="expertData.email" :placeholder="$t('first_name')"/>
              </div>
              <div class="form-div">
                <input type="text" class="form-input" v-model="expertData.phoneNumber"
                       :placeholder="this.$t('last_name')"/>
              </div>
            </div>

            <div class="form-div">
              <input type="text" class="form-input" v-model="expertData.title" :placeholder="$t('street_address')"/>
            </div>

            <div class="form-div">
              <input type="text" class="form-input" v-model="expertData.title" :placeholder="$t('Apt,suite,etc.(optional)')"/>
            </div>


            <div class="row-div">
              <input class="form-input-3" :placeholder="this.$t('country_person')" />
              <input class="form-input-4" :placeholder="this.$t('state_detail')" />
              <input class="form-input-4" :placeholder="this.$t('zip_code_detail')" />
            </div>
          </div>

          <div class="save-close-div" >
            <button class="dark-button" @click="closeModal">{{$t('cancel_button')}}</button>
            <button class="primary-button" @click="savePayment">{{$t('save_button')}}</button>
          </div>
          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg"/>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FileDrop from "@/components/FileDrop.vue";
import expertService from "@/service/expertService";
import {
  VueSpinnerIos
} from 'vue3-spinners'
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    FileDrop,
    SuccessModal,
    TextInput,
    SelectBox,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {
      }
    },
    expertId: {
      type: String,
      default: ""
    },
    editMode: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: {
        name: "",
        surname: "",
        title: "",
        email: "",
        phoneNumber: "",
        birthday: "",
        avatarUrl: "",
        type: "",
      }
    },
    refreshCallback: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      showSuccessModal: false,
      uploadedFile: null,
      birthdayDate: null,
      expertData: {
        name: "",
        surname: "",
        title: "",
        email: "",
        phoneNumber: "",
        birthday: "",
        avatarUrl: "",
      },
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },

    closeModal() {
      this.$emit("close");
    },
    savePayment() {
      this.closeModal();
    }
  },

  watch: {
    editData: {
      handler() {
        if (this.editMode) {
          this.expertData = {
            name: this.editData.name,
            surname: this.editData.surname,
            title: this.editData.title,
            email: this.editData.email,
            phoneNumber: this.editData.phone,
            birthday: this.editData.birthday,
            avatarUrl: this.editData.avatarUrl,
          }

          this.birthdayDate = this.convertDate(this.editData.birthday);
        }
      },
      deep: true,
    },
    birthdayDate: {
      handler() {
        this.expertData.birthday = this.convertYMDToDmy(this.birthdayDate);
      }
    }
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}

.cross {
  padding: 8px;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.upload-image-main-div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 24px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  height: auto;
}

.upload-image-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
}

.header-span {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 16px;
  line-height: 24px;
}

.upload-image-txt {
  color: #667085;
  font-size: 14px;
  line-height: 20px;
}

.forms {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  align-items: flex-start;
  width: 100%;
}

.double-form {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
}

.form-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
  z-index: 2;
}

.form-div.half {
  width: calc(50% - 12px);
}

.form-div img {
  width: 100%;
}

.form-title {
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.btn-style {
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}

.next-txt {
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.bottom-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
  flex: 1;
}

@media (max-width: 678px) {
  .upload-image-main-div {
    flex-direction: column;
    gap: 10px;
  }
}

.form-div.w80 {
  width: 80%;
}

.form-div.w40 {
  width: 40%;
}

.form-div.w20 {
  width: 20%;
}

.double-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-close-div{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}

.row-div{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.form-input-2 {
  background: transparent;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 15px 15px;
  width: 25%;
  font-family: 'euclid_regular', sans-serif;
  height: 50px;
}

.form-input-3 {
  background: #F5F5F5;
  border-radius: 8px;
  padding: 15px 15px;
  border: none;
  width: 50%;
  font-family: 'euclid_regular', sans-serif;
  height: 50px;
}

.form-input-4 {
  background: #F5F5F5;
  border-radius: 8px;
  padding: 15px 15px;
  border: none;
  width: 50%;
  font-family: 'euclid_regular', sans-serif;
  height: 50px;
}

</style>
