import axios from './providers/axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const onboardingService = {
    getOnboarding(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/onboarding/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/onboarding/store?limit=20&page=${page}`);
        }
    },

    getAllOnboarding() {
        return axios.get('/v1/admin/onboarding/store?list=1');
    },

    addOnboarding(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/onboarding/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept-Language': lang
            }
        });
    },

    getCopy(onboardingId) {
        return axios.post('/v1/admin/onboarding/copy', {
            onboardingId: onboardingId
        });
    },

    updateOnboarding(data, lang = 'tr') {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/onboarding/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept-Language': lang
            }
        });
    },
    getOnboardingDetail(id, lang = 'tr') {
        return axios.get(`/v1/admin/onboarding/getFromId/${id}`, {
            headers: {
                'Accept-Language': lang
            }
        });
    },
    getLogs(lang){
        return axios.get(`/v1/admin/onboarding/getLogs?/list=1`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    },
    getDetailLogs(lang,id){
        return axios.get(`/v1/admin/onboarding/getLogsById/${id}`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    }
}


export default onboardingService;
