<template>
  <PageLayout :on-settings-action="handleSettingsModalShow" :on-action="handleSave" :on-log-action="showLogModal">
    <div class="tab-panel-wrapper no-text-select">
      <div class="tab-panel-left"></div>

      <div class="tab-panel-right">
        <TranslationDropdown :selected-language="activeLanguage" :on-select="handleLanguageChange"/>
      </div>
    </div>


    <div class="card-wrapper">
      <div class="title-card-border"></div>

      <span class="input-title">{{$t('title_expert')}}</span>

      <input class="form-input w-100 mb-3" :placeholder="this.$t('add_your_blog_title_here')" v-model="pageData.name" />

      <div class="card-row no-text-select">
        <div class="row-50">
          <span class="input-title">{{$t('slug')}}</span>
          <input class="form-input w-100" :placeholder="this.$t('slug')" v-model="pageData.slug" />
        </div>

        <div class="row-50">
          <span class="input-title">{{$t('type')}}</span>
          <SelectBox :full-width="true" :options="pageTypes" :on-select="handleTypeSelect" :selected-value="pageData.type" :placeholder="this.$t('Select_type')" />
        </div>
      </div>
    </div>

    <div class="card-wrapper mt-3">
      <span class="input-title mb-3">{{$t('content')}}</span>
      <quill-editor theme="snow" v-model:content="pageData.description" content-type="html"></quill-editor>
    </div>

    <LoadingOverlay :is-loading="isLoading" />

    <PageSettingsModal v-if="settingsModalVisible" :visible="settingsModalVisible" :settings="pageData" :on-hide="handleSettingsModalHide" />
    <LogModal
        :visible="isLogModalVisible"
        :on-hide="hideLogModal"
        :data="logData"
    />
  </PageLayout>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import BlogLayout from "@/components/layouts/BlogLayout.vue";
import commonService from "@/service/commonService";
import {VueSpinnerCore} from "vue3-spinners";
import pageService from "@/service/pageService";
import {handleErrorResponse} from "@/utils/utils";
import PageLayout from "@/components/layouts/PageLayout.vue";
import SelectBox from "@/components/SelectBox.vue";
import PageSettingsModal from "@/components/modals/PageSettingsModal.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import TranslationDropdown from "@/components/TranslationDropdown.vue";
import LogModal from "@/components/modals/LogModal.vue";


export default {
  name: 'PageDetails',
  components: {
    LogModal,
    TranslationDropdown,
    LoadingOverlay,
    PageSettingsModal,
    SelectBox,
    PageLayout,
    BlogLayout,
    ExpandableSearch,
    Pagination,
    VueSpinnerCore
  },
  data() {
    return {
      logData:[],
      isLogModalVisible:false,
      isLoading: true,
      activeLanguage: 'tr',
      pageData: {
        name: '',
        order: 0,
        readingTime: 0,
        tagIds: [],
        status: false,
        description: '',
      },

      settingsModalVisible: false,
      editMode: false,
      pageTypes: [
        {
          value: 'faq',
          text: 'FAQ'
        },
        {
          value: 'kvkk',
          text: 'KVKK'
        },
        {
          value: 'privacy',
          text: 'Privacy'
        }
      ]
    }
  },

  async mounted() {
    const routeParams = this.$route.params;
    this.isLoading = true;
    this.fetchTags();

    if (routeParams.id !== "new") {
     this.handleGetDetail();
    } else {
      this.isLoading = false;
      this.editMode = false;
    }
    this.getLogs(this.$i18n.locale)

  },
  methods: {
    getLogs(lang){
      const routeParams = this.$route.params;

      pageService.getDetailLogs(lang,routeParams.id).then((res) => {
        this.logData=res.data.data;
      })
    },
    showLogModal(){
      this.isLogModalVisible=true;
    },
    hideLogModal(){
      this.isLogModalVisible=false;
    },
    handleGetDetail() {
      const routeParams = this.$route.params;

      this.isLoading = true;
      this.editMode = true;

      pageService.getPageDetails(routeParams.id, this.activeLanguage).then((res) => {
        this.pageData = res.data.data;
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    fetchTags() {
      commonService.getTags().then((res) => {
        this.tagsData = res.data.data.tag.map((tag) => {
          return {
            id: tag.id,
            name: tag.name,
          }
        });
      });
    },
    handleSettingsModalHide() {
      this.settingsModalVisible = false;
    },
    handleSettingsModalShow() {
      this.settingsModalVisible = true;
    },
    handleTypeSelect(value) {
      this.pageData.type = value;
    },
    handleSave() {
      this.isLoading = true;
      if (this.editMode) {
        pageService.updatePage(this.pageData, this.activeLanguage).then((res) => {
          this.$snackbar.add({
            text: 'Page updated.',
            type: 'success'
          });
          this.pageData = res.data.data;
        }).catch((err) => {
          handleErrorResponse(err, this.$snackbar);
        }).finally(() => {
          this.isLoading = false;
        });
      } else {
        pageService.addPage(this.pageData, this.activeLanguage).then((res) => {
          this.$snackbar.add({
            text: 'Page created.',
            type: 'success'
          });
          window.location.href = "/page/" + res.data.data.pageId;
        }).catch((err) => {
          handleErrorResponse(err, this.$snackbar);
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    handleLanguageChange(lang) {
      const routeParams = this.$route.params;
      this.activeLanguage = lang;

      if (routeParams.id !== "new") {
        this.handleGetDetail();
      }
    },
  }
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.title-card-border {
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  left: 0;
  background-color: #1C3CD5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  background-color: transparent;
  color: #040C2D;
  margin-bottom: 15px;
}

.card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 15px;
}

.input-title {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #010511;
  display: block;
  margin-bottom: 5px;
}


.dropdown {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #F5F5F5;
  border-radius: 8px;
  cursor: pointer;
}

.dropdown img {
  width: 20px;
  height: 20px;
}

.dropdown .check {
  height: 18px;
  width: 18px;
  border-radius: 0;
}

.row-50 {
  width: 100%;
}

.dropdown-content {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 10px;
  z-index: 9999;
}


.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #000000;
  padding: 10px;
}

.dropdown-item:hover {
  background-color: #F5F5F5;
}

.dropdown-item img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}


.tab-panel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tab-panel-left {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.tab-panel-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

</style>
