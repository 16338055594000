<template>
  <Layout>
    <div class="card c-row">
      <multiselect v-model="selectedRole" :options="roles" track-by="id" label="name" :multiple="false" :placeholder="this.$t('select_a_role')"></multiselect>
      <button class="icon-button-square" @click="rolesModalVisible = !rolesModalVisible">
        <img src="@/assets/images/editPenOutline.svg">
      </button>
    </div>

    <Transition name="slide-up">
      <div v-if="selectedRole" class="card mt-3">
          <div  v-if="modules.length > 0">
            <table>
              <thead>
              <tr>
                <th>{{$t('module')}}</th>
                <th>{{$t('create')}}</th>
                <th>{{$t('update')}}</th>
                <th>{{$t('delete_button')}}</th>
                <th>{{$t('list')}}</th>
                <th>{{$t('view')}}</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="role in roleData">
                <td class="module-name" data-label="Module">
                  {{role.name}}
                </td>
                <td data-label="Create"><Switch v-model="role.canCreate" /></td>
                <td data-label="Update"><Switch v-model="role.canUpdate" /></td>
                <td data-label="Delete"><Switch v-model="role.canDelete" /></td>
                <td data-label="List"><Switch v-model="role.canList" /></td>
                <td data-label="View"><Switch v-model="role.canView" /></td>
              </tr>
              </tbody>
            </table>

            <div class="bottom-actions">
              <button class="primary-button" @click="updatePermissions">{{$t('update')}}</button>
            </div>
          </div>
        </div>
    </Transition>

    <RolesModal
        :visible="rolesModalVisible"
        :roles="roles"
        :on-hide="() => {
          rolesModalVisible = false;
        }"
        :refresh-hook="fetchRoles"
    />
    <LoadingOverlay :is-loading="isLoading" />
  </Layout>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Layout from "@/components/layouts/Layout.vue";
import roleService from "@/service/roleService";
import SelectBox from "@/components/SelectBox.vue";
import {VueSpinnerIos} from 'vue3-spinners';
import {handleErrorResponse} from "@/utils/utils";
import Switch from "@/components/Switch.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import RolesModal from "@/components/modals/RolesModal.vue";

export default {
  components: {
    RolesModal,
    LoadingOverlay,
    Switch,
    SelectBox,
    Layout,
    Multiselect,
    VueSpinnerIos
  },
  data() {
    return {
      isLoading: true,
      modules: [],
      roles: [],
      selectedRole: null,
      roleData: [],
      rolesModalVisible: false,
    };
  },
  watch: {
    selectedRole(newVal) {
      if (newVal) {
        this.isLoading = true;
        this.roleData = [];
        roleService.getRole(newVal.id).then((response) => {
          /*this.modules.map(module => {
            if (response.data.data.modules && response.data.data.modules.find(role => role.moduleId === module.moduleId)) {
              const tempModule = response.data.data.modules.find(role => role.moduleId === module.moduleId);
              console.log("found", tempModule)
              this.roleData.push({
                moduleId: module.moduleId,
                name: module.name,
                canCreate: tempModule.canCreate ? tempModule.canCreate : false,
                canUpdate: tempModule.canUpdate ? tempModule.canUpdate : false,
                canDelete: tempModule.canDelete ? tempModule.canDelete : false,
                canList: tempModule.canList ? tempModule.canList : false,
                canView: tempModule.canView ? tempModule.canView : false,
              });
            } else {
              this.roleData.push({
                moduleId: module.moduleId,
                name: module.name,
                canCreate: false,
                canUpdate: false,
                canDelete: false,
                canList: false,
                canView: false
              });
            }
          });*/

          this.roleData= response.data.data.modules;

          console.log("roledata" ,this.roleData);

        }).catch(error => {
          handleErrorResponse(error, this.$snackbar);
        }).finally(() => {
          this.isLoading = false;
        });
      }

    }
  },
  mounted() {
    this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      await roleService.getMenuModules().then((response) => {
        this.modules = response.data.data;
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });

      roleService.getRoles().then((response) => {
        this.roles = response.data.data;
        console.log('pnnar', this.roles);
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
      });
    },
    updatePermissions() {
      this.isLoading = true;
      roleService.updatePermissions({
        roleId: this.selectedRole.id,
        modules: this.roleData
      }).then(() => {
        this.fetchRoles();
        this.$snackbar.add({
          text: this.$t('role_updated'),
          type: "success",
          duration: 3000
        });
      }).catch((error) => {
        this.$snackbar.add({
          text: this.$t('role_not_updated'),
          type: "error",
          duration: 5000
        });
      }).finally(() => {
        this.isLoading = false;  // Loading durumunu her durumda kapatıyoruz
      });
    }
  },
}

</script>

<style scoped>
.card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  width: 100%;
}

/* Tablo container */
.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

/* Tablo stilleri */
table {
  width: 100%;
  border-collapse: collapse;
}

table thead {
  background-color: #F9FAFB;
}

table th {
  padding: 12px;
  text-align: left;
  font-size: 14px;
  color: #344054;
  white-space: nowrap;
}

table td {
  padding: 12px;
  vertical-align: middle;
}

.module-name {
  text-transform: capitalize;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
  white-space: nowrap;
}

/* Flex container */
.c-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

/* Bottom actions */
.bottom-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  padding: 10px 0;
}

/* Multiselect özelleştirme */
::v-deep(.multiselect) {
  flex: 1;
  min-width: 200px;
}

/* Loading wrapper */
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Tablet için düzenlemeler (768px - 1024px) */
@media screen and (max-width: 1024px) {
  .card {
    padding: 15px;
  }

  table th,
  table td {
    padding: 10px;
    font-size: 13px;
  }

  .bottom-actions {
    padding: 15px 0;
  }

  .c-row {
    flex-direction: row;
    align-items: stretch;
  }

}

/* Mobil için düzenlemeler (<768px) */
@media screen and (max-width: 767px) {
  .card {
    padding: 12px;
  }

  /* Tablo düzenlemesi */
  table {
    display: block;
    width: 100%;
  }

  table thead {
    display: none; /* Başlıkları gizle */
  }

  table tr {
    margin-bottom: 15px;
    display: block;
    border-bottom: 2px solid #F2F4F7;
    padding: 10px 0;
  }

  table td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    text-align: right;
    font-size: 13px;
  }

  table td::before {
    content: attr(data-label);
    font-weight: bold;
    text-align: left;
    color: #344054;
  }

  .module-name {
    font-size: 13px;
  }

  /* Multiselect ve button düzenlemesi */
  .c-row {
    flex-direction: row;
    align-items: stretch;
  }

  .icon-button-square {
    align-self: flex-end;
  }

  ::v-deep(.multiselect) {
    width: 100%;
  }

  /* Bottom actions düzenlemesi */
  .bottom-actions {
    margin-top: 15px;
    padding: 10px 0;
  }

  .primary-button {
    width: 100%;
  }
}

/* Animasyonlar */
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

/* Switch component düzenlemesi */
::v-deep(.switch-container) {
  margin: 0 auto;
}

</style>
