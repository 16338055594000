<template>
  <div class="card-wrapper">
    <div class="question-header">
      <div class="left-wrapper">
        <div class="question-number-wrapper">
          <span class="question-number">{{cardData.order}}.</span>
        </div>

        <span class="question-type-text">{{$t('card')}}: </span>

        <div class="question-dropdown" v-click-outside="dropdownOutsideClick">
          <div class="dropdown-button" :class="{'active': cardDropdownVisible}" @click="cardDropdownVisible = !cardDropdownVisible">
            <img :src="cardTypes.find(type => type.id === selectedTypeID).icon" />
            <span>{{cardTypes.find(type => type.id === selectedTypeID).name}}</span>
            <img src="@/assets/images/arrowdown.svg" class="arrow" />
          </div>

          <div class="dropdown-items" v-if="cardDropdownVisible">
            <div v-for="item in cardTypes" class="dropdown-item" @click="() => selectType(item.id)">
              <img :src="item.icon" />
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="right-wrapper">
       <!-- <Switch v-model="cardData.status" /> -->
        <img src="@/assets/images/arrow-up.svg" @click="onChangePosition('up', cardData.order)"/>
        <img src="@/assets/images/arrow-down.svg" @click="onChangePosition('down', cardData.order)" />
        <img src="@/assets/images/copyOutline.svg" @click="onDuplicateQuestion(cardData.order)" />
        <img src="@/assets/images/trashOutline.svg" @click="handleQuestionDelete" />
      </div>
    </div>

    <Transition name="slide-up">
      <div v-if="expanded" class="question-body">

        <div class="port-body" v-if="selectedTypeID === 1">
          <span class="form-label">{{$t('card_name')}}</span>
          <input class="question-input mb-2" :placeholder="this.$t('add_your_card_name_here')" v-model="cardData.options[0].name" />
          <span class="form-label">{{$t('score')}}</span>
          <input type="number" class="question-input mb-3" :placeholder="this.$t('add_your_card_score_here')" v-model="cardData.options[0].score" />
          <span class="form-label">{{$t('group')}}</span>
          <SelectBox :options="groupList" class="mb-3"
                     :placeholder="this.$t('select_a_group')"
                     :selected-value="cardData.options[0].groupId"
                     :on-select="(value) => {
                     cardData.options[0].groupId = value;

          }" />
          <FileDrop :on-file-selected="handleOptionA" :not-binary=true :selected-file="cardData.options[0].files?.attachUrl" :file-info="cardData.options[0].files?.info" :on-delete="() => handleImageDelete(cardData.options[0])" />

        </div>

        <div class="port-body" v-if="selectedTypeID === 2 || selectedTypeID === 3">
          <div class="file-row">
            <div>
              <span class="form-label">{{$t('card_name')}}</span>
              <input class="question-input mb-2" :placeholder="this.$t('add_your_card_name_here')" v-model="cardData.options[0].name"/>
              <span class="form-label">{{$t('score')}}</span>
              <input type="number" class="question-input mb-3" :placeholder="this.$t('add_your_card_score_here')" v-model="cardData.options[0].score" />
              <span class="form-label">{{$t('group')}}</span>
              <SelectBox :full-width="true" :options="groupList" class="mb-3" :placeholder="this.$t('select_a_group')" :selected-value="cardData.options[0].groupId" :on-select="(value) => {
                cardData.options[0].groupId = value;
              }" />
              <FileDrop :on-file-selected="handleOptionA" :not-binary=true :selected-file="cardData.options[0].files?.attachUrl" :file-info="cardData.options[0].files?.info" :on-delete="() => handleImageDelete(cardData.options[0])"/>
            </div>
            <div>
              <span class="form-label">{{$t('card_name')}}</span>
              <input class="question-input mb-2" :placeholder="this.$t('add_your_card_name_here')" v-model="cardData.options[1].name" />
              <span class="form-label">{{$t('score')}}</span>
              <input type="number" class="question-input mb-3" :placeholder="this.$t('add_your_card_score_here')" v-model="cardData.options[1].score" />
              <span class="form-label">{{$t('group')}}</span>
              <SelectBox :full-width="true" :options="groupList" class="mb-3" :placeholder="this.$t('select_a_group')" :selected-value="cardData.options[1].groupId" :on-select="(value) => {
                cardData.options[1].groupId = value;
              }" />
              <FileDrop :on-file-selected="handleOptionB" :not-binary=true  :selected-file="cardData.options[1].files?.attachUrl" :file-info="cardData.options[1].files?.info" :on-delete="() => handleImageDelete(cardData.options[1])"/>
            </div>
          </div>
        </div>

      </div>
    </Transition>

    <div class="toggle-card" :class="{'closed': !expanded}" @click="toggleCard">
      <img src="@/assets/images/cross.svg" />
    </div>

  </div>
</template>

<script>
import swipeOutline from "@/assets/images/swipeOutline.svg";
import verticalOutline from "@/assets/images/verticalOutline.svg";
import horizontalOutline from "@/assets/images/horizontalOutline.svg";
import FileDrop from "@/components/FileDrop.vue";
import SelectBox from "@/components/SelectBox.vue";
import fileService from "@/service/fileService";

export default {
  components: {SelectBox, FileDrop,},
  props: {
    data: {
      type: Object,
      default: {}
    },
    cardUpdate: {
      type: Function,
      default: () => {}
    },
    onDelete: {
      type: Function,
      default: () => {}
    },
    onChangePosition: {
      type: Function,
      default: () => {}
    },
    onDuplicateQuestion: {
      type: Function,
      default: () => {}
    },
    groupList: {
      type: Array,
      default: () => []
    },
    deleteList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      expanded: true,
      selectedTypeID: 1,
      cardDropdownVisible: false,
      partOrder: 1,

      cardTypes: [
        {
          id: 1,
          name: "Swipe",
          icon: swipeOutline,
          data: {
            status: true,
            order: 1,
            type: "swipe",
            options: [
              {
                files: {
                  attachUrl:null,
                },
                name: "Option A",
                score: 0,
                attach: null,
              }
            ]
          },
        },
        {
          id: 2,
          name: "Vertical",
          icon: verticalOutline,
          data: {
            status: true,
            order: 1,
            type: "vertical",
            options: [
              {
                files: {
                  attachUrl:null,
                },
                name: "Option A",
                score: 0,
                attach: null,
                attachUrl: null,
              },
              {
                files: {
                  attachUrl:null,
                },
                name: "Option B",
                score: 0,
                attach: null,
                attachUrl: null,
              }
            ]
          },
        },
        {
          id: 3,
          name: "Horizontal",
          icon: horizontalOutline,
          data: {
            status: true,
            order: 1,
            type: "horizontal",
            options: [
              {
                files: {
                  attachUrl:null,
                },
                name: "Option A",
                score: 0,
                attach: null,
                attachUrl: null,
              },
              {
                files: {
                  attachUrl:null,
                },
                name: "Option B",
                score: 0,
                attach: null,
                attachUrl: null,
              }
            ]
          },
        },
      ],

      cardData: {},

      answers: [{
        id: 0,
        value: ""
      }],
    }
  },
  created() {
    this.handleCardChanges();
  },
  watch: {
    cardData: {
      handler: function (newVal, oldVal) {
        this.cardUpdate(newVal);
      },
      deep: true
    },
    data: {
      handler: function (newVal, oldVal) {
        this.handleCardChanges();
      },
      deep: true
    },
  },
  methods: {
    handleImageDelete(element) {
      this.deleteList.push(element.files.fillableId);
      element.attach = null;
      element.attachUrl = null;
    },



    handleCardChanges() {
      if (this.data) {
        if (this.data.type === "swipe") {
          this.selectedTypeID = 1;
        } else if (this.data.type === "vertical") {
          this.selectedTypeID = 2;
        } else if (this.data.type === "horizontal") {
          this.selectedTypeID = 3;
        }

        this.cardData = this.data;
        this.partOrder = this.data.order;
      } else {
        this.cardData = this.cardTypes.find(type => type.id === this.selectedTypeID).data;
      }
    },
    dropdownOutsideClick() {
      this.cardDropdownVisible = false;
    },
    selectType(typeID) {
      this.cardData = this.cardTypes.find(type => type.id === typeID).data;
      this.cardData.order = this.partOrder;

      this.selectedTypeID = typeID;
      this.cardDropdownVisible = false;
    },
    toggleCard() {
      this.expanded = !this.expanded;
    },
    handleQuestionDelete() {
      this.onDelete(this.cardData.order);
    },
    handleOptionA(file) {
      if (!this.cardData.options && !this.cardData.options[0]) {
        this.cardData.options = [];
      }

      this.cardData.options[0].attach = file;
    },
    handleOptionB(file) {
      if (!this.cardData.options && !this.cardData.options[1]) {
        this.cardData.options = [];
      }

      this.cardData.options[1].attach = file;
    },
  }
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.question-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.left-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.right-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px
}

.question-number-wrapper {
  background-color: #040C2D;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-number {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #FFFFFF;
}

.question-body {
  padding: 15px 0;
}

.question-dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.question-dropdown img {
  height: 16px;
  width: 16px;
}

.question-dropdown span {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
}

.dropdown-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-button:hover, .dropdown-button.active {
  background-color: #F2F4F7;
}

.dropdown-button .arrow {
  transition: transform 0.1s linear;
}

.dropdown-button.active .arrow {
  transform: rotate(180deg);
}

.dropdown-items {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
}

.dropdown-item:hover {
  background-color: #F5F7FA;
}

.question-input, .answer-input  {
  border: none;
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #F5F5F5;
  color: #282A36;
  padding: 15px;
  border-radius: 8px;
}

.question-input {
  width: 100%;
}

.answer-input {
  width: 300px;
}

.answer-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

h4 {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
  margin: 0;
  padding: 0;
}

.answers-body {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add-answer-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
  color: #4A68F8;
  margin-top: 10px;
  border: none;
  outline: none;
  background-color: transparent;
}

.add-answer-button:hover {
  background-color: #f3f4ff;
}

.toggle-card {
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 20px;
  width: 20px;
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.toggle-card img {
  height: 9px;
  width: 9px;
}

.toggle-card.closed img {
  transform: rotate(-45deg);
}

.number-input-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  height: 48px;
  width: 350px;
}

.number-input-row input {
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #ffffff;
  color: #282A36;
  padding: 15px;
  width: 100%;
  border-top: 1px solid #D0D5DD;
  border-bottom: 1px solid #D0D5DD;
  border-left: none;
  border-right: none;
  height: 48px;
}

.number-input-row .left-part, .number-input-row .right-part {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 40px;
  background-color: #FCFCFD;
  border: 1px solid #D0D5DD;
}

.number-input-row .left-part img, .number-input-row .right-part img {
  height: 16px;
  width: 16px;
}

.number-input-row .left-part {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.number-input-row .right-part {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  background-color: transparent;
  color: #040C2D;
  display: block;
  margin-bottom: 10px;
}

.upload-image{
  border-radius: 12px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  width: 100%;
}
.upload-image-attention-div{
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 24px;
}
.orange{
  display: inline-block;
  font-family: euclid_semi_bold, sans-serif;
  color: #F09460;
  font-size: 14px;
}
.gray{
  display: inline-block;
  color: #667085;
  font-size: 14px;
}
.file-row{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
