<template>
  <div class="search-wrapper" :class="{'expanded': isExpanded}" @click="toggleSearch">
    <img v-if="isExpanded" src="@/assets/images/cross.svg" @click="cancelSearch" />
    <img v-if="!isExpanded" src="@/assets/images/search.svg"/>
    <span class="search-txt" v-if="!isExpanded">{{ $t('search_text') }}</span>
    <input
        v-if="isExpanded"
        v-focus="isExpanded"
        @keyup.enter="handleSearch"
        v-model="searchQuery"
        class="input"
        type="text"
        :placeholder="$t('search_text')"
        @click.stop />
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: "",
      isExpanded: false
    }
  },
  methods: {
    toggleSearch() {
      this.isExpanded = !this.isExpanded;
      if (!this.isExpanded) {
        this.searchQuery = '';
      }
    },
    cancelSearch() {
      this.searchQuery = "";
      this.$router.push({
        query: {
          ...this.$route.query,
          searchText: undefined,
        }
      });
    },
    handleSearch() {
      if (this.searchQuery.length < 3) {
        this.$snackbar.add({
          text: this.$t('minimum_characters_warning'),
          type: "error",
          duration: 3000
        });
        return;
      }

      this.$router.push({
        query: {
          ...this.$route.query,
          searchText: this.searchQuery,
        }
      });
    }
  }
}
</script>

<style scoped>
.search-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  min-height: 32px;
}

.search-txt {
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
}

.expanded {
  background-color: #F9FAFB;
  border: 1px solid #EAECF0;
  border-radius: 24px;
  padding: 5px 10px;
  cursor: text;
}

input {
  font-size: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

input:focus {
  outline: none;
}
</style>