import axios from './providers/axios';

const companyService = {
    getCompanies(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/company/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/company/store?limit=20&page=${page}`);
        }
    },
    getCompanyList() {
            return axios.get(`/v1/admin/company/store?list=1`);
    },
    addCompany(data) {
        return axios.post('/v1/admin/company/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    getCompanyDetails(id) {
        return axios.get(`/v1/admin/company/getFromDetail/${id}`);
    },
    updateCompany(data) {
        return axios.post('/v1/admin/company/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteCompany(id) {
        return axios.delete(`/v1/admin/company/delete/${id}`);
    },
    changeInviteCode(id){
        return axios.post(`/v1/admin/company/refreshInviteCode/${id}`);
    },

    getLogs(lang){
        return axios.get(`/v1/admin/company/getLogs?/list=1`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    },
    getDetailLogs(lang,id){
        return axios.get(`/v1/admin/company/getLogByCompanyId?companyId=${id}`, {
            headers:{
                "Accept-Language": lang,
            }
        });
    },
    changeCompany(id){
        return axios.get(`/v1/admin/company/change?companyId=${id}`, {})
    },
    changeCompanyList(){
        return axios.get(`/v1/admin/company/changeList?/list=1`);
    }
}

export default companyService;
