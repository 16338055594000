import { createApp, h } from 'vue'
import AIGeneratorPanel from '../components/AIGeneratorPanel.vue'

export const aiTextGenerator = {
    mounted(el, binding) {
        // Create wrapper to position the panel
        const wrapper = document.createElement('div')
        wrapper.style.position = 'relative'
        wrapper.style.width = '100%'
        el.parentNode.insertBefore(wrapper, el)
        wrapper.appendChild(el)

        // Create panel container
        const container = document.createElement('div')
        container.style.position = 'absolute'
        container.style.bottom = '100%'
        container.style.left = '0'
        container.style.zIndex = '1000'
        container.style.marginBottom = '10px'
        wrapper.appendChild(container)

        // Track panel visibility
        let isPanelVisible = false

        // Click outside handler
        const handleClickOutside = (event) => {
            const aiSocket = binding.value

            if (aiSocket.state.isGenerating[aiSocket.state.currentChatId]) {
                return;
            }

            if (isPanelVisible &&
                !(container.contains(event.target) || el.contains(event.target))) {
                isPanelVisible = false
                container.style.display = 'none'
            }
        }

        // Add click outside listener
        document.addEventListener('click', handleClickOutside)

        // Toggle panel visibility when clicking the input
        el.addEventListener('click', () => {
            const aiSocket = binding.value;

            if (aiSocket.state?.socket === null) {
                console.error('Socket is null')
                return;
            }

            if (aiSocket.state?.socket?.connected === false) {
                console.error('Socket not connected')
                return;
            }

            isPanelVisible = true;
            container.style.display = 'block';
        })

        // Create panel instance
        const app = createApp({
            render() {
                return h(AIGeneratorPanel, {
                    targetInput: el,
                    aiSocket: binding.value,
                })
            }
        })

        // Mount panel (initially hidden)
        app.mount(container)
        container.style.display = 'none'

        // Store cleanup function
        el._cleanup = () => {
            document.removeEventListener('click', handleClickOutside)
            app.unmount()
            wrapper.removeChild(container)
            el.parentNode.insertBefore(el, wrapper)
            wrapper.remove()
        }
    },

    unmounted(el) {
        if (el._cleanup) {
            el._cleanup()
        }
    },
}
