<template>
  <BlogLayout :on-settings-action="handleSettingsModalShow" :on-action="handleSave" :on-log-action="showLogModal">
    <div class="tab-panel-wrapper no-text-select">
      <div class="tab-panel-left"></div>

      <div class="tab-panel-right">
        <TranslationDropdown :selected-language="activeLanguage" :on-select="handleLanguageChange"/>
      </div>
    </div>


    <div class="card-wrapper">
      <div class="title-card-border"></div>

      <span class="input-title">{{$t('title_expert')}}</span>

      <input class="form-input w-100 mb-3" :placeholder="this.$t('add_your_blog_title_here')" v-model="blogData.name" />

      <div class="card-row no-text-select">
        <div class="row-50">
          <span class="input-title">{{$t('tags_accounting')}}</span>

          <div class="dropdown" @click="toggleTagsDropdown" v-click-outside="hideTagsDropdown">
            <span v-if="tagsData.filter(tag => tag.selected).length === 0">{{$t('select_tags_question')}}</span>
            <span v-else>{{ tagsData.filter(tag => tag.selected).length }} selected</span>
            <img src="@/assets/images/arrowdown.svg" />

            <div class="dropdown-content" v-if="tagsDropdown">
              <div class="tags-list scroll-design">
                <div class="dropdown-item" v-for="tag in tagsData" @click="toggleTag(tag.id)">
                  <img v-if="tag.selected" class="check" src="@/assets/images/checkboxChecked.svg" />
                  <img v-else class="check" src="@/assets/images/checkbox.svg" />
                  <span>{{ tag.name }}</span>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="row-50">
          <span class="input-title">{{$t('reading_time')}}</span>

          <input class="form-input" type="number" :placeholder="this.$t('enter_reading_time')" :value="readingTime(blogData.description)" />
        </div>
      </div>
    </div>

    <div class="card-wrapper mt-3">
      <span class="input-title mb-3">{{$t('content')}}</span>
      <quill-editor theme="snow" v-model:content="blogData.description" content-type="html"></quill-editor>
    </div>

    <LoadingOverlay :is-loading="isLoading" />

    <BlogSettingsModal :visible="settingsModalVisible" :onHide="handleSettingsModalHide" :settings="blogData" :edit-mode="editMode" />
    <LogModal
        :visible="isLogModalVisible"
        :on-hide="hideLogModal"
        :data="logData"
    />
  </BlogLayout>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import BlogLayout from "@/components/layouts/BlogLayout.vue";
import commonService from "@/service/commonService";
import BlogSettingsModal from "@/components/modals/BlogSettingsModal.vue";
import {VueSpinnerCore} from "vue3-spinners";
import blogService from "@/service/blogService";
import {handleErrorResponse} from "@/utils/utils";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import TranslationDropdown from "@/components/TranslationDropdown.vue";
import LogModal from "@/components/modals/LogModal.vue";

export default {
  name: 'BlogDetails',
  components: {
    LogModal,
    TranslationDropdown,
    LoadingOverlay,
    BlogSettingsModal,
    BlogLayout,
    ExpandableSearch,
    Pagination,
    VueSpinnerCore
  },
  data() {
    return {
      logData:[],
      isLogModalVisible:false,
      isLoading: true,
      activeLanguage: 'tr',
      blogData: {
        name: '',
        readingTime: 0,
        tagIds: [],
        status: false,
        description: '',
      },
      tagsDropdown: false,
      tagsData: [{
        id: 0,
        name: this.$t('blog_loading'),
        selected: false
      }],
      settingsModalVisible: false,
      editMode: false,
    }
  },

  async mounted() {
    const routeParams = this.$route.params;
    this.isLoading = true;
    this.fetchTags();

    if (routeParams.id !== "new") {
      this.handleGetDetail();
    } else {
      this.isLoading = false;
      this.editMode = false;
    }
    this.getLogs(this.$i18n.locale)
  },
  methods: {
    getLogs(lang){
      const routeParams = this.$route.params;

      blogService.getDetailLogs(lang,routeParams.id).then((res) => {
        this.logData=res.data.data;
      })
    },
    showLogModal(){
      this.isLogModalVisible=true;
    },
    hideLogModal(){
      this.isLogModalVisible=false;
    },
    handleGetDetail() {
      const routeParams = this.$route.params;

      this.isLoading = true;
      this.editMode = true;

      blogService.getBlogDetail(routeParams.id, this.activeLanguage).then((res) => {
        this.blogData = res.data.data;

        if (this.blogData.tags.length > 0) {
          this.tagsData.forEach(tag => {
            this.blogData.tags.forEach(blogTag => {
              if (tag.id === blogTag.tagId) {
                tag.selected = true;
              }
            })
          });

          this.blogData.tagIds = this.tagsData.filter(tag => tag.selected).map(tag => tag.id);
        }
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    fetchTags() {
      commonService.getTags().then((res) => {
        this.tagsData = res.data.data.tag.map((tag) => {
          return {
            id: tag.id,
            name: tag.name,
          }
        });
      });
    },
    toggleTag(id) {
      if (id === 0) {
        return;
      }

      const foundTag = this.tagsData.find(
          (tag) => tag.id === id
      );

      if (foundTag) {
        foundTag.selected = !foundTag.selected;
      }

      this.blogData.tagIds = this.tagsData.filter(tag => tag.selected).map(tag => tag.id);
    },
    toggleTagsDropdown() {
      this.tagsDropdown = true;
      this.therapistDropdown = false;
      event.stopPropagation();
    },
    hideTagsDropdown() {
      this.tagsDropdown = false;
    },
    handleSettingsModalShow() {
      this.settingsModalVisible = true;
    },
    handleSettingsModalHide() {
      this.settingsModalVisible = false;
    },
    handleSave() {
      this.isLoading = true;

      if (this.editMode) {
        blogService.updateBlog(this.blogData, this.activeLanguage).then((res) => {
          this.$snackbar.add({
            text: 'Blog updated successfully',
            type: 'success'
          });
          this.blogData = res.data.data;
        }).catch((err) => {
          handleErrorResponse(err, this.$snackbar);
        }).finally(() => {
          this.isLoading = false;
        });
      } else {
        blogService.addBlog(this.blogData, this.activeLanguage).then((response) => {
          this.$snackbar.add({
            text: 'Blog created successfully',
            type: 'success'
          });
          window.location.href = "/blog/" + response.data.data.blogId;
        }).catch((err) => {
          handleErrorResponse(err, this.$snackbar);
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    readingTime(text) {
      if (!text) {
        return 1;
      }

      const averageWPM = 250;

      const adjustedText = text.replace(/(.)\1+/g, '$1');

      const adjustedSentences = adjustedText.replace(/([.!?])\s*\1+/g, '$1');

      const adjustedCharCount = adjustedSentences.length;

      const adjustedWords = adjustedSentences.trim().split(/\s+/);
      const adjustedWordCount = adjustedWords.length;
      const averageWordLength = adjustedCharCount / adjustedWordCount;

      const adjustedTime = (adjustedCharCount / averageWPM) * (averageWordLength / 5);

      const formattedAdjustedTime = adjustedTime > 1 ? Math.round(adjustedTime):"1";

      return formattedAdjustedTime;
    },
    handleLanguageChange(lang) {
      const routeParams = this.$route.params;
      this.activeLanguage = lang;

      if (routeParams.id !== "new") {
        this.handleGetDetail();
      }
    },
  }
}
</script>

<style scoped>
.card-wrapper {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  border-radius: 16px;
  padding: 20px 20px 20px 28px;
  display: flex;
  flex-direction: column;
}

.title-card-border {
  position: absolute;
  height: 100%;
  width: 8px;
  top: 0;
  left: 0;
  background-color: #1C3CD5;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.title-input {
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  background-color: transparent;
  color: #040C2D;
  margin-bottom: 15px;
}

.card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 15px;
}

.input-title {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #010511;
  display: block;
  margin-bottom: 5px;
}


.dropdown {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #F5F5F5;
  border-radius: 8px;
  cursor: pointer;
}

.dropdown img {
  width: 20px;
  height: 20px;
}

.dropdown .check {
  height: 18px;
  width: 18px;
  border-radius: 0;
}

.row-50 {
  width: 100%;
}

.dropdown-content {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 10px;
  z-index: 9999;
}


.dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #000000;
  padding: 10px;
}

.dropdown-item:hover {
  background-color: #F5F5F5;
}

.dropdown-item img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}


.tags-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 5px;
  margin-right: 5px;
  max-height: 200px;
  overflow-y: auto;
}

.blog-content {
  min-height: 400px;
}

.tab-panel-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tab-panel-left {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.tab-panel-right {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
