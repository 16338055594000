<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-body">
          <div class="left">
            <h3>{{$t('weekly_available_hours')}}</h3>

            <div class="days-list">
            <div class="day-wrapper" v-for="day in daysArray">
              <div class="day-left">
                <div @click="checkDaySlot(day.value)">
                  <img src="../../assets/images/checkboxChecked.svg" v-if="checkDaySelected(day.value)" />
                  <img src="../../assets/images/checkbox.svg" v-if="!checkDaySelected(day.value)" />
                </div>

                <span class="day-name">
                  {{$t('day_' + day.value)}}
               </span>
              </div>

              <div class="day-right">
                <div class="time-wrapper" v-for="(slot, index) in weeklyData[day.value]?.slotTimes">
                  <SelectBox
                      size="medium"
                      :options="timeStartOptions"
                      :placeholder="this.$t('start_time')"
                      :selected-value="slot.startTime"
                      :on-select="value => {
                        slot.startTime = value;
                        slot.endTime = '';
                      }"
                  />
                  <div class="row-separator" />
                  <SelectBox
                      size="medium"
                      :options="getTimeEndOptions(slot.startTime)"
                      :placeholder="this.$t('end_time')"
                      :selected-value="slot.endTime"
                      :on-select="value => slot.endTime = value"
                  />

                  <img src="@/assets/images/trash.svg" @click="handleSlotDelete(day.value, index)"/>
                </div>

                <button class="add-new-slot" @click="addNewSlot(day.value)">
                  <img src="../../assets/images/add.svg" />
                </button>
              </div>
            </div>
            </div>
          </div>

          <div class="separator"></div>

          <div class="right">
            <h3>{{$t('date_specific_hours')}}</h3>
            <h5>{{$t('override_your_availability')}}.</h5>

            <button class="add-specific-button" @click="specificModalVisible = true">
              <img src="@/assets/images/plusBlue.svg" />
              <span>{{$t('add_specific_hour')}}</span>
            </button>

            <div class="specific-availability" v-for="item in specificAvailability">

              <div class="date-wrapper" v-for="date in item.dates">
                <div class="date-left">
                  <span>
                    {{this.$moment(date.date).format('MMMM D')}}
                  </span>
                </div>

                <div class="date-right">
                  <div class="slot-list">
                    <span v-for="slot in item.slotTimes">{{slot.startTime}} - {{slot.endTime}}</span>
                  </div>
                  <img src="@/assets/images/trash.svg" @click="handleSpecificDateDelete(item.id)" />
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <span class="save-info">{{$t('do_you_want_to_save')}}?</span>

          <button class="primary-button" @click="handleSave">
            <span>{{$t('save')}}</span>
          </button>
        </div>

        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>
    </div>
  </Transition>

  <SpecificAvailabilityModal
      :expert-id="expertId"
      :visible="visible && specificModalVisible"
      :on-hide="hideSpecificModal"
      :on-save="handleSpecificSave"
  />
</template>

<script>
import {VueSpinnerIos} from 'vue3-spinners'
import SelectBox from "@/components/SelectBox.vue";
import SpecificAvailabilityModal from "@/components/modals/SpecificAvailabilityModal.vue";
import expertService from "@/service/expertService";
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    SpecificAvailabilityModal,
    SelectBox,
    VueSpinnerIos,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {
      }
    },
    expertId: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      isLoading: false,
      specificModalVisible: false,
      weeklyData: {
        1: {
          slotTimes: {},
        },
        2: {
          slotTimes: {},
        },
        3: {
          slotTimes: {},
        },
        4: {
          slotTimes: {},
        },
        5: {
          slotTimes: {},
        },
        6: {
          slotTimes: {},
        },
        7: {
          slotTimes: {},
        }
      },
      timeStartOptions: [
        {text: "09:00", value: "09:00"},
        {text: "09:15", value: "09:15"},
        {text: "09:30", value: "09:30"},
        {text: "09:45", value: "09:45"},
        {text: "10:00", value: "10:00"},
        {text: "10:15", value: "10:15"},
        {text: "10:30", value: "10:30"},
        {text: "10:45", value: "10:45"},
        {text: "11:00", value: "11:00"},
        {text: "11:15", value: "11:15"},
        {text: "11:30", value: "11:30"},
        {text: "11:45", value: "11:45"},
        {text: "12:00", value: "12:00"},
        {text: "12:15", value: "12:15"},
        {text: "12:30", value: "12:30"},
        {text: "12:45", value: "12:45"},
        {text: "13:00", value: "13:00"},
        {text: "13:15", value: "13:15"},
        {text: "13:30", value: "13:30"},
        {text: "13:45", value: "13:45"},
        {text: "14:00", value: "14:00"},
        {text: "14:15", value: "14:15"},
        {text: "14:30", value: "14:30"},
        {text: "14:45", value: "14:45"},
        {text: "15:00", value: "15:00"},
        {text: "15:15", value: "15:15"},
        {text: "15:30", value: "15:30"},
        {text: "15:45", value: "15:45"},
        {text: "16:00", value: "16:00"},
        {text: "16:15", value: "16:15"},
        {text: "16:30", value: "16:30"},
        {text: "16:45", value: "16:45"},
        {text: "17:00", value: "17:00"},
        {text: "17:15", value: "17:15"},
        {text: "17:30", value: "17:30"},
        {text: "17:45", value: "17:45"},
        {text: "18:00", value: "18:00"},
        {text: "18:15", value: "18:15"},
        {text: "18:30", value: "18:30"},
        {text: "18:45", value: "18:45"},
        {text: "19:00", value: "19:00"},
        {text: "19:15", value: "19:15"},
        {text: "19:30", value: "19:30"},
        {text: "19:45", value: "19:45"},
        {text: "20:00", value: "20:00"},
        {text: "20:15", value: "20:15"},
        {text: "20:30", value: "20:30"},
        {text: "20:45", value: "20:45"},
        {text: "21:00", value: "21:00"},
        {text: "21:15", value: "21:15"},
        {text: "21:30", value: "21:30"},
        {text: "21:45", value: "21:45"},
        {text: "22:00", value: "22:00"},
      ],
      daysArray: [
        {
          value: 1,
          name: "Mon"
        },
        {
          value: 2,
          name: "Tue"
        },
        {
          value: 3,
          name: "Wed"
        },
        {
          value: 4,
          name: "Thu"
        },
        {
          value: 5,
          name: "Fri"
        },
        {
          value: 6,
          name: "Sat"
        },
        {
          value: 7,
          name: "Sun"
        }
      ],
      specificAvailability: []
    };
  },
  watch: {
    expertId: {
      immediate: true,
      handler() {
        if (this.expertId) {
          this.fetchSlots();
          this.fetchSpecificSlots();
        }
      }
    }
  },
  methods: {
    fetchSlots() {
      if (this.expertId === '' || !this.expertId) {
        return;
      }

      expertService.getExpertSlots(this.expertId).then((response) => {
         const tempSlots = response.data.data.slots;

         if (!tempSlots || Object.keys(tempSlots).length === 0) {
           return;
         }

        this.daysArray.forEach((day) => {
          if (!tempSlots[day.value]) {
            tempSlots[day.value] = {
              slotTimes: {}
            };
          }
        });

         this.weeklyData = tempSlots;
      });
    },
    fetchSpecificSlots() {
      expertService.getExpertSpecificSlots(this.expertId).then((response) => {
        this.specificAvailability = [];
        response.data.data?.map((item) => {
          this.specificAvailability.push({
            id: item.uuid,
            dates: [{
              date: item.date
            }],
            slotTimes: item.slot_times
          });
        });
      });
    },
    checkDaySlot(day) {
      if (Object.keys(this.weeklyData[day].slotTimes).length > 0) {
        this.weeklyData[day].slotTimes = {};
      } else {
        this.weeklyData[day].slotTimes = {
          1: {
            startTime: "",
            endTime: ""
          }
        };
      }
    },
    checkDaySelected(day) {
      return !!Object.keys(this.weeklyData[day].slotTimes).length > 0;
    },
    addNewSlot(day) {
      const lastSlot = Object.keys(this.weeklyData[day].slotTimes).length;
      this.weeklyData[day].slotTimes[lastSlot + 1] = {
        startTime: "",
        endTime: ""
      };
    },
    getTimeEndOptions(startTime) {
      if (!startTime) {
        return [];
      }

      // Parse the start time
      const [hours, minutes] = startTime.split(':').map(Number);
      const baseDate = new Date();
      baseDate.setHours(hours, minutes, 0, 0);

      const options = [];
      const intervals = [15, 30, 45, 60]; // minutes to add

      intervals.forEach(interval => {
        const newDate = new Date(baseDate.getTime() + interval * 60000);
        const newHours = newDate.getHours().toString().padStart(2, '0');
        const newMinutes = newDate.getMinutes().toString().padStart(2, '0');
        const timeString = `${newHours}:${newMinutes}`;

        // Only add the option if it's not past 22:00
        if (newHours < 23 || (newHours === 23 && newMinutes === '00')) {
          options.push({
            text: timeString,
            value: timeString
          });
        }
      });

      return options;
    },
    handleSlotDelete(dayIndex, index) {
      delete this.weeklyData[dayIndex].slotTimes[index];
    },
    hideSpecificModal() {
      this.specificModalVisible = false;
    },
    handleSave() {
      this.isLoading = true;

      console.log(this.weeklyData);

      expertService.updateSlot({
        expertId: this.expertId,
        type: 'recurring',
        slots: this.weeklyData
      }).then(() => {
        this.isLoading = false;
        this.onHide();
      });
    },
    handleSpecificSave(data) {
      this.isLoading = true;

      expertService.addSpecificSlot({
        expertId: this.expertId,
        type: 'onetime',
        slots: [
          {
            dates: data.dates.map((date) => {
              return date.date;
            }),
            timeSlots: data.slotTimes
          }
        ]
      }).then(() => {
        this.isLoading = false;
        this.specificModalVisible = false;
        this.fetchSlots();
        this.fetchSpecificSlots();
      }).catch(error => {
        this.isLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    handleSpecificDateDelete(itemId) {
      expertService.deleteSlot(itemId).then(() => {
        this.fetchSpecificSlots();
      });
    }
  },
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 70%;
  height: 100%;
  border-radius: 12px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}

.modal-body{
  display: flex;
  flex-direction: row;
  padding: 0;
}

.modal-body .left{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  padding: 30px;
}

.modal-body .right{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  padding: 30px;
}

.separator{
  width: 1px;
  background-color: #EAECF0;
  margin: 0 10px 0 10px;
  height: 100%;
}

h3{
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 18px;
  color: #040C2D;
}

h5{
  font-family: 'euclid_medium', sans-serif;
  font-size: 14px;
  color: #667085;
}

.day-wrapper{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.days-list{
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.day-left{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  flex: 1;
}

.day-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  flex: 2;
}

.day-name{
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.time-wrapper{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.row-separator{
  height: 2px;
  background-color: #D0D5DD;
  width: 8px;
  align-self: center;
}

.add-new-slot img {
  width: 16px;
  height: 16px;
}

.add-new-slot{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  border: none;
  padding: 5px;
  border-radius: 50%;
  background-color: #F2F4F7;
  outline: none;
}

.add-new-slot:hover{
  background-color: #EAECF0;
}

.add-specific-button {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 12px;
  border: 1px solid #4A68F8;
  background-color: transparent;
  outline: none;
  width: 100%;
  align-self: flex-start;
  justify-content: center;
  transition: background-color 0.3s;
}

.add-specific-button:hover {
  background-color: #EAECF0;
}

.add-specific-button img {
  width: 16px;
  height: 16px;
}

.add-specific-button span {
  font-family: 'euclid_regular', sans-serif;
  font-size: 12px;
  color: #4A68F8;
}

.modal-footer{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  border-top: 1px solid #EAECF0;
  padding: 5px;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.modal-footer .save-info {
  color: #667085;
  font-family: 'euclid_medium', sans-serif;
  font-size: 14px;
}

.specific-availability{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.date-wrapper{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.slot-list{
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
}

.slot-list span{
  font-family: 'euclid_regular', sans-serif;
  font-size: 12px;
  color: #040C2D;
}

.date-left{
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.date-left span{
  font-family: 'euclid_medium', sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.date-right{
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}
</style>
