<template>
  <div class="question-holder">
    <div class="content-header">
      <span class="module-header">{{ submodule.name }}</span>
      <span v-if="selectedModule.content?.type==='number'|| selectedModule.content?.type==='matchType'" class="welcome-text">{{ $t("question_type_program") }}</span>
      <span v-if="selectedModule.content?.type==='text'" class="welcome-text">{{ $t("writing") }}</span>
      <span v-if="selectedModule.content?.type==='rating' || selectedModule.content.type==='grading'" class="welcome-text">{{ $t("grading") }}</span>
      <span v-if="selectedModule.content?.type==='dragAndDrop'" class="welcome-text">{{ $t("drag_and_drop_preview") }}</span>
      <span v-if="selectedModule.content?.type==='swipe'" class="welcome-text">{{ $t("swipe_type") }}</span>

      <div class="progress-container">
        <div class="progress-bar"></div>
      </div>
    </div>
    <div v-if="(selectedModule.content?.attach.length >= 3) && selectedModule.content?.type!=='swipe'" class="question-image-holder">
      <img
          v-for="image in selectedModule.content?.attach"
          v-if="image"
          :key="image.attachUrl"
          :src="image.attachUrl"
          class="question-image"
      />

    </div>
    <div v-if="(selectedModule.content?.attach.length < 3 && selectedModule.content?.attach.length > 0) && selectedModule.content?.type!=='swipe' " class="question-image-less-holder">
      <img
          v-for="image in selectedModule.content?.attach"
          v-if="image"
          :key="image.attachUrl"
          :src="image.attachUrl"
          class="question-less-image"
      />
    </div>
    <div class="number-header">{{ selectedModule.name }}</div>
    <span class="swipe-description" v-if="selectedModule.content?.type==='swipe'">{{selectedModule.description}}</span>
    <div v-if="selectedModule.content?.type==='swipe'" class="swipe-image">
      <img class="question-image" :src="selectedModule.content?.attach[0].attachUrl"/>
    </div>

    <div v-if="selectedModule.content?.type==='number'" class="input-wrapper">
      <span class="minus">
        <img class="icon" src="@/assets/images/minus.svg" />
      </span>
      <span class="number-input">|</span>
      <span class="plus">
        <img class="icon" src="@/assets/images/plusIcon.svg" />
      </span>
    </div>

    <div v-if="selectedModule.content?.type==='text'"  class="text-input">
      <span>{{selectedModule.content?.settings?.placeholder || "Placeholder"}}</span>
    </div>
    <div v-if="selectedModule.content?.type==='text'" class="text-limit">
      <span class="text-limit-text">{{selectedModule.content?.settings.characterLimit?.min}} / {{selectedModule.content?.settings.characterLimit?.max}} </span>
    </div>


    <div v-if="selectedModule.content?.type==='rating' ||selectedModule.content?.type==='grading' " class="rating-holder">
      <span>{{ $t("rate") }}</span>
      <div v-if="selectedModule.content.type==='rating'" class="star-holder">
        <img
            v-for="star in selectedModule.content.settings.limitedRange.max"
            src="@/assets/images/emptyStar.svg"
            class="star-icon"/>
      </div>
      <div v-if="selectedModule.content.type==='grading'" class="grader">
        <span class="rate-number">{{selectedModule.content.settings.limitedRange.max/2}}</span>
      <div v-if="selectedModule.content.type==='grading'" class="gradient-linear">

        <img src="@/assets/images/thumb.png" class="thumb-img" />
      </div>
      </div>
    </div>

    <div v-if="selectedModule.content?.type==='matchType'" class="match-area">
      <div v-for="option in selectedModule.content?.options" class="match-row">
        <div class="match-cell">
          <img v-if="selectedModule.content.settings.leftPartImage===1"
          :src="option.attachLeftUrl" class="match-image"/>
          <span class="match-text">{{option.leftValue}}</span>
        </div>
        <div class="match-cell">
          <img v-if="selectedModule.content.settings.rightPartImage===1"
               :src="option.attachRightUrl" class="match-image"/>
          <span class="match-text">{{option.rightValue}}</span>
        </div>
      </div>
    </div>

    <div v-if="selectedModule.content?.type==='dragAndDrop'" v-for="option in selectedModule.content.options" class="drag-holder">
      <img class="drag-icon" src="@/assets/images/dragIcon.svg">
      <span class="drag-option-text">{{option.name}}</span>
    </div>
    <div class="button-holder">
      <span class="back-button">{{ $t("go_back_button") }}</span>
      <span @click="goForward" class="button">{{ $t("next_question") }}</span>
    </div>

  </div>
</template>

<script>
export default {
  props: {

    submodule: {
      type: Object,
      required: true,
    },
    selectedModule: {
      type: Object,
      required: true,
    },
    goForward: {
      type: Function,
    },
  },
};
</script>

<style scoped>
.match-image{
  align-self: center;
  width: 100%;
  aspect-ratio: 1/1;
}
.match-text{
  display: flex;
  justify-self: center;
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;

}
.match-cell{
  padding: 10px;
  width: 50%;
  height: 100%;
  background-color:#F5F5F5;
  border-radius: 5px;
  display: inline-block;
  word-wrap: break-word;
  white-space: normal;
  line-height: 12px;
}
.match-row{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  gap:5px;
}
.match-area{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
  align-items: center;
  gap: 5px;
}
.drag-option-text{
  font-family: 'euclid_regular', sans-serif;
  color: #000;
  align-self: center;
  font-size: 10px;
}
.drag-icon{
  width: 12px;
}
.drag-holder{
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  background-color:#F5F5F5;
  border-radius: 5px;
  width: 90%;
  align-self: center;
}
.rate-number{
  color: #000000;
  font-size: 12px;
}
.grader{
  gap:3px;
  margin-top: 0.2em;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.thumb-img{
  width: 15px;

}
.gradient-linear {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8px;
  width: 100%;
  border-radius: 15px;
  background: linear-gradient(
      90deg,
      #BED8E2 0%,
      #B4C0A5 25%,
      #FB7F43 50%,
      #A84383 75%,
      #6943CE 100%
  );
}
.star-icon{
width: 15px;
}
.star-holder{
  display: grid;
  grid-template-columns: repeat(10, minmax(0px, 1fr));
}
.rating-holder{
  padding:1.2em;
  gap: 1em;
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  border-radius: 5%;
  color: #8c8c8c;
  font-size: 9px;
  margin: 5%;
}
.text-input{
  margin: 0.5em;
  align-content: center;
  color: #8c8c8c;
  font-size: 0.7em;
  padding: 1em;
  border-radius: 5%;
  background-color:#F5F5F5;
  height: 15%;
}
.question-image {
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 10%;
  width: 100%;
}
.question-less-image{
  aspect-ratio: 1.5/1;
  object-fit: cover;
  border-radius: 5%;
  width: 100%;
}
.question-image-less-holder {
  margin-top: 10px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  gap: 10px;
  justify-content: center;
}
.question-image-holder {
  margin-top: 10px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  gap: 10px;
  justify-content: center;
}
.back-button {
  padding: 5px 10px 5px 10px;
  color: black;
  border-radius: 7px;
  border: 1px black solid;
  cursor: pointer;
}
.button {
  text-align: center;
  background-color: #1c3cd5;
  border-radius: 7px;
  padding: 5px 10px 5px 10px;
  color: white;
  cursor: pointer;
}
.button-holder {
  justify-self: end;
  font-size: 11px;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 2px #f5f5f5 solid;
  width: 100%;
  padding: 10px;
  padding-top: 7px;
  padding-bottom: 4px;
}
.icon{
  width: 10px;
  height: 10px;
}
.plus{
  padding-left: 5px;
  padding-right: 5px;
  height: 30px;
  background-color: #FCFCFD;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  align-items: center;
  justify-content: center;
  //paddingHorizontal: 10px;
  border-left-width: 0px !important;
  border: solid 1px #D0D5DD;
}
.minus{
  padding-left: 5px;
  padding-right: 5px;
  height: 30px;
  background-color: #FCFCFD;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  align-items: center;
  justify-content: center;
  //paddingHorizontal: 10px;
  border-right-width: 0px !important;
  border: solid 1px #D0D5DD;
}
.input-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px
}
.number-input{
  width: 70%;
  height: 30px;
  border: solid 1px #D0D5DD;
  text-align: center;
  font-size: 20px
}
.number-header {
  font-family: 'euclid_semi_bold', sans-serif;
  color: #040C2D;
  font-size: 1em;
  margin-top: 10px;
  margin-left: 10px;
}
.progress-bar {
  height: 100%;
  background-color: #4a68f8;
  width: 20%;
  transition: width 0.3s ease-in-out;
}
.progress-container {
  display: flex;
  width: 100%;
  height: 3.5px;
  background-color: #ececec;
  align-self: center;
  margin-top: 10px;
}
.welcome-text {
  text-align: center;
  font-family: 'euclid_regular', sans-serif;
  font-size: 0.5em;
  color: #9194a3;
}
.module-header {
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 0.8em;
  color: #040c2d;
  text-align: center;
}
.content-header {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.question-holder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.question-holder::-webkit-scrollbar {
  width: 0px;
}

.text-limit{
  display: flex;
  align-self: flex-end;
  padding: 5px;
  padding-right: 10px !important;
}

.text-limit-text{
  font-size: 8px;
  font-family: 'euclid_regular', sans-serif;
}
.swipe-description{
  margin-left: 10px;
  font-family: 'euclid_medium', sans-serif;
  font-size: 8px;
  color:#6B7C93;
}

.swipe-image{
  height: 100%;
  padding: 15px;
}

</style>