<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt">{{$t('template_settings')}}</span>
          </div>

          <div class="question-column">
            <span>{{$t('send_in')}}</span>
          </div>
          <div class="question-row">
            <input
                class="question-input"
                :placeholder="$t('delay_time')"
                :value="settings.delay?.value ? settings.delay.value : ''"
                @change="handleDelayValue"
            />
            <SelectBox
                class="select-box"
                placeholder="Day's"
                :full-width="true"
                :selected-value="settings.delay?.type ? settings.delay.type : 'day'"
                :on-select="handleDelayUnit"
                :options="[
                { text: 'Minutes', value: 'minute' },
                { text: 'Hours', value: 'hour' },
                { text: 'Days', value: 'day' }
              ]"
            />
          </div>


          <div class="setting-wrapper">
            <div class="setting-text-row">
              <span class="setting-name">{{$t('show_pending')}}</span>
              <span class="setting-text">{{$t('show_waiting_screen')}}.</span>
            </div>
            <Switch v-model="settings.waitFor" />
          </div>

          <div class="pending-input" v-if="settings.waitFor">
            <input
                class="question-input mb-2"
                :value="settings.pending?.title ? settings.pending.title : ''"
                @change="handlePendingTitle"
                :placeholder="$t('title')"
            />
            <input
                class="question-input mb-2"
                :value="settings.pending?.description ? settings.pending.description : ''"
                @change="handlePendingDescription"
                :placeholder="$t('description')"
            />
          </div>

          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Switch from "@/components/Switch.vue";
import SelectBox from "../SelectBox.vue";

export default {
  components: {
    Switch,
    SelectBox,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    onHide: {
      type: Function,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleDelayValue(e) {
      if (!this.settings.delay) {
        this.settings.delay = {
          value: e.target.value,
          type: 'day',
        };

        return;
      }

      this.settings.delay.value = e.target.value;
    },
    handleDelayUnit(unit) {
      if (!this.settings.delay) {
        this.settings.delay = {
          ...this.settings.delay,
          type: unit,
        };

        return;
      }

      this.settings.delay.type = unit;
    },
    handlePendingTitle(e) {
      if (!this.settings.pending) {
        this.settings.pending = {
          title: e.target.value,
          description: '',
        };

        return;
      }

      this.settings.pending.title = e.target.value;
    },
    handlePendingDescription(e) {
      if (!this.settings.pending) {
        this.settings.pending = {
          title: '',
          description: e.target.value,
        };

        return;
      }

      this.settings.pending.description = e.target.value;
    },

  },
};
</script>



<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 99999;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}

.cross {
  padding: 8px;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0 20px 0;
  border: none;
}

.modal-header-txt {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.pending-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 30px 0;
}

.setting-text-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.setting-name {
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.setting-text {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.question-input,
.answer-input {
  border: none;
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #F5F5F5;
  color: #282A36;
  padding: 15px;
  border-radius: 8px;
}

.question-input {
  width: 100%;
}

.question-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.question-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.question-row .question-input,
.question-row .select-box {
  flex: 1;
  max-width: 50%;
}

.question-row .question-input {
  border: none;
  outline: none;
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  background-color: #F5F5F5;
  color: #282A36;
  padding: 15px;
  border-radius: 8px;
}

.question-row .select-box {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  border-radius: 8px;
  padding: 10px;
}

.save-button-container {
  position: absolute;
  bottom: 24px;
  right: 24px;
}

.save-button {
  background-color: #040c2d;
  width: 164px;
  height: 48px;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.save-button:hover {
  background-color: #040c2d;
}

</style>
