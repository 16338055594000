<template>
  <Layout>

    <div class="chat-wrapper">
      <div class="chat-list scroll-design">

        <div class="no-messages-wrapper" v-if=" !chatLoading && chatData.length === 0">
          <span>{{$t('no_messages_fount')}}</span>
        </div>
        <div class="chat-list-item" v-if="!chatLoading" v-for="item in chatData" :class="{'active': selectedChat.id === item.id}" @click="handleChatClick(item)">
          <div class="left-border" v-if="selectedChat.id === item.id"></div>
          <img :src="getChatAvatar(item)" class="chat-avatar" />
          <div class="chat-info">
            <span class="chat-name">{{getParticipantNames(item)}}</span>
            <span class="last-message">{{getLastMessage(item)}}</span>
            <span class="chat-date">{{getLastMessageDate(item)}}</span>
          </div>
          <div class="badge orange top-right" v-if="getUnreadMessageCount(item) > 0">{{getUnreadMessageCount(item)}}</div>
        </div>

        <div class="chat-list-item" v-if="chatLoading">
          <free-style-shimmer height="64px" width="64px" border-radius="50%" color="#EAECF0" />

          <div class="chat-info">
            <div class="chat-name">
              <free-style-shimmer  height="10px" width="40%" border-radius="12px" color="#EAECF0" />
            </div>
            <div class="last-message">
              <free-style-shimmer  height="8px" width="80%" border-radius="12px" color="#EAECF0" />
            </div>
          </div>
        </div>

        <div class="chat-list-item" v-if="chatLoading">
          <free-style-shimmer height="64px" width="64px" border-radius="50%" color="#EAECF0" />

          <div class="chat-info">
            <div class="chat-name">
              <free-style-shimmer  height="10px" width="40%" border-radius="12px" color="#EAECF0" />
            </div>
            <div class="last-message">
              <free-style-shimmer  height="8px" width="80%" border-radius="12px" color="#EAECF0" />
            </div>
          </div>
        </div>

        <div class="chat-list-item" v-if="chatLoading">
          <free-style-shimmer height="64px" width="64px" border-radius="50%" color="#EAECF0" />

          <div class="chat-info">
            <div class="chat-name">
              <free-style-shimmer  height="10px" width="40%" border-radius="12px" color="#EAECF0" />
            </div>
            <div class="last-message">
              <free-style-shimmer  height="8px" width="80%" border-radius="12px" color="#EAECF0" />
            </div>
          </div>
        </div>

      </div>

      <div class="chat" v-if="!chatLoading">
        <div class="chat-header" v-if="!messagesLoading && selectedChat">
          <img :src="getChatAvatar(selectedChat)" class="chat-avatar" />
          <div class="chat-info">
            <span class="chat-name">{{getParticipantNames(selectedChat)}}</span>
            <span class="chat-date">{{ getLastMessageDate(selectedChat) }}</span>
          </div>
        </div>

        <div class="chat-content scroll-design"  v-if="!messagesLoading && selectedChat">
          <div class="load-more-wrapper" v-if="activeChatHasMore">
            <button class="load-more-button" @click="handleLoadMore">
              {{$t('load_more')}}
            </button>
          </div>

          <div class="load-more-wrapper" v-if="moreLoading">
            <VueSpinnerIos />
          </div>

          <div class="chat-message" v-for="item in currentMessages" :class="isMyMessage(item) ? 'my-message' : 'incoming'">
            <div class="chat-message-wrapper" :class="isMyMessage(item) ? 'my-message' : 'incoming'">
              <span class="chat-message-text">{{item.content}}</span>
              <span class="chat-message-time">{{this.$moment(item.createdAt).fromNow()}}</span>
            </div>
          </div>
        </div>

        <div class="chat-content not-selected" v-if="!selectedChat">
          <span>{{$t('no_conversation_selected')}}</span>
        </div>

        <div class="chat-content not-selected" v-if="messagesLoading">
          <span>{{$t('messages_loading')}}</span>
        </div>


        <div class="chat-footer" v-if="!messagesLoading && selectedChat">
          <input class="chat-input" placeholder="Type your message here" v-model="messageContent" :disabled="sendLoading" />
          <button class="send-button" @click="addMessage" :disabled="sendLoading">
            <img src="@/assets/images/sendArrow.svg" v-if="!sendLoading"/>
            <VueSpinnerIos v-if="sendLoading" size="20" height="100%" color="#fff" />
          </button>
        </div>
      </div>

      <div class="chat-loading" v-else>
        <VueSpinnerIos :size="24" />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import socketChatService from "@/service/socketChatService";
import {handleErrorResponse} from "@/utils/utils";
import {VueSpinnerIos} from "vue3-spinners";
import chatSocket from "@/utils/chatSocket";

export default {
  components: {
    VueSpinnerIos,
    Layout
  },
  data() {
    return {
      chatSocket,
      currentChatPage: 1,
      chatLoading: false,
      chatData: [],
      messagesLoading: false,
      selectedChat: {},
      currentMessages: [],
      sendLoading: false,
      messageContent: '',
      activeChatPageIndex: 1,
      activeChatHasMore: false,
      moreLoading: false,
    }
  },
  mounted() {
    this.getChatList();

    chatSocket.connect();
  },
  unmounted() {
    chatSocket.disconnect();
  },
  watch: {
    'chatSocket.state.messages': {
      handler: function (val) {
        val.map((data) => {
          if (this.currentMessages.length > 0 && this.currentMessages.find((message) => message.id === data.message.id)) {
            return;
          }
          this.currentMessages.push(data.message);
          this.scrollToBottom();
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getChatList() {
      this.chatLoading = true;

      socketChatService.getChatList(this.currentChatPage).then((response) => {
        this.chatData = response.data.chats;
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.chatLoading = false;
      });
    },
    getParticipantNames(chat) {
      const userId = JSON.parse(localStorage.getItem('user'))?.userId;

      if (!chat.participantDetails) {
        return 'Unknown';
      }


      const participants = chat.participantDetails.filter(
          chatUsers => chatUsers.userId !== userId
      );

      if (participants.length === 0) {
        return 'Unknown';
      }

      if (participants.length > 3) {
        return `${participants[0].name}, ${participants[1].name}, ${
            participants[2].name
        } and ${participants.length - 3} more`;
      }

      return participants.map(participant => participant.name).join(', ');
    },

    getReceiverId(chat) {
      const userId = JSON.parse(localStorage.getItem('user'))?.userId;

      if (!chat.participants) {
        return '';
      }

      const participants = chat.participants.filter(
          chatUsers => chatUsers !== userId
      );
      return participants[0] || '';
    },

    getChatAvatar(chat) {
      const userId = JSON.parse(localStorage.getItem('user'))?.userId;

      if (!chat.participantDetails) {
        return 'https://api.ibilityapp.com/img/ibility.jpeg';
      }

      const participants = chat.participantDetails.filter(
          chatUsers => chatUsers.userId !== userId
      );
      return participants[0]?.avatarUrl || 'https://api.ibilityapp.com/img/ibility.jpeg';
    },

    getUnreadMessageCount(chat) {
      if (!chat.messages) {
        return 0;
      }

      if (chat.messages.length === 0) {
        return 0;
      }

      const unreadMessages = chat.messages.filter(message => !message.isRead);

      return unreadMessages.length > 9 ? '9+' : unreadMessages.length;
    },

    getChatMessages(chatId, page, loadMore = false) {
      if (this.messagesLoading) {
        return;
      }

      if (loadMore) {
        this.moreLoading = true;
      } else {
        this.messagesLoading = true;
      }

      this.activeChatHasMore = false;
      this.activeChatPageIndex = page;

      socketChatService.getChat(chatId, page).then((response) => {
        this.currentMessages = [
          ...response.data.messages,
          ...this.currentMessages,
        ];

        this.activeChatHasMore = response.data.pagination.hasNextPage;

        if (!loadMore) {
          this.scrollToBottom();
        }
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.messagesLoading = false;
        this.moreLoading = false;
      });
    },

    handleLoadMore() {
      this.moreLoading = true;
      this.getChatMessages(this.selectedChat.id, this.activeChatPageIndex + 1, true);
    },

    handleChatClick(chat) {
      if (this.messagesLoading) {
        return;
      }

      chatSocket.joinRoom(chat.id);
      this.selectedChat = chat;
      this.getChatMessages(chat.id, 1);
    },

    getLastMessage(chat) {
      if (!chat.messages) {
        return '';
      }

      if (chat.messages.length === 0) {
        return '';
      }

      return chat.messages[0].content.substring(0, 50) + '...';
    },
    getLastMessageDate(chat) {
      if (!chat.messages) {
        return '';
      }

      if (chat.messages.length === 0) {
        return '';
      }

      return this.$moment(chat.messages[0].createdAt).fromNow();
    },

    isMyMessage(message) {
      const userId = JSON.parse(localStorage.getItem('user'))?.userId;
      return message.senderId === userId;
    },

    addMessage() {
      if (!this.messageContent) {
        return;
      }

      if (!this.selectedChat?.id) {
        return;
      }

      this.sendLoading = true;

      socketChatService.sendMessage(this.selectedChat.id, this.messageContent).then(() => {
        this.messageContent = '';
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.sendLoading = false;
      });
    },
    scrollToBottom() {
      const chatContent = document.querySelector('.chat-content');
      if (chatContent) {
        chatContent.scrollTop = chatContent.scrollHeight;
      }
    }
  }
}
</script>

<style scoped>

.chat-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.chat-list {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  height: 600px;
  overflow-y: auto;
}

.chat-list-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 16px;
  border-radius: 12px;
  background-color:  transparent;
  cursor: pointer;
}

.chat-list-item.active {
  background-color: #EFF5F5;
}

.chat-list-item .left-border {
  position: absolute;
  height: 100%;
  width: 6px;
  background-color: #3DA33E;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  top: 0;
  left: 0;
}

.chat-list-item .chat-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.chat-info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
}

.chat-info .chat-name {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
  line-height: 1;
}

.chat-info .last-message {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #667085;
  line-height: 1;
}

.chat-info .chat-date {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #98A2B3;
  line-height: 1;
}

.chat {
  width: 65%;
  height: 600px;
}

.chat-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  padding: 0 15px;
  height: 70px;
}

.chat-header .chat-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat-content {
  width: 100%;
  height: calc(100% - 150px);
  overflow-y: auto;
  padding: 20px;
  background-color: #FCFCFC;
  border-left: 1px solid #EAECF0;
  border-right: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.no-messages-wrapper{
  border: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: calc(100% - 150px);
  color: #667085;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
}

.chat-content.not-selected {
  border: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.chat-content.not-selected span {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
}

.chat-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  padding: 0 15px;
  height: 80px;
}

.chat-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.chat-message.incoming {
  align-items: flex-start;
}


.chat-message-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-message-wrapper.incoming {
  align-items: flex-start;
}


.chat-message-text {
  background-color: #EAECF0;
  color: #101828;
  padding: 5px 15px;
  border-radius: 24px;
  font-size: 14px;
}

.chat-message-wrapper.incoming .chat-message-text {
  background-color: #4A68F8;
  color: #FFFFFF;
}

.chat-message-time {
  font-size: 12px;
  color: #98A2B3;
  margin-right: 8px;
  margin-left: 8px;
}

.chat-input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #F5F5F5;
  padding: 15px;
  font-family: euclid_medium, sans-serif;
  color: #010511;
}

.send-button {
  background-color: #4A68F8;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-button img {
  height: 22px;
  width: 22px;
}

.badge {
  background-color: #3DA33E;
  border-radius: 24px;
  color: #FFFFFF !important;
  font-family: euclid_medium, sans-serif;
  font-size: 10px !important;
  padding: 4px 10px;
  margin-left: 5px;
}

.badge.gray {
  background-color: #EAECF0;
  color: #667085 !important;
}

.badge.orange {
  background-color: #F09460;
}

.badge.top-right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.load-more-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.load-more-button {
  background-color: #4A68F8;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 5px 10px;
  color: #fff;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
}


.chat-loading {
  width: 65%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
