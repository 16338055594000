<template>
  <Layout>
    <div>
      <TagTable :on-log-action="showLogModal" :loading="isLoading" :tags="tags" :add-new-tag="() => tagModalVisible = true" :edit-tag="handleEdit" :delete-tag="handleDeleteTag" />
      <LogModal
          :visible="isLogModalVisible"
          :on-hide="hideLogModal"
          :data="logData"
      />
    </div>

    <TagModal :is-visible="tagModalVisible" :on-close="() => tagModalVisible = false" :refresh-callback="refreshData" />
    <TagModal :is-visible="tagEditModalVisible" :on-close="() => tagEditModalVisible = false" :edit-mode="true" :edit-data="editData" :refresh-callback="refreshData" />
    <ConfirmDeleteModal :is-visible="confirmDeleteModalVisible" :on-close="onTagDeleteCancel" message="Tag will be deleted permanently" :on-action="handleDeleteRequest" />

  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import TagTable from "@/components/tables/TagTable.vue";
import tagService from "@/service/tagService";
import TagModal from "@/components/modals/TagModal.vue";
import ConfirmDeleteModal from "@/components/modals/ConfirmDeleteModal.vue";
import {handleErrorResponse} from "../utils/utils";
import LogModal from "@/components/modals/LogModal.vue";

export default {
  components: {
    LogModal,
    ConfirmDeleteModal,
    TagModal,
    TagTable,
    Layout
  },
  data() {
    return {
      logData:[],
      isLogModalVisible:false,
      isLoading: true,
      tags: [],
      currentPage: 1,
      tagModalVisible: false,
      tagEditModalVisible: false,
      confirmDeleteModalVisible: false,
      editData: {},
      deleteId: null,
    }
  },
  mounted() {
    this.getTags(1);
    this.getLogs(this.$i18n.locale)
  },
  methods: {
    getLogs(lang){
      tagService.getLogs(lang).then((res) => {
        this.logData=res.data.data.data;
      })
    },
    showLogModal(){
      this.isLogModalVisible=true;
    },
    hideLogModal(){
      this.isLogModalVisible=false;
    },
    getTags(page) {
      this.isLoading = true;
      tagService.getTags(page).then(response => {
        this.tags = response.data.data.data;
        this.isLoading = false;
      })
    },
    refreshData() {
      this.getTags(this.currentPage);
    },
    handleEdit(data) {
      this.editData = data;
      this.tagEditModalVisible = true;
    },
    handleDeleteTag(tagId) {
      event.stopPropagation();
      this.deleteId = tagId;
      this.confirmDeleteModalVisible = true;
    },
    onTagDeleteCancel() {
      this.confirmDeleteModalVisible = false;
      this.deleteId = null;
    },
    handleDeleteRequest() {
      if (!this.deleteId) {
        return;
      }

      this.confirmDeleteModalVisible = false;
      this.isLoading = true;

      tagService.deleteTag(this.deleteId).then(response => {
        this.deleteId = null;
        this.refreshData();

        this.$snackbar.add({
          text:this.$t('tag_deleted'),
          type:'error',
        })
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar)
      }).finally(() => {
        this.isLoading = false;
      })
    }
  },

}

</script>

<style scoped>

</style>
