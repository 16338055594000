<script setup>
import {ref, computed, watch} from "vue";

const props = defineProps(['options', 'selectedValue', 'placeholder', 'onSelect', 'loading', 'alignRight', 'fullWidth', 'size', 'searchEnabled']);
const dropdownVisible = ref(false);
const searchValue = ref('');
const filteredOptions = ref([]);

watch(() => props.options, (newOptions) => {
  filteredOptions.value = newOptions || [];
}, { immediate: true });

watch(searchValue, (value) => {
  if (value && props.options) {
    filteredOptions.value = props.options.filter(option =>
        option.text.toLowerCase().includes(value.toLowerCase())
    );
  } else {
    filteredOptions.value = props.options || [];
  }
});

const handleChange = (value) => {
  dropdownVisible.value = false;
  searchValue.value = '';
  props.onSelect(value);
};

const handleClickOutside = () => {
  dropdownVisible.value = false;
  searchValue.value = '';
}

const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
}

const findSelected = (val) => {
  if (!props.options) {
    return '';
  }

  if (props.options.length === 0) {
    return '';
  }

  const option = props.options.find(item => item.value === val);
  return option ? option.text : '';
}
</script>

<template>
  <div class="select-box-wrapper no-text-select" :class="[fullWidth && 'full-width', size === 'small' && 'small', size === 'medium' && 'medium']" v-click-outside="handleClickOutside">

    <div class="select-custom" @click="toggleDropdown">
      <span class="select-placeholder" v-if="!selectedValue">{{ loading ? "Loading..." : placeholder }}</span>
      <span class="select-selected" v-else>{{findSelected(selectedValue)}}</span>
      <img src="@/assets/images/arrowdown.svg" />
    </div>

    <div class="select-custom-content" v-if="dropdownVisible" :class="alignRight ? 'align-right' : 'align-left'">
      <input
          v-if="searchEnabled"
          type="text"
          class="search-input"
          :placeholder="$t('search')"
          v-model="searchValue"
          @click.stop
      />

      <div v-if="filteredOptions?.length > 0" class="select-custom-item" v-for="option in filteredOptions" :key="option.value" :class="option.value === selectedValue && 'selected'" @click="handleChange(option.value)">
        {{ option.text }}
      </div>

      <div v-else class="select-custom-item">
        <span>{{$t('no_option_found')}}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.full-width, .full-width .select-custom, .full-width .select-custom-content {
  width: 100%;
}

.select-box-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.select-custom {
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: #F5F5F5;
  padding: 15px 0 15px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.small .select-custom {
  padding: 5px 0 5px 10px;
}

.medium .select-custom {
  padding: 10px 0 10px 15px;
}

.select-custom span {
  font-size: 14px;
  font-family: 'euclid_regular', sans-serif;
  padding-right: 10px;
}

.small .select-custom span {
  font-size: 12px;
}

.medium .select-custom span {
  font-size: 14px;
}

.select-custom .select-placeholder {
  color: #717171;
  text-wrap: nowrap;
}

.select-custom .select-selected {
  font-family: 'euclid_medium', sans-serif;
  color: #434343;
  text-wrap: nowrap;
}

.select-custom img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}

.select-custom-content {
  position: absolute;
  top: calc(100% + 5px);
  width: 200px;
  border: 1px solid #EAECF0;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  z-index: 99999;
  max-height: 200px;
  overflow-y: auto;
}

.search-input {
  padding: 8px 12px;
  border: 1px solid #EAECF0;
  border-radius: 6px;
  margin-bottom: 8px;
  font-family: 'euclid_regular', sans-serif;
  font-size: 12px;
  outline: none;
}

.search-input:focus {
  border-color: #040C2D;
}

.select-custom-content.align-right {
  right: 0;
}

.select-custom-content.align-left {
  left: 0;
}

.select-custom-item {
  padding: 10px;
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  cursor: pointer;
  border-radius: 8px;
}

.select-custom-item:hover {
  background-color: #F5F7FA;
}

.select-custom-content::-webkit-scrollbar {
  width: 15px;
}

.select-custom-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #fff;
}

.select-custom-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.select-custom-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}
</style>
