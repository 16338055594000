<template>
  <div class="question-holder">
    <div class="content-header">
      <span class="module-header">{{ submodule.name }}</span>
      <span class="welcome-text">{{ $t("question_type_program") }}</span>
      <div class="progress-container">
        <div class="progress-bar"></div>
      </div>
    </div>
    <div v-if="selectedModule.content?.attach.length >= 3" class="question-image-holder">
      <img
          v-for="image in selectedModule.content?.attach"
          v-if="image"
          :key="image.attachUrl"
          :src="image.attachUrl"
          class="question-image"
      />

    </div>
    <div v-if="selectedModule.content?.attach.length < 3 && selectedModule.content?.attach.length > 0" class="question-image-less-holder">
      <img
          v-for="image in selectedModule.content?.attach"
          v-if="image"
          :key="image.attachUrl"
          :src="image.attachUrl"
          class="question-less-image"
      />

    </div>
    <div class="question-marker">Soru</div>
    <div class="question-title">{{ selectedModule.name }}</div>
    <div class="option-holder">
      <div v-for="option in selectedModule.content.options" class="option-frame">
        <img v-if="option.attachUrl"
            :key="option.attachUrl"
            :src="option.attachUrl"
            class="option-image"
        />
        <div class="check-holder">
          <img v-if="selectedModule?.content?.type==='single' || selectedModule?.content?.type==='yesNo' " src="@/assets/images/checkSingle.svg" style="width: 10px" />
          <img v-if="selectedModule?.content?.type==='multiple'" src="@/assets/images/checkMultiple.svg" style="width: 10px" />
          <span class="option-text">{{ option.name }}</span>
        </div>
      </div>
      <div v-if="selectedModule?.content?.type==='yesNo'" class="option-frame">

        <div class="check-holder">
          <img v-if="(selectedModule?.content?.type==='single' || selectedModule?.content?.type==='yesNo')" src="@/assets/images/checkSingle.svg" style="width: 10px" />
          <span class="option-text">{{ $t("other") }}</span>
        </div>
      </div>
    </div>
    <div class="button-holder">
      <span class="back-button">{{ $t("go_back_button") }}</span>
      <span @click="goForward" class="button">{{ $t("next_question") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {

    submodule: {
      type: Object,
      required: true,
    },
    selectedModule: {
      type: Object,
      required: true,
    },
    goForward: {
      type: Function,
    },
  },
};
</script>

<style scoped>
.back-button {
  padding: 5px 10px 5px 10px;
  color: black;
  border-radius: 7px;
  border: 1px black solid;
  cursor: pointer;
}
.button {
  text-align: center;
  background-color: #1c3cd5;
  border-radius: 7px;
  padding: 5px 10px 5px 10px;
  color: white;
  cursor: pointer;
}
.button-holder {
  justify-self: end;
  font-size: 11px;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 2px #f5f5f5 solid;
  width: 100%;
  padding: 10px;
  padding-top: 7px;
  padding-bottom: 4px;
}
.option-text {
  width: 70%;
  word-wrap: break-word; /* Uzun kelimeleri kır */
  white-space: normal; /* Metnin alt satıra geçmesine izin ver */
  overflow-wrap: break-word; /* Uzun metinleri sarmalar */
  color: #000;
  font-family: 'euclid_regular', sans-serif;
  font-size: 8px;
}
.check-holder {
  padding-left: 6px !important;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 3px;
  gap: 3px;
  display: flex;
  flex-direction: row;
}
.option-image {
  margin-top: 5px;
  object-fit: cover;
  border-radius: 8px;
  width: 80%;
  aspect-ratio: 1/1;
}
.option-frame {
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 7px;
}
.option-holder {
  margin-bottom: 10px;
  padding-top: 5px;
  align-items: start;
  justify-items: center;
  min-height:min-content;
  padding-left: 8px;
  padding-right: 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  width: 100%;
}
.question-title {
  padding-top: 0px !important;
  padding: 8px;
  padding-bottom: 0px !important;
  margin-bottom: 5px;
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 12px;
  color: #0a0a0a;
}
.question-marker {
  padding-bottom: 0px !important;
  padding: 8px;
  font-family: 'euclid_semi_bold', sans-serif;
  color: #4a68f8;
  font-size: 10px;
}
.progress-bar {
  height: 100%;
  background-color: #4a68f8;
  width: 20%;
  transition: width 0.3s ease-in-out;
}
.progress-container {
  display: flex;
  width: 100%;
  height: 3.5px;
  background-color: #ececec;
  align-self: center;
  margin-top: 10px;
}
.welcome-text {
  text-align: center;
  font-family: 'euclid_regular', sans-serif;
  font-size: 7px;
  color: #9194a3;
}
.content-header {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.module-header {
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 12px;
  color: #040c2d;
  text-align: center;
}
.question-holder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
.question-holder::-webkit-scrollbar {
  width: 0px;
}
.question-image-holder {
  margin-top: 10px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  gap: 10px;
  justify-content: center;
}
.question-image-less-holder {
  margin-top: 10px;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  gap: 10px;
  justify-content: center;
}

.question-image {
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 10%;
  width: 100%;
}
.question-less-image{
  aspect-ratio: 1.5/1;
  object-fit: cover;
  border-radius: 5%;
  width: 100%;
}
</style>
