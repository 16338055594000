<template>
  <div class="welcome-text-content">
    <div class="scroll-content">
      <div class="content-header">
        <span class="module-header">{{ submodule.name }}</span>
        <span v-if="selectedModule.content?.type==='welcomeText'" class="welcome-text">{{ $t("welcome") }}</span>
        <span v-if="selectedModule.content?.type==='summary'" class="welcome-text">{{$t("summary_preview")}}</span>

        <div class="progress-container">
          <span class="progress-bar"></span>
        </div>
      </div>
      <div  v-if="selectedModule.content?.attach.length >= 3" class="question-image-holder">
      <img v-for="item in selectedModule.content?.attach" v-if="item" :src="item.attachUrl" alt="item.name" class="welcome-image">
      </div>
      <div v-if="selectedModule.content?.attach.length < 3 && selectedModule.content?.attach.length > 0"  class="question-image-less-holder">
        <img v-for="item in selectedModule.content?.attach" v-if="item" :src="item.attachUrl" alt="item.name" class="welcome-less-image">
      </div>
      <span v-if="selectedModule.content?.type==='welcomeText'" class="bold-welcome-text">{{ $t("welcome") }}🙏🏻</span>
      <span v-if="selectedModule.content?.type==='summary'" class="bold-welcome-text">{{selectedModule.name}}</span>

      <span v-if="selectedModule.content?.type==='welcomeText'" class="description">{{selectedModule.name}}</span>
      <span v-if="selectedModule.content?.type==='summary'" class="description">{{selectedModule.description}}</span>

      <div style="flex: 1"></div>
      <span class="button" @click="goForward">{{ $t("next_question") }}</span>
    </div>
  </div>
</template>

<script>
export default{
  props:{
    submodule:{
      type:Object,
      required: true
    },
    selectedModule:{
      type:Object,
      required: true
    },
    goForward:{
      type:Function,
      required: true
    }
  }
}
</script>

<style scoped>
.question-image-less-holder {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  gap: 10px;
  justify-content: center;
}
.question-image-holder {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  gap: 10px;
  justify-content: center;
}
.welcome-text-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
}

.scroll-content {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-bottom: 10px;
}

.scroll-content::-webkit-scrollbar {
  width: 0px;
}

.button {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 7px 10px;
  color: #fff;
  cursor: pointer;
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  background-color: #040C2D;
  transition: all 0.1s ease-in;
  border: 1px solid #040C2D;
  width: 90%;
  align-self: center;
}

.description {
  height: 100%;
  font-family: 'euclid_regular', sans-serif;
  font-size: 10px;
  color: #475467;
  padding-right: 5px;
  padding-left: 5px;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.bold-welcome-text {
  align-self: start;
  display: flex;
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 16px;
  color: #040C2D;
  padding: 5px;
}

.welcome-image {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 9px;
  object-fit: cover;
  object-position: center;
}
.welcome-less-image {
  width: 100%;
  aspect-ratio: 1.7/1;
  border-radius: 9px;
  object-fit: cover;
  object-position: center;
}

.progress-container {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  height: 3.5px;
  background-color: #ececec;
  align-self: center;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #4A68F8;
  width: 20%;
  transition: width 0.3s ease-in-out;
}

.welcome-text {
  text-align: center;
  font-family: 'euclid_regular', sans-serif;
  font-size: 7px;
  color: #9194A3;
}

.module-header {
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 12px;
  color: #040C2D;
  text-align: center;
}

.content-header {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
</style>