<template>
  <div class="main-component-wrapper">
    <div v-if="showElseBlock" class="elif-block-wrapper">
      <div class="elif-block">
        <span>ELSE</span>
      </div>

      <div class="left-spacer elif">
        <div class="connection-line-if-block" />
      </div>
    </div>

    <div class="component-wrapper" :class="selected && 'selected'" @click="onClick" v-if="data?.type !== 'ifCondition'">
      <div class="delete-wrapper" @click="onDelete">
        <img src="@/assets/images/trash.svg" />
      </div>

      <div class="icon-wrapper" :class="flowComponent.color">
        <img :src="flowComponent.icon" />
      </div>

      <div class="info-wrapper">
        <span class="title">{{flowComponent.name}}</span>
        <span class="description">{{flowComponent.description}}</span>
      </div>
    </div>

    <div v-if="data?.type !== 'ifCondition'" class="connection-line" />

    <div v-if="data?.type === 'ifCondition'" class="branch-line"  :style="{width: `${93}px`}"/>

    <div v-if="data?.type === 'ifCondition'" class="if-block-wrapper">
      <div class="left-spacer">
        <div class="connection-line-if-block" />
      </div>

      <div class="if-block">
        <span>IF</span>
      </div>
    </div>

    <div v-if="data?.type === 'ifCondition'" class="if-block-wrapper">
      <div class="left-spacer">
        <div class="connection-line-if-block" />
      </div>

      <div class="left-spacer if-bottom-line">
        <div class="connection-line-if-block" />
      </div>
    </div>

  </div>
</template>


<script>
import PlayIcon from "@/assets/images/play.svg";
import BranchIcon from "@/assets/images/branchWhite.svg";
import LightningIcon from "@/assets/images/lightningBlue.svg";
import FilterIcon from "@/assets/images/filterYellow.svg";
import {
  findIfComponentByType,
  findThenComponentByType,
  findWhenComponentByType
} from "@/components/user-flow/constants";

export default {
  name: 'FlowComponent',
  props: {
    data: {
      type: Object,
      default: {},
    },
    selected: {
      type: Boolean,
      default: false,
    },
    indentationLevel: {
      type: Number,
      default: 1,
    },
    showElseBlock: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  methods: {
    findWhenComponentByType,
    findThenComponentByType,
    findIfComponentByType,
  },
  computed: {
    flowComponent() {
      if (this.data.type === 'whenStart') {
        return {
          icon: PlayIcon,
          color: 'green',
          name: 'When: Add a trigger',
          description: 'Add an event that triggers the rule to run',
        }
      } else if (this.data.type === 'thenStart') {
        return {
          icon: LightningIcon,
          color: 'blue',
          name: 'Then: Add an action',
          description: 'What the rule will do',
        }
      } else if (this.data.type === 'ifStart') {
        return {
          icon: FilterIcon,
          color: 'yellow',
          name: 'If: Add a condition',
          description: 'Narrows the scope to specific filters',
        }
      } else if (this.data.type === 'default') {
        return {
          icon: BranchIcon,
          color: 'gray',
          name: 'New component',
          description: 'Add an action or condition',
        }
      } else {
        const tempWhen = findWhenComponentByType(this.data.type);
        const tempThen = findThenComponentByType(this.data.type);
        const ifThen = findIfComponentByType(this.data.type);

        if (tempWhen) {
          return {
            name: tempWhen.name,
            color: 'green',
            description: tempWhen.description,
            icon: tempWhen.icon,
          }
        } else if (tempThen) {
          return {
            name: tempThen.name,
            color: 'blue',
            description: tempThen.description,
            icon: tempThen.icon,
          }
        } else if (ifThen) {
          return {
            name: ifThen.name,
            color: 'yellow',
            description: ifThen.description,
            icon: ifThen.icon,
          }
        }
      }

    }
  }
}
</script>

<style scoped>
.main-component-wrapper {
  display: flex;
  flex-direction: column;
}

.component-wrapper {
  position: relative;
  border: 2px solid #EAECF0;
  background-color: #FCFCFD;
  border-radius: 16px;
  padding: 15px;
  width: 400px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  cursor: pointer;
  transition: border 0.2s, background-color 0.2s;
}

.component-wrapper.selected {
  border: 2px solid #1C3CD5;
  background-color: #F5F7FF;
}

.component-wrapper:hover .delete-wrapper {
  opacity: 1;
}

.delete-wrapper {
  display: flex;
  opacity: 0;
  position: absolute;
  width: 36px;
  height: 36px;
  top: -18px;
  right: 15px;
  background-color: #fff;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #EAECF0;
  transition: opacity 0.2s, background-color 0.2s;
}

.delete-wrapper:hover {
  background-color: #F5F7FF;
}

.delete-wrapper img {
  width: 16px;
  height: 16px;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 16px;
}

.icon-wrapper.green {
  background-color: #e3fcef;
}

.icon-wrapper.gray {
  background-color: #EAECF0;
}

.icon-wrapper.yellow {
  background-color: #FFF0B3;
}

.icon-wrapper.blue {
  background-color: #DEEBFF;
}

.icon-wrapper img {
  width: 26px;
  height: 26px;
}

.info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.info-wrapper .title {
  font-family: 'euclid_medium', sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.info-wrapper .description {
  font-family: 'euclid_regular', sans-serif;
  font-size: 12px;
  color: #98A2B3;
}

.connection-line {
  width: 2px;
  background-color: #EAECF0;
  margin-left: 39px;
  min-height: 40px;
  z-index: -1;
}

.connection-line-if-block {
  width: 2px;
  background-color: #EAECF0;
  min-height: 26px;
  z-index: -1;
}

.branch-line {
  content: "";
  display: block;
  min-height: 24px;
  border-top: 2px solid #EAECF0;
  border-right: 2px solid #EAECF0;
  border-radius: 0 16px 0 0;
  border-bottom: none;
  border-left: 2px solid #EAECF0;
  margin-left: 39px;
}

.if-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #EAECF0;
  width: 26px;
  height: 26px;
  margin-left: 37px;
}

.elif-block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #EAECF0;
  width: 56px;
  height: 26px;
  margin-left: 12px;
}

.if-bottom-line {
  margin-left: 10px;
}

.elif-block span, .if-block span {
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 10px;
  color: #040C2D;
}

.if-block-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.elif-block-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.left-spacer {
  width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>
