import axios from './providers/axios';

const authService = {
    clientToken() {
        return axios.post('/v1/common/profile/clientToken');
    },
    login(email, password) {
        return axios.post('/v1/common/profile/login', {
            email,
            password
        });
    },
    logout() {
        return axios.post('/v1/common/profile/logout');
    }
};

export default authService;
