<template>
  <Layout>
    <div class="tab-panel-row">
      <div class="tab-panel">
        <button class="tab-button" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
          <span>{{$t('my_account')}}</span>
        </button>
        <button class="tab-button" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
          <span>{{$t('company_person')}}</span>
        </button>
      </div>
    </div>

    <div class="my-account" v-if="tabIndex === 0">
      <div class="settings-row">
        <div class="left">
          <h3>{{$t('contact_information')}}</h3>
          <h4>{{$t('update_contact')}}</h4>
        </div>
        <div class="right">
          <div class="setting-card top">
            <div class="input-row">
              <div class="input-wrapper">
                <span class="form-label">{{$t('name_person')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('name_expert')" v-model="accountSettings.name"/>
              </div>

              <div class="input-wrapper">
                <span class="form-label">{{$t('surname_person')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('surname_person')" v-model="accountSettings.surname" />
              </div>
            </div>

            
              <!--
              <div class="input-wrapper">
                <span class="form-label">{{$t('email_address_person')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('email_address_person')" v-model="accountSettings.email"/>
              </div>
-->
              <!--
              <div class="input-wrapper">
                <span class="form-label">{{$t('phone_number_person')}}</span>
                <input class="form-input" type="text" :placeholder="thisdouble-form.$t('phone_number_person')" v-model="accountSettings.phone"/>
              </div>-->
              <div class="input-row">
                <div class="input-wrapper">
                  <span class="form-label">{{$t('person_email_adress')}}</span>
                  <input class="form-input" type="text" :placeholder="this.$t('person_email_adress')" v-model="accountSettings.email"/>
                </div>
                    <div class="input-wrapper">
                      <span class="form-title">{{$t('mobile_phone_number') }}</span>
                      <PhoneInput v-model="this.accountSettings.phone"/>
                    </div>
              </div>
            

            <div class="input-row">
              <div class="input-wrapper">
                <span class="form-label">{{$t('fax_number_person')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('fax_number_person')" v-model="accountSettings.faxNumber" />
              </div>
            </div>

            <FileDrop :on-file-selected="handleProfileImage" :selected-file="accountSettings.avatarUrl" />
          </div>
        </div>
      </div>


      <div class="settings-row">
        <div class="left">
          <h3>{{$t('address_information')}}</h3>
          <h4>{{$t('update_address')}}</h4>
        </div>
        <div class="right">
          <div class="setting-card top">
            <div class="input-wrapper">
              <span class="form-label">{{$t('address_name')}}</span>
              <input class="form-input" type="text" :placeholder="this.$t('address_name')" v-model="accountSettings.addressName" />
            </div>

            <div class="input-row">
              <div class="input-wrapper">
                <span class="form-label">{{$t('country_person')}}</span>
                <SelectBox :full-width="true" :loading="countriesLoading" :options="countries" :on-select="handleAccountCountry" :selected-value="accountSettings.countryId"/>
              </div>

              <div class="input-wrapper">
                <span class="form-label">{{$t('city_person')}}</span>
                <SelectBox v-if="accountSettings.countryId === 218" :full-width="true" :loading="citiesLoading" :options="cities" :selected-value="accountSettings.cityId" :on-select="handleAccountCity"/>
                <input v-else class="form-input" type="text" :placeholder="this.$t('city_person')" v-model="accountSettings.cityName" />
              </div>
            </div>

            <div class="input-wrapper">
              <span class="form-label">{{$t('address_person')}}</span>
              <input class="form-input" type="text" :placeholder="this.$t('address_person')" v-model="accountSettings.address" />
            </div>
          </div>
        </div>
      </div>


      <div class="position-right">
        <button class="dark-button" @click="handleSave">
          {{$t('save_changes')}}
        </button>
      </div>

    </div>

    <div class="my-account" v-if="tabIndex === 1">
      <div class="settings-row">
        <div class="left">
          <h4>{{$t('update_photo_personal')}}.</h4>
        </div>
        <div class="right">
          <div class="setting-card top">
            <div class="input-wrapper">
              <span class="form-label">{{$t('company_name_person')}}</span>
              <input class="form-input" type="text" :placeholder="this.$t('company_name_person')" v-model="companySettings.name"/>
            </div>
            <span class="form-label">{{$t('company_profile_image')}}</span>
            <FileDrop :on-file-selected="handleCompanyProfileImage" :selected-file="companySettings.pngIconUrl" :on-delete="deleteCompanyProfileImage" />
            <span class="form-label">{{$t('company_icon')}}</span>
            <FileDrop :on-file-selected="handleCompanyIcon" :selected-file="companySettings.appIconUrl" :on-delete="deleteCompanyIcon"/>

          </div>
        </div>
      </div>

      <div class="settings-row">
        <div class="left">
          <h4>{{$t('update_tax_details')}}</h4>
        </div>
        <div class="right">
          <div class="setting-card top">

            <div class="input-row">
              <div class="input-wrapper">
                <span class="form-label">{{$t('tax_number')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('tax_number')" v-model="companySettings.taxNumber"/>
              </div>

              <div class="input-wrapper">
                <span class="form-label">{{$t('tax_office')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('tax_office')" v-model="companySettings.taxOffice"/>
              </div>
            </div>

          </div>
        </div>
      </div>


      <div class="settings-row">
        <div class="left">
          <h4>{{$t('update_address_details')}}.</h4>
        </div>
        <div class="right">
          <div class="setting-card top">

            <div class="input-row">
              <div class="input-wrapper">
                <span class="form-label">{{$t('country_detail')}}</span>
                <SelectBox :full-width="true" :loading="countriesLoading" :options="countries" :on-select="handleCompanyCountry" :selected-value="companySettings.countryId"/>
              </div>

              <div class="input-wrapper">
                <span class="form-label">{{$t('city_detail')}}</span>
                <SelectBox v-if="companySettings.countryId === 218" :full-width="true" :loading="citiesLoading" :options="cities" :selected-value="companySettings.cityId" :on-select="handleCompanyCity"/>
                <input v-else class="form-input" type="text" :placeholder="this.$t('city_detail')" v-model="companySettings.cityName" />
              </div>
            </div>

            <div class="input-row">
              <div class="input-wrapper">
                <span class="form-label">{{$t('state_detail')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('state_detail')" v-model="companySettings.state"/>
              </div>

              <div class="input-wrapper">
                <span class="form-label">{{$t('zip_code_detail')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('zip_code_detail')" v-model="companySettings.zipCode"/>
              </div>
            </div>

            <div class="input-row">
              <div class="input-wrapper">
                <span class="form-label">{{$t('company_email')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('company_email')" v-model="companySettings.email"/>
              </div>

              <div class="input-wrapper">
                <span class="form-label">{{$t('company_phone')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('company_phone')" v-model="companySettings.phone"/>
              </div>
            </div>

            <div class="input-row">
              <div class="input-wrapper">
                <span class="form-label">{{$t('time_zone')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('time_zone')" v-model="companySettings.timeZone"/>
              </div>

              <div class="input-wrapper">
                <span class="form-label">{{$t('website_detail')}}</span>
                <input class="form-input" type="text" :placeholder="this.$t('website_detail')" v-model="companySettings.webSite"/>
              </div>
            </div>

            <div class="input-wrapper">
              <span class="form-label">{{$t('address_detail')}}</span>
              <input class="form-input" type="text" :placeholder="this.$t('address_person')" v-model="companySettings.address"/>
            </div>

          </div>
        </div>
      </div>


      <div class="position-right">
        <button class="dark-button" @click="handleCompanySave">
          {{$t('save_changes_profile')}}
        </button>
      </div>

    </div>

    <LoadingOverlay :is-loading="isLoading" />
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import FileDrop from "@/components/FileDrop.vue";
import settingsService from "@/service/settingsService";
import {
  VueSpinnerCore
} from 'vue3-spinners'
import SelectBox from "@/components/SelectBox.vue";
import commonService from "@/service/commonService";
import {handleErrorResponse} from "@/utils/utils";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import PhoneInput from "@/components/PhoneInput.vue";

export default {
  components: {
    PhoneInput,
    LoadingOverlay,
    SelectBox,
    FileDrop,
    Layout,
    VueSpinnerCore
  },
  data() {
    return {
      isLoading: true,
      tabIndex: 0,
      uploadFile: null,
      uploadCompanyFile: null,
      uploadCompanyIcon:null,
      accountSettings: {
        name: "",
        surname: "",
        email: "",
        address: "",
        addressName: "",
        avatar: null,
        phone: "",
        cityId: 1,
        countryId: null,
        faxNumber: "",
        cityName:"",
      },
      companySettings: {
        name: "",
        avatar: "",
        taxNumber: "",
        taxOffice: "",
        country: "",
        city: "",
        state: "",
        zipCode: "",
        email: "",
        phone: "",
        timeZone: "",
        webSite: "",
        address: "",

      },
      countries: [],
      countriesLoading: true,

      cities: [],
      citiesLoading: true,
      loading: false,
    }
  },
  async mounted() {
    await settingsService.getAccountSettings().then((res) => {
      this.accountSettings = res.data.data;

      this.accountSettings.addressName = this.accountSettings.adress;
      this.accountSettings.address = this.accountSettings.adressName;

      delete this.accountSettings.adress;
      delete this.accountSettings.adressName;

    }).catch((err) => {
      console.log(err);
    });

    await settingsService.getCompanySettings().then((res) => {
      this.companySettings = res.data.data;
    }).catch((err) => {
      console.log(err);
    });

    this.getCountries();
    this.getCities();

    this.isLoading = false;
  },
  methods: {
    changeTab(index) {
      this.tabIndex = index;
    },
    handleProfileImage(file) {
      this.uploadFile = file;
    },
    handleCompanyProfileImage(file) {
      this.uploadCompanyFile = file;
    },
    deleteCompanyProfileImage() {
      this.companySettings.pngIconDelete=1;
      this.uploadCompanyFile = null;
    },
    handleCompanyIcon(file) {
      this.uploadCompanyIcon = file;
    },
    deleteCompanyIcon(){
      this.companySettings.appIconDelete=1;
      this.uploadCompanyIcon = null;
    },

    handleSave() {
      this.isLoading = true;

      const temp = {
        ...this.accountSettings
      };

      if (this.uploadFile) {
        temp.avatar = this.uploadFile;
      } else {
        delete temp.avatar;
      }

      settingsService.updateAccountSettings(temp).then((res) => {
        this.isLoading = false;

        this.$snackbar.add({
          text: this.$t('settings_updated_messages'),
          type: 'success'
        });

      }).catch((error) => {
        this.isLoading = false;
        handleErrorResponse(error, this.$snackbar);

        this.$snackbar.add({
          text: this.$t('settings_error_messages'),
          type: 'error'
        });

      });
    },
    handleCompanySave() {
      this.isLoading = true;

      const temp = {
        ...this.companySettings
      };

      if (this.uploadCompanyFile!==null) {
        temp.pngIcon = this.uploadCompanyFile;
        temp.pngIconDelete=0;
      } else {
        delete temp.pngIcon;
      }

      if (this.uploadCompanyIcon!==null) {
        temp.appIcon = this.uploadCompanyIcon;
        temp.appIconDelete=0;
      } else {
        delete temp.appIcon;
      }

      settingsService.updateCompanySettings(temp).then((res) => {
        this.isLoading = false;
        window.location.reload();
      }).catch((error) => {
        this.isLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    getCountries() {
      this.countriesLoading = true;
      commonService.getCountries().then((res) => {
        const countryTemp = res.data.data.country;
        countryTemp.forEach((item) => {
          this.countries.push({
            value: item.id,
            text: item.name,
          });

          this.countriesLoading = false;
        });

        if (this.accountSettings.country.id) {
          this.accountSettings.countryId = this.accountSettings.country.id;
          delete this.accountSettings.country;
        }

        if (this.companySettings.country.id) {
          this.companySettings.countryId = this.companySettings.country.id;
          delete this.companySettings.country;
        }

      }).catch((err) => {
        console.log(err);
      });
    },
    getCities() {
      this.citiesLoading = true;
      commonService.getCities().then((res) => {
        const cityTemp = res.data.data.cities;
        cityTemp.forEach((item) => {
          this.cities.push({
            value: item.id,
            text: item.name,
          });

          const tempCityId = this.accountSettings.cityId.id;

          if (tempCityId !== undefined) {
            delete this.accountSettings.cityId;
            this.accountSettings.cityId = tempCityId;
          }

          const tempAccountCityId = this.companySettings.cityId.id;

          if (tempAccountCityId !== undefined) {
            delete this.companySettings.cityId;
            this.companySettings.cityId = tempAccountCityId;
          }

          this.citiesLoading = false;
        });
      }).catch((err) => {
        console.log(err);
      });
    },

    handleAccountCountry(countryId) {
      this.accountSettings.countryId = parseInt(countryId);
      if (countryId !== 218) {
        this.accountSettings.cityId = "";
      }
    },

    handleCompanyCountry(countryId) {
      this.companySettings.countryId = parseInt(countryId);
      if (countryId !== 218) {
        this.companySettings.cityId = "";
      }
    },

    handleAccountCity(cityId) {
      this.accountSettings.cityName="";
      this.accountSettings.cityId = parseInt(cityId);
    },

    handleCompanyCity(cityId) {
      this.companySettings.cityName="";
      this.companySettings.cityId = parseInt(cityId);
    },

  }
}
</script>

<style scoped>
.double-form{
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
}
.my-account {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.settings-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}
.settings-row .left {
  width: 25%;
}
.settings-row .right {
  width: 75%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.settings-row h3 {
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 16px;
  color: #101828;
  margin-bottom: 5px;
}
.settings-row h4 {
  font-family: 'euclid_medium', sans-serif;
  font-size: 14px;
  color: #475467;
}
.setting-card.top {
  background-color: #FFFFFF;
  border-radius: 16px;
  border: 1px solid #EAECF0;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.setting-card.bottom {
  background-color: #FFFFFF;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border: 1px solid #EAECF0;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.input-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}
.tab-panel {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}
.tab-panel-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #F2F4F7;
  padding: 10px 0;
  margin-bottom: 20px;
}
.tab-button {
  padding: 6px 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.tab-button span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #344054;
}
.tab-button.active, .tab-button:hover {
  background-color: #F2F4F7;
}
</style>
