import axios from './providers/chatAxios';

const chatService = {
    getChatList(page = 1) {
        return axios.get(`/chats?page=${page}&limit=20&showGroups=false`);
    },
    getChat(chatId, page = 1) {
        return axios.get(`/chats/${chatId}?page=${page}&limit=10`);
    },
    createChatGroup(name, members, notificationId) {
        return axios.post(`/chats`, {
            name: name,
            participants: members,
            isGroup: true,
            notificationId: notificationId
        });
    },
    getInterviewChatGroups(notificationId) {
        return axios.get(`/chats/getGroupChatsByNotificationId/${notificationId}`);
    },
    sendMessage(chatId, content, file) {
        return axios.post(`/messages/send`, {
            chatId: chatId,
            content: content,
            file: file
        }, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    },

}

export default chatService;
